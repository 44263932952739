import React from 'react';
import { Link } from 'react-router-dom';

const FourOhFour = () => (
  <div className="container">
    <h3>404 PAGE NOT FOUND</h3>
    <p>We are sorry but the page you are looking for does not exist</p>
    <Link to="/p/search">Go back</Link>
  </div>
);

export default FourOhFour;
