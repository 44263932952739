const transformLabel = label => {
  switch (label) {
    case 'FirstName':
      return 'First Name';
    case 'LastName':
      return 'Last Name';
    case 'EmployeeID':
      return 'Username';
    case 'CustomerID':
      return 'Linked Customers';
    default:
      return label;
  }
};

export default function(state = { allUsers: [], createUserStatus: '' }, action) {
  switch (action.type) {
    case 'CREATE_USER_STATUS':
      return { ...state, createUserStatus: action.payload };
    case 'EDIT_USER_STATUS':
      return { ...state, editUserStatus: action.payload };
    case 'CREATE_USER':
      const newAllUsers = [...state.allUsers];
      const newUser = { ...action.payload, Status: action.payload.Status === 'A' };
      newAllUsers.unshift(newUser);
      return { ...state, allUsers: newAllUsers };
    case 'EDIT_USER':
      const updatedUsers = state.allUsers.map(user => {
        if (user.EmployeeNo === action.payload.user.EmployeeNo) {
          return {
            ...user,
            ...action.payload.user
          };
        }
        return user;
      });

      return {
        ...state,
        allUsers: updatedUsers
      };
    case 'LIST_USERS':
      const users = action.payload.map(user => {
        let Status = false;
        if (user.Status === 'A') {
          Status = true;
        }
        return {
          ...user,
          Status
        };
      });

      return {
        ...state,
        allUsers: users
      };
    case 'SET_EDIT_USER':
      // eslint-disable-next-line no-case-declarations
      let labels = [];
      Object.keys(action.payload).forEach(prop => {
        labels.push({ name: prop, label: transformLabel(prop) });
      });
      labels = labels.filter(
        label =>
          label.name !== 'EmployeeNo' &&
          label.name !== 'Status' &&
          label.name !== 'CustomerID' &&
          label.name !== 'station' &&
          label.name !== 'GroupType'
      );
      return {
        ...state,
        userData: action.payload,
        userLabels: labels
      };
    case 'RESET_PASSWORD':
      return {
        ...state,
        resetPasswordStatus: action.payload.msg
      };
    case 'PASSWORD_ERROR':
      return {
        ...state,
        userError: action.payload
      };

    case 'TOGGLE_USER_STATUS':
      const { status, id } = action.payload;
      const newUsers = state.allUsers.map(user => {
        if (user.EmployeeNo === id) {
          return {
            ...user,
            Status: status === 'A'
          };
        }
        return user;
      });

      return {
        ...state,
        allUsers: newUsers
      };
    default:
      return state;
  }
}
