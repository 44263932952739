import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import * as actions from '../../actions';
import '../../css/move.css';
import CustomSweetAlert from '../Alerts/SweetAlert';

class MoveBooking extends Component {
  constructor() {
    super();
    this.moveBooking = this.moveBooking.bind(this);
    this.handleCloneDetail = this.handleCloneDetail.bind(this);
  }

  moveBooking(value) {
    const { BkDetailID } = this.props;
    if (window.confirm('Are you sure to move?')) {
      this.props.moveBooking(BkDetailID, value, () => {
        window.location.reload(true);
      });
    }
  }

  handleCloneDetail() {
    const { details, cloneDetail } = this.props;
    cloneDetail(details);
  }

  renderAlert() {
    const { showAlert } = this.props;
    if (showAlert) {
      return <CustomSweetAlert type="success" title="Successfully cloned booking details!" />;
    }

    return null;
  }

  renderField(field) {
    const { label, type, input, ...rest } = field;
    return (
      <div className="form-group form-group-sm col-sm-6">
        <div className="row">
          <label className="col-sm-4 col-form-label">{label}</label>
          <div className="col-sm-7">
            <input className="form-control" type={type} {...input} {...rest} />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        {this.renderAlert()}
        <form onSubmit={handleSubmit(this.moveBooking)}>
          <Field label="Move to booking:" type="text" name="BookingID" component={this.renderField} />
          <button type="submit" name="move" className="move btn btn-sm btn-default">
            Move
          </button>
          <button name="clone" type="button" className="move btn btn-sm btn-default" onClick={this.handleCloneDetail}>
            Clone
          </button>
        </form>
      </div>
    );
  }
}

function mapStateToProps({ util }) {
  return {
    showAlert: util.showAlert
  };
}

MoveBooking = reduxForm({
  form: 'MoveBooking'
})(MoveBooking);

MoveBooking = connect(mapStateToProps, actions)(MoveBooking);
export default MoveBooking;
