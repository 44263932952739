import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import * as actions from '../../actions';

class HBL extends Component {
  state = {
    fileExist: false,
    isLoading: true
  };

  componentDidMount() {
    const { hbl, CNeeID } = this.props;
    const link = `https://otxusa.s3-us-west-1.amazonaws.com/sfo_uploads/${CNeeID}/${hbl}.pdf`;
    fetch(link, {
      method: 'HEAD'
    }).then(res => {
      if (res.status === 200) {
        this.setState({ fileExist: true });
      }
      this.setState({ isLoading: false });
    });
  }

  render() {
    const { fileExist, isLoading } = this.state;
    const {
      disabled,
      hbl,
      CNeeID,
      label,
      input,
      meta: { touched, error }
    } = this.props;

    if (isLoading) {
      return <div>Loading...</div>;
    }
    if (!fileExist && !isLoading) {
      return (
        <div className="form-group form-group-sm col-sm-6">
          <div className="row">
            <label className="col-sm-4 col-form-label">{label}</label>
            <div className="col-sm-7">
              <input className="form-control" {...input} disabled={disabled} />
              <div className="text-danger">{touched ? error : ''}</div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="form-group form-group-sm col-sm-6">
        <div className="row">
          <label className="col-sm-4 col-form-label">{label}</label>
          <div className="col-sm-7">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://otxusa.s3-us-west-1.amazonaws.com/sfo_uploads/${CNeeID}/${hbl}.pdf`}
            >
              {hbl}
            </a>
          </div>
        </div>
      </div>
    );
  }
}
export default HBL;
// function mapStateToProps(state) {
//   // console.log(`the file status is ${state.util.isExist}`);
//   return {
//     fileExist: state.util.hblExist
//   };
// }
// export default connect(
//   mapStateToProps,
//   actions
// )(HBL);
