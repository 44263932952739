import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
// import { Button } from 'react-bootstrap';
import RaisedButton from 'material-ui/RaisedButton';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import moment from 'moment';
import React, { Component } from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { DatePicker, TextField } from 'redux-form-material-ui';
import * as actions from '../../actions';
import Spinner from '../util/spinner';
import validate from '../util/validate';

// import RenderField from '../booking/renderField';
const styles = theme => ({
  button: {
    margin: theme.spacing.unit
  },

  paper: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    textAlign: 'center',
    // color: theme.palette.text.secondary
    backgroundColor: '#f9fdfd'
  })
});

class POHeader extends Component {
  constructor(props) {
    super(props);
    this.onPOSubmit = this.onPOSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.user.accessLevel === 'CUSTOMER') {
      if (this.props.user.CustomerID !== this.props.POHeader.CNeeID.toString()) {
        this.props.history.push('/p/po/search');
        this.props.addError('ACCESS DENIED');
      }
    }
    if (this.props.user.accessLevel === 'AGENT' && this.props.user.AccessGroup) {
      // console.log(this.props);
      // console.log(this.props.user.AccessGroup, this.props.POHeader.AccessGroup);
      if (this.props.user.AccessGroup !== this.props.POHeader.AccessGroup) {
        this.props.history.push('/p/po/search');
        this.props.addError('ACCESS DENIED');
      }
    }
    if (this.props.user.accessLevel === 'AGENT' && !this.props.user.AccessGroup) {
      if (this.props.user.id.toString() !== this.props.POHeader.Agent) {
        this.props.history.push('/p/po/search');
        this.props.addError('ACCESS DENIED');
      }
    }
  }

  onPOSubmit(values) {
    if (values.CustomerInHandsDate) {
      values.CustomerInHandsDate = moment(values.CustomerInHandsDate, ['MM/DD/YYYY', 'YYYY-MM-DD']);
    }
    if (values.TransactionDate) {
      values.TransactionDate = moment(values.TransactionDate, ['MM/DD/YYYY', 'YYYY-MM-DD']);
    }
    if (values.FinalDueDate) {
      values.FinalDueDate = moment(values.FinalDueDate, ['MM/DD/YYYY', 'YYYY-MM-DD']);
    }
    if (values.CargoReadyDate) {
      values.CargoReadyDate = moment(values.CargoReadyDate, ['MM/DD/YYYY', 'YYYY-MM-DD']);
    }
    // console.log(values);
    if (!this.props.updated) {
      this.props.updatePOHeader(values);
    } else {
      if (this.props.user.accessLevel !== 'CUSTOMER') this.props.movePOtoBooking(values);
      // console.log(values);
    }
  }
  renderUpdatedMsg() {
    if (this.props.updated) {
      return (
        <div className="alert alert-success text-center">
          <strong>Successfully updated!</strong>
        </div>
      );
    }
  }
  renderMoveSuccess() {
    if (this.props.POMoved) {
      return (
        <div className="alert alert-success text-center">
          <strong>Successfully moved PO to booking!</strong>
        </div>
      );
    }
  }

  render() {
    const { POHeader, initialValues, handleSubmit, classes } = this.props;

    if (!POHeader || !initialValues) {
      return <Spinner />;
    }
    return (
      <div>
        {/* {booking.Urgent ? <h3 style={{ color: 'red', marginTop: 0, marginBottom: '20px' }}>Urgent</h3> : ''} */}
        {/* <Button bsSize="small" href="#comments">
            Comments
          </Button> */}
        <Grid>
          <form onSubmit={handleSubmit(this.onPOSubmit)}>
            <Row>
              <Col sm={6}>
                <Paper className={classes.paper} elevation={4}>
                  <MuiThemeProvider>
                    <Field name="PONo" component={TextField} floatingLabelText="PO #:" />
                  </MuiThemeProvider>
                  <MuiThemeProvider>
                    <Field name="buyCode" component={TextField} floatingLabelText="Buyer:" />
                  </MuiThemeProvider>
                  <MuiThemeProvider>
                    <Field name="LineNum" component={TextField} floatingLabelText="Line #:" disabled />
                  </MuiThemeProvider>
                  <MuiThemeProvider>
                    <Field name="SKUNo" component={TextField} floatingLabelText="Sku #:" />
                  </MuiThemeProvider>
                  <MuiThemeProvider>
                    <Field name="ShipperName" component={TextField} floatingLabelText="Shipper:" />
                  </MuiThemeProvider>
                  <MuiThemeProvider>
                    <Field name="CargoDescription" component={TextField} floatingLabelText="Description:" />
                  </MuiThemeProvider>
                  <MuiThemeProvider>
                    <Field name="POTotal" component={TextField} floatingLabelText="Total Open POs:" />
                  </MuiThemeProvider>
                  <MuiThemeProvider>
                    <Field name="QtyShipped" component={TextField} floatingLabelText="Qty Shipped:" />
                  </MuiThemeProvider>
                  {this.props.updated && this.props.user.accessLevel !== 'CUSTOMER' ? (
                    <MuiThemeProvider>
                      <Field name="BookingID" component={TextField} floatingLabelText="Move to Booking #" />
                    </MuiThemeProvider>
                  ) : (
                    <MuiThemeProvider>
                      <Field name="QtyRemaining" component={TextField} floatingLabelText="Qty Remaining:" disabled />
                    </MuiThemeProvider>
                  )}
                </Paper>
              </Col>
              <Col sm={6}>
                <Paper className={classes.paper} elevation={4}>
                  <MuiThemeProvider>
                    <Field
                      name="TransactionDate"
                      hintText="Transaction Date"
                      floatingLabelText="Transaction Date"
                      component={DatePicker}
                      format={(value, name) =>
                        value === '' ? null : typeof value === 'string' ? new Date(value) : value
                      }
                    />
                  </MuiThemeProvider>
                  <MuiThemeProvider>
                    <Field
                      name="FinalDueDate"
                      hintText="Final Due Date"
                      floatingLabelText="Final Due Date"
                      component={DatePicker}
                      format={(value, name) =>
                        value === '' ? null : typeof value === 'string' ? new Date(value) : value
                      }
                    />
                  </MuiThemeProvider>
                  <MuiThemeProvider>
                    <Field
                      name="CustomerInHandsDate"
                      hintText="Promise Date"
                      floatingLabelText="Promise Date"
                      component={DatePicker}
                      format={(value, name) =>
                        value === '' ? null : typeof value === 'string' ? new Date(value) : value
                      }
                    />
                  </MuiThemeProvider>
                  <MuiThemeProvider>
                    <Field
                      name="CargoReadyDate"
                      hintText="Cargo Ready Date"
                      floatingLabelText="Cargo Ready Date"
                      component={DatePicker}
                      format={(value, name) =>
                        value === '' ? null : typeof value === 'string' ? new Date(value) : value
                      }
                    />
                  </MuiThemeProvider>
                  <MuiThemeProvider>
                    <Field name="unitCost" component={TextField} floatingLabelText="Unit Cost:" />
                  </MuiThemeProvider>
                  <MuiThemeProvider>
                    <Field name="VendorCountry" component={TextField} floatingLabelText="Vendor Country:" />
                  </MuiThemeProvider>
                  <MuiThemeProvider>
                    <Field name="ShippingCountry" component={TextField} floatingLabelText="Shipping Country:" />
                  </MuiThemeProvider>
                  <MuiThemeProvider>
                    <Field name="ShipToCity" component={TextField} floatingLabelText="Ship To City:" />
                  </MuiThemeProvider>
                  <MuiThemeProvider>
                    <Field name="ShipToState" component={TextField} floatingLabelText="Ship to State:" />
                  </MuiThemeProvider>
                </Paper>
              </Col>
            </Row>

            {/* {this.renderUpdatedMsg()} */}
            <br />
            <br />
            {this.renderUpdatedMsg()}
            {this.renderMoveSuccess()}
            <div className="text-center">
              <MuiThemeProvider>
                <RaisedButton type="submit" label="Submit Update" labelPosition="before" style={styles.button} />
              </MuiThemeProvider>
            </div>
            <br />
            <br />
          </form>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    errorMessage: state.util.errors,
    initialValues: state.POs.POHeader,
    updated: state.POs.updated,
    POMoved: state.POs.POMoved,
    user: state.auth.user
  };
}

POHeader = reduxForm({
  form: 'POHeader',
  enableReinitialize: true,
  validate
})(POHeader);

POHeader = withRouter(
  connect(
    mapStateToProps,
    actions
  )(POHeader)
);
export default withStyles(styles)(POHeader);
