import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { reduxForm, FieldArray } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import BookingOceanDetailNew from './bookingOceanDetail_new';
import BookingAirDetailNew from './bookingAirDetail_new';

import '../../css/modal.css';

class DetailModal extends Component {
  constructor() {
    super();
    this.addDetailSubmit = this.addDetailSubmit.bind(this);
  }
  addDetailSubmit(values) {
    const { BookingID } = this.props.match.params;
    values.BkDetails.map(item => {
      return (item.BookingID = BookingID);
    });
    if (window.confirm('Are you sure to submit?')) {
      // console.log(values);
      this.props.createBookingDetail(values, () => {
        window.location.reload('true');
      });
    }
  }
  render() {
    const { ShipmentType, showCreateModal, closeCreateModal, handleSubmit } = this.props;
    // console.log(ShipmentType);
    if (ShipmentType === 'AIR') {
      var modalStyle = 'custom-air-modal';
    } else modalStyle = 'custom-modal';
    return (
      <div>
        <Modal dialogClassName={modalStyle} show={showCreateModal} onHide={closeCreateModal}>
          <Modal.Header closeButton>
            <Modal.Title>New Booking Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit(this.addDetailSubmit)}>
              {ShipmentType === 'OCEAN' ? (
                <FieldArray name="BkDetails" component={BookingOceanDetailNew} />
              ) : ShipmentType === 'AIR' ? (
                <FieldArray name="BkDetails" component={BookingAirDetailNew} />
              ) : (
                ''
              )}
              <div className="text-center">
                <Button type="submit">Submit</Button>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={closeCreateModal}>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

DetailModal = reduxForm({
  form: 'BookingDetailNew',
  enableReinitialize: true
})(DetailModal);

DetailModal = withRouter(
  connect(
    null,
    actions
  )(DetailModal)
);
export default DetailModal;
