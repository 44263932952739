import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';

class renderCheckbox extends Component {
  missingDocAlert(detailID, values, docName) {
    const { BookingID } = this.props;
    let msg = {};
    if (docName === 'MissingDoc1') {
      if (values[`${docName}`] === true) {
        values[`${docName}`] = 1;
        msg.Comments = `Shipper needs ORIGINAL HBL.`;
      }
      if (values[`${docName}`] === false) {
        values[`${docName}`] = 0;
        msg.Comments = `HBL requirement met. Freight will now be released.`;
      }
    }
    if (docName === 'MissingDoc2') {
      if (values[`${docName}`] === true) {
        values[`${docName}`] = 1;
        msg.Comments = `Missing Commerical Invoice And/Or Packing list.`;
      }
      if (values[`${docName}`] === false) {
        values[`${docName}`] = 0;
        msg.Comments = `Commerical invoice And/Or Packing list received.`;
      }
    }
    if (docName === 'MissingDoc3') {
      if (values[`${docName}`] === true) {
        values[`${docName}`] = 1;
        msg.Comments = `Origin Charges not received.`;
      }
      if (values[`${docName}`] === false) {
        values[`${docName}`] = 0;
        msg.Comments = `Origin Charges received.`;
      }
    }
    // values.BkDetailID = detailID;
    // console.log(values);
    this.props.docAlert(detailID, BookingID, values, msg, () => {
      window.location.reload(true);
    });
    // alert(`CLICKED: bookingID ${BookingID} booking detail ${id} is missing ${docName}? Answer: ${value}`);
  }

  render() {
    const {
      detailID,
      label,
      input,
      docName,
      meta: { touched, error }
    } = this.props;
    return (
      <div className="form-group form-group-sm col-sm-4">
        <div className="row">
          <div className="col-sm-1">
            <input
              type="checkbox"
              checked={input.value}
              onClick={() => {
                input.onChange((input.value = !input.value));
                let values = {};
                values[`${docName}`] = input.value;
                // console.log(values);
                this.missingDocAlert(`${detailID}`, values, docName);
              }}
              {...input}
            />
            <div className="text-danger">{touched ? error : ''}</div>
          </div>
          <label className="col-sm-9 col-form-label">{label}</label>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  actions
)(renderCheckbox);
