import React, { Component } from 'react';

export default class RenderShipmentType extends Component {
  render() {
    const {
      label,
      input,
      disabled,
      meta: { touched, error }
    } = this.props;
    const statusOption = [
      { value: '', display: 'SHIPMENT TYPE:' },
      { value: 'OCEAN', display: 'OCEAN' },
      { value: 'AIR', display: 'AIR' }
    ];
    return (
      <div className="form-group form-group-sm col-sm-6">
        <div className="row">
          <label className="col-sm-4 col-form-label">{label}</label>
          <div className="col-sm-7">
            <select className="form-control" {...input} disabled={disabled}>
              {statusOption.map(choice => (
                <option value={choice.value} key={choice.value}>
                  {choice.display}
                </option>
              ))}
            </select>
            <div className="text-danger">{touched ? error : ''}</div>
          </div>
        </div>
      </div>
    );
  }
}
