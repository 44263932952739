import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import * as actions from '../../actions';
import RenderShipmentType from '../booking/renderShipmentType';
import RenderStation from '../booking/RenderStation';
import validate from '../util/validate';
import BookingAirNew from './bookingAirNew';
import BookingOceanNew from './bookingOceanNew';
import RenderConsignee from './renderConsignee';

class BookingNew extends Component {
  render() {
    const { air_ocean, ConsigneeID, station } = this.props;
    return (
          <div className="col-sm-10 col-sm-offset-1 container-bottom">
            <h2 className="page-header">Create New Booking</h2>
            <fieldset className="for-panel">
              <div className="row">
                {/* <div className="col-sm-6 ">
                  <strong>BOOKING: NEW</strong>
                </div> */}
                <Field label="Consignee Name:" name="CNeeID" component={RenderConsignee} />
                <Field label="Shipment Type:" name="ShipmentType" component={RenderShipmentType} />
                <Field label="Station:" name="station" component={RenderStation} />
              </div>
              <hr />
              {air_ocean === 'OCEAN' && ConsigneeID && station ? (
                <BookingOceanNew ShipmentType={air_ocean} CNeeID={ConsigneeID} station={station} />
              ) : air_ocean === 'AIR' && ConsigneeID && station ? (
                <BookingAirNew ShipmentType={air_ocean} CNeeID={ConsigneeID} station={station} />
              ) : (
                ''
              )}
            </fieldset>
          </div>
    );
  }
}

function mapStateToProps(state) {
  const selector = formValueSelector('BookingNew');
  const air_ocean = selector(state, 'ShipmentType');
  const ConsigneeID = selector(state, 'CNeeID');
  const station = selector(state, 'station');

  return {
    air_ocean,
    station,
    ConsigneeID,
    customer: state.util.customer,
    user: state.auth.user,
    newBookingID: state.bookings.newBookingID
  };
}

BookingNew = reduxForm({
  form: 'BookingNew',
  enableReinitialize: true,
  validate
})(BookingNew);

BookingNew = withRouter(
  connect(
    mapStateToProps,
    actions
  )(BookingNew)
);
export default BookingNew;
