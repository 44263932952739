import React from 'react';
import { Switch } from 'react-router-dom';
import Footer from '../components/footer';
import Header from '../components/header';
import '../css/mainLayout.css';
import routes from '../routes';
import PrivateRoute from '../utils/privateRoute';

class Main extends React.Component {
  render() {
    const routeComponents = routes.map((prop, key) => (
      <PrivateRoute exact path={prop.path} component={prop.component} key={key} />
    ));
    return (
      <div>
        <Header />
        <div className="container main-layout">
          <Switch>{routeComponents}</Switch>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Main;
