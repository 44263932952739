import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import S3Uploader from './util/s3_uploader'
import styles from './upload.module.css'

const clients = [
  {
    label: 'Upload for Readerlink',
    clientName: 'Readerlink',
    roles: ['control'],
    s3Path: 'readerlink_template_uploads',
    type: 'readerlink-auto-booking-creation',
  },
  {
    label: 'Upload for Safeworks',
    clientName: 'Safeworks',
    roles: ['control', 'operator'],
    s3Path: 'safeworks_template_uploads',
    type: 'safeworks-auto-booking-creation',
  },
]

// roles are not used atm, for future purpose only
const AGENT_CLIENTS = [
  {
    label: 'General upload',
    clientName: '',
    roles: ['agent'],
    s3Path: 'template_uploads',
    type: 'auto-booking-creation',
  },
  {
    label: 'Upload for Safeworks',
    clientName: 'Safeworks',
    roles: ['agent'],
    s3Path: 'safeworks_template_uploads',
    type: 'safeworks-auto-booking-creation',
  },
]

function UploadModal(props) {
  const { showModal, closeModal, role } = props
  const renderContent = () => {
    if (role === 'agent') {
      return (
        <div className={styles.container}>
          {AGENT_CLIENTS.map(({ s3Path, type, label, clientName }) => (
            <div className={styles.containerItem} key={type}>
              <S3Uploader
                url={s3Path}
                close={closeModal}
                type={type}
                client={clientName}
              >
                <div className={styles.placeholder}>{label}</div>
              </S3Uploader>
            </div>
          ))}
        </div>
      )
    }

    return (
      <div className={styles.container}>
        {clients.map(({ clientName, label, type, s3Path, roles }) => {
          return (
            roles.includes(role.toLowerCase()) && (
              <div className={styles.containerItem} key={clientName}>
                <S3Uploader
                  url={s3Path}
                  close={closeModal}
                  client={clientName}
                  type={type}
                >
                  <div className={styles.placeholder}>{label}</div>
                </S3Uploader>
              </div>
            )
          )
        })}
      </div>
    )
  }
  return (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Upload files here</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Guide to successful uploading:</h4>
        <p>Drag & drop to the square below or click it to begin upload</p>
        <p>Only Excel files are allowed</p>
        {renderContent()}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={closeModal}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default UploadModal
