// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Switch from '@material-ui/core/Switch';
// @material-ui/icons
import Assignment from '@material-ui/icons/Assignment';
import Edit from '@material-ui/icons/Edit';
import LockOpen from '@material-ui/icons/LockOpen';
import PersonAdd from '@material-ui/icons/PersonAdd';
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
// react component for creating dynamic tables
import ReactTable from 'react-table';
// import { listUsers } from '../../actions/users';
import * as userActions from '../../actions/users';
import { cardTitle } from '../../assets/jss/material-dashboard-pro-react.jsx';
import buttonsStyle from '../../assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx';
import switchStyle from '../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';
import sweetAlertStyle from '../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle';
import CustomSweetAlert from '../../components/Alerts/SweetAlert';
import Card from '../../components/Card/Card.jsx';
import CardBody from '../../components/Card/CardBody.jsx';
import CardHeader from '../../components/Card/CardHeader.jsx';
import CardIcon from '../../components/Card/CardIcon.jsx';
import Button from '../../components/CustomButtons/Button.jsx';
// core components
import GridContainer from '../../components/Grid/GridContainer.jsx';
import GridItem from '../../components/Grid/GridItem.jsx';
import CreateModal from '../../components/Modal/Create';
import EditUserModal from '../../components/Modal/Edit';
import Spinner from '../../components/util/spinner';
import CreateUserWizard from '../../components/WizardForm/Wizard';
import ResetPassword from './reset';

const styles = {
  ...buttonsStyle,
  ...switchStyle,
  ...sweetAlertStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  }
};

class Account extends React.Component {
  state = {
    createUser: false,
    sweetAlert: null,
    edit: false,
    resetPassword: false,
    userState: {}
  };

  componentDidMount() {
    this.props.listUsers();
  }

  handleClickOpen = modal => {
    const x = [];
    x[modal] = true;
    this.setState(x);
  };

  hideAlert = () => {
    this.setState({ sweetAlert: null });
  };

  handleClose = modal => {
    const x = [];
    x[modal] = false;
    this.setState({ userState: {} });
    this.setState(x);
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  showActivation = ({ Status, EmployeeNo }) => {
    const {
      classes: { button, success, danger }
    } = this.props;
    this.setState({
      sweetAlert: (
        <SweetAlert
          warning
          style={{ display: 'block', marginTop: '-250px' }}
          title="Are you sure?"
          onConfirm={() => this.successToggleUser(Status, EmployeeNo)}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={`${button} ${success}`}
          cancelBtnCssClass={`${button} ${danger}`}
          confirmBtnText={Status ? 'Yes, deactivate!' : 'Yes, activate!'}
          cancelBtnText="Cancel"
          showCancel
        >
          {Status ? 'User will be deactivated' : 'User will be activated'}
        </SweetAlert>
      )
    });
  };

  successToggleUser = (status, id) => {
    const {
      classes: { button, success },
      toggleUser
    } = this.props;
    const updateStatus = status === true ? 'D' : 'A';
    toggleUser({ status: updateStatus, id });
    this.setState({
      sweetAlert: (
        <SweetAlert
          success
          style={{ display: 'block', marginTop: '-250px' }}
          title="Done!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={`${button} ${success}`}
        >
          {status ? 'User has been deactivated' : 'User has been activated'}
        </SweetAlert>
      )
    });
  };

  render() {
    const { classes, users, setEditingUser, resetPasswordStatus, createUserStatus, editUserStatus } = this.props;
    const { resetPassword, edit, userState, sweetAlert, createUser } = this.state;
    if (!users) return <Spinner />;
    const userData = users.map((user, key) => ({
      ...user,
      actions: (
        <div className="actions-right">
          <Button
            justIcon
            round
            simple
            onClick={() => {
              setEditingUser(user);
              this.handleClickOpen('edit');
            }}
            color="success"
            className="edit"
          >
            <Edit />
          </Button>{' '}
          <Button
            justIcon
            round
            simple
            onClick={() => {
              this.setState({ userState: { id: user.EmployeeNo } });
              this.handleClickOpen('resetPassword');
            }}
            color="warning"
            className="edit"
          >
            <LockOpen />
          </Button>{' '}
          <Switch
            checked={user.Status}
            onChange={() => this.showActivation(user)}
            value={user.Status ? 'A' : 'D'}
            classes={{
              switchBase: classes.switchBase,
              checked: classes.switchChecked,
              thumb: classes.switchIcon,
              track: classes.switchBar
            }}
          />
        </div>
      )
    }));
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader stats color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <Button
                color="success"
                round
                simple
                className={classes.marginRight}
                onClick={() => this.handleClickOpen('createUser')}
              >
                <PersonAdd className={classes.icons} />
                Create User
              </Button>
            </CardHeader>
            <CardBody>
              <EditUserModal item={userState} open={edit} title="Edit User" onClose={() => this.handleClose('edit')} />
              <CreateModal
                item={userState}
                open={resetPassword}
                title="Reset Password"
                onClose={() => this.handleClose('resetPassword')}
                Component={ResetPassword}
              />
              <CreateModal
                maxWidth="lg"
                item={null}
                open={createUser}
                title="User Creation"
                onClose={() => this.handleClose('createUser')}
                Component={CreateUserWizard}
              />
              {resetPasswordStatus && resetPasswordStatus === 'success' ? (
                <CustomSweetAlert title="Password Reset Success!" />
              ) : null}
              {createUserStatus && createUserStatus === 'success' ? (
                <CustomSweetAlert title="User succesfully created!" />
              ) : null}
              {editUserStatus && editUserStatus === 'success' ? (
                <CustomSweetAlert title="User succesfully updated!" />
              ) : null}
              {sweetAlert}
              <ReactTable
                data={userData}
                filterable
                defaultFilterMethod={(filter, row, column) => {
                  const id = filter.pivotId || filter.id;
                  return row[id] !== undefined ? String(row[id]).startsWith(filter.value) : true;
                }}
                columns={[
                  {
                    Header: 'Username',
                    accessor: 'EmployeeID'
                  },
                  {
                    Header: 'First Name',
                    accessor: 'FirstName'
                  },
                  {
                    Header: 'Last Name',
                    accessor: 'LastName'
                  },
                  {
                    Header: 'Email',
                    accessor: 'Email'
                  },
                  {
                    Header: 'Actions',
                    accessor: 'actions',
                    sortable: false,
                    filterable: false
                  }
                ]}
                defaultPageSize={20}
                showPaginationTop
                showPaginationBottom={false}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = ({ users }) => ({
  users: users.allUsers,
  resetPasswordStatus: users.resetPasswordStatus,
  editUserStatus: users.editUserStatus,
  createUserStatus: users.createUserStatus
});

export default connect(
  mapStateToProps,
  userActions
)(withStyles(styles)(Account));
