import _ from 'lodash';
import moment from 'moment';
// import jwtDecode from 'jwt-decode';
import {
  CREATE_PO_COMMENTS,
  DELETE_PO_COMMENT,
  GET_PO_COMMENT,
  GET_PO_HEADER,
  MOVE_PO_BOOKING,
  SEARCH_PO,
  UPDATE_PO_HEADER
} from '../actions/types';

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case SEARCH_PO:
      const POSearchResults = _.map(action.payload).map(x => {
        return _.assign(x, {
          CargoReadyDate: x.CargoReadyDate ? moment(x.CargoReadyDate).format('MM/DD/YYYY') : 'N/A',
          CustomerInHandsDate: x.CustomerInHandsDate ? moment(x.CustomerInHandsDate).format('MM/DD/YYYY') : 'N/A'
        });
      });
      return { ...state, POSearchResults };
    case GET_PO_HEADER:
      return { ...state, POHeader: action.payload };
    case GET_PO_COMMENT:
      return { ...state, POComment: action.payload };
    case CREATE_PO_COMMENTS:
      return {
        ...state,
        POComment: [...state.POComment, action.payload]
      };
    case DELETE_PO_COMMENT: {
      const newID = parseInt(action.id, 10);
      return {
        ...state,
        POComment: state.POComment.filter(comment => comment.CommentID !== newID)
      };
    }
    case UPDATE_PO_HEADER: {
      const POHeader = action.payload;
      return {
        ...state,
        updated: true,
        POHeader
      };
    }
    case MOVE_PO_BOOKING: {
      return {
        ...state,
        POMoved: true
      };
    }
    default:
      return state;
  }
}
