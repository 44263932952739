import React, { Component } from 'react';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import * as actions from '../../actions';
import { ADD_ERROR } from '../../actions/types';
import '../../css/sendmail.css';
import Footer from '../footer';
import Header from '../header';
import RenderFieldNotification from './renderFieldNotification';
import Spinner from './spinner';
import validate from './validate';

class SendNotification extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    const { BookingID } = this.props.match.params;
    this.props.fetchBookingForNotification(BookingID);

    sleep(1500).then(() => {
      if (this.props.user.accessLevel === 'CUSTOMER') {
        if (this.props.user.CustomerID !== this.props.initialValues.consigneeID.toString()) {
          this.props.history.push('/p/search');
          this.props.dispatch(this.addError('ACCESS DENIED'));
        }
      }
      if (this.props.user.accessLevel === 'AGENT') {
        // console.log(this.props.user.id, this.props.initialValues.Agent);
        if (this.props.user.id.toString() !== this.props.initialValues.Agent) {
          this.props.history.push('/p/search');
          this.props.dispatch(this.addError('ACCESS DENIED'));
        }
      }
    });
  }

  addError(error) {
    return {
      type: ADD_ERROR,
      payload: error
    };
  }

  renderField(field) {
    const {
      label,
      disabled,
      input,
      meta: { touched, error }
    } = field;
    return (
      <div className="form-group form-group-sm">
        <div className="row">
          <label className="col-sm-3 col-form-label">{label}</label>
          <div className="col-sm-9">
            <input className="form-control" {...input} disabled={disabled} />
            <div className="text-danger">{touched ? error : ''}</div>
          </div>
        </div>
      </div>
    );
  }

  renderTextArea(field) {
    const { label, input } = field;
    return (
      <div className="form-group form-group-sm">
        <div className="row">
          <label className="col-sm-3 col-form-label">{label}</label>
          <div className="col-sm-9">
            <textarea className="form-control" rows="10" {...input} />
          </div>
        </div>
      </div>
    );
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="container">
          <h2 className="text-center">Send Booking Notification</h2>
          <div className="col-sm-10 col-sm-offset-1">
            <div className="alert alert-danger">
              <strong>Oops!</strong> {this.props.errorMessage}
            </div>
            <Link to={`/p/booking/${this.props.match.params.BookingID}`}>Go back</Link>
          </div>
        </div>
      );
    }
  }

  onSubmit({ to, cc, from, subject, message }) {
    if (!cc) {
      cc = '';
    }
    const { BookingID } = this.props.match.params;
    message = message.split('\n');
    message = message.map(item => `<p>${item}</p>`).join('');
    // console.log(message);
    this.props.sendMail({ to, cc, from, subject, message }, () => {
      this.props.history.push(`/p/booking/${BookingID}`);
      alert('Email sent!');
      // this.forceUpdate()
    });
  }

  render() {
    const { initialValues, handleSubmit, errorMessage } = this.props;
    if (!initialValues && !errorMessage) {
      return <Spinner />;
    }
    if (errorMessage) {
      return (
        <div>
          <Header />
          {this.renderAlert()}
          <Footer />
        </div>
      );
    }
    // const { booking } = this.props;
    // console.log(notification);
    return (
      <div>
        <div className="col-sm-10 col-sm-offset-1">
          <h2 className="text-center">Send Booking Notification</h2>
          {this.renderAlert()}
          <form onSubmit={handleSubmit(this.onSubmit)}>
            {this.props.user.accessLevel !== 'CUSTOMER' ? (
              <Field label="From:" name="from" component={this.renderField} />
            ) : (
              <Field label="From:" name="from" component={this.renderField} disabled />
            )}

            <Field
              CNeeID={initialValues.consigneeID}
              CNeeName={initialValues.CNeeName}
              label="To:"
              name="to"
              component={RenderFieldNotification}
            />
            <Field
              CNeeID={initialValues.consigneeID}
              CNeeName={initialValues.CNeeName}
              label="Cc:"
              name="cc"
              component={RenderFieldNotification}
            />
            <Field label="Subject:" name="subject" component={this.renderField} />
            <Field label="Message:" name="message" component={this.renderTextArea} />
            <div className="pull-right">
              <ButtonToolbar>
                <Button bsStyle="default" type="submit">
                  Send Mail
                </Button>
                <Button bsStyle="danger" href={`/p/booking/${this.props.match.params.BookingID}`}>
                  Go back
                </Button>
              </ButtonToolbar>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ bookings, auth }) {
  return {
    initialValues: bookings.notification,
    user: auth.user,
    errorMessage: bookings.errorMessage
  };
}

SendNotification = reduxForm({
  form: 'SendNotification',
  enableReinitialize: true,
  validate
})(SendNotification);

SendNotification = connect(
  mapStateToProps,
  actions
)(SendNotification);
export default SendNotification;
