import React from 'react';

const RenderStation = ({ label, input, disabled, meta: { touched, error } }) => {
  const statusOption = [
    { value: '', display: 'Select OTX Station...' },
    { value: 'LAX', display: 'LAX' },
    { value: 'JFK', display: 'JFK' },
    { value: 'ORD', display: 'ORD' }
  ];
  return (
    <div className="form-group form-group-sm col-sm-6">
      <div className="row">
        <label className="col-sm-4 col-form-label">{label}</label>
        <div className="col-sm-7">
          <select className="form-control" {...input} disabled={disabled}>
            {statusOption.map(choice => (
              <option value={choice.value} key={choice.value}>
                {choice.display}
              </option>
            ))}
          </select>
          <div className="text-danger">{touched ? error : ''}</div>
        </div>
      </div>
    </div>
  );
};

export default RenderStation;
