const validate = values => {
  const errors = {};
  if (!values.CNeeID) {
    errors.CNeeID = 'Required!';
  }
  if (values.mawb && !/^[0-9]{3}[-]{1}[0-9]{8}$/i.test(values.mawb)) {
    errors.mawb = 'Correct format: XXX-XXXXXXXX, no spaces, numbers only!';
  }
  if (values.ContainerNo && !/^[A-Za-z]{4}[0-9]{7}$/i.test(values.ContainerNo)) {
    errors.ContainerNo = 'Incorrect format, check container# and try again';
  }
  // if (values.ETDFrom && !/^[0-9]{1,2}[/]{1}[0-9]{1,2}[/]{1}[20]{2}[0-9]{2}$/i.test(values.ETDFrom)) {
  //   errors.ETDFrom = 'Date format: MM/DD/YYYY, year > 2000';
  // }
  // if (values.ETDTo && !/^[0-9]{1,2}[/]{1}[0-9]{1,2}[/]{1}[20]{2}[0-9]{2}$/i.test(values.ETDTo)) {
  //   errors.ETDTo = 'Date format: MM/DD/YYYY, year > 2000';
  // }
  // if (values.ETAFirstFrom && !/^[0-9]{1,2}[/]{1}[0-9]{1,2}[/]{1}[20]{2}[0-9]{2}$/i.test(values.ETAFirstFrom)) {
  //   errors.ETAFirstFrom = 'Date format: MM/DD/YYYY, year > 2000';
  // }
  // if (values.ETAFirstTo && !/^[0-9]{1,2}[/]{1}[0-9]{1,2}[/]{1}[20]{2}[0-9]{2}$/i.test(values.ETAFirstTo)) {
  //   errors.ETAFirstTo = 'Date format: MM/DD/YYYY, year > 2000';
  // }
  // if (values.ETAFinalFrom && !/^[0-9]{1,2}[/]{1}[0-9]{1,2}[/]{1}[20]{2}[0-9]{2}$/i.test(values.ETAFinalFrom)) {
  //   errors.ETAFinalFrom = 'Date format: MM/DD/YYYY, year > 2000';
  // }
  // if (values.ETAFinalTo && !/^[0-9]{1,2}[/]{1}[0-9]{1,2}[/]{1}[20]{2}[0-9]{2}$/i.test(values.ETAFinalTo)) {
  //   errors.ETAFinalTo = 'Date format: MM/DD/YYYY, year > 2000';
  // }
  // if (
  //   values.CargoReadyDateFrom &&
  //   !/^[0-9]{1,2}[/]{1}[0-9]{1,2}[/]{1}[20]{2}[0-9]{2}$/i.test(values.CargoReadyDateFrom)
  // ) {
  //   errors.CargoReadyDateFrom = 'Date format: MM/DD/YYYY, year > 2000';
  // }
  // if (values.CargoReadyDateTo && !/^[0-9]{1,2}[/]{1}[0-9]{1,2}[/]{1}[20]{2}[0-9]{2}$/i.test(values.CargoReadyDateTo)) {
  //   errors.CargoReadyDateTo = 'Date format: MM/DD/YYYY, year > 2000';
  // }
  if (!values.email) {
    errors.email = 'Required!';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (!values.to) {
    errors.to = 'Required!';
    // } else {
    //   console.log(typeof values.to);
    //   let toEmail = values.to.split(/[,;]/);
    //   toEmail.forEach(i => {
    //     if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(i)) {
    //       errors.to = 'Invalid email address';
    //     }
    //   });
  }
  // if (values.cc) {
  //   const ccEmail = values.cc.split(/[,;]/);
  //   // console.log(ccEmail);
  //   ccEmail.forEach(i => {
  //     if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(i)) {
  //       errors.cc = 'Invalid email address';
  //     }
  //   });
  // }

  if (!values.subject) {
    errors.subject = 'Required!';
  }
  if (!values.CNeeID) {
    errors.CNeeID = 'Required!';
  }
  if (!values.station) {
    errors.station = 'Required!';
  }
  if (values.ETD) {
    if (!values.CarrierName) {
      errors.CarrierName = 'Required!';
    }
  }
  if (!values.ShipmentType) {
    errors.ShipmentType = 'Required!';
  }
  if (!values.from) {
    errors.from = 'Required!';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.from)) {
    errors.from = 'Invalid email address';
  }
  return errors;
};

export default validate;
