import AdminAuth from './AdminAuth';
import NewBooking from './components/agent/bookingNew';
import BookingMain from './components/booking';
import CustomerDashboard from './components/customer/Dashboard';
import FourOhFour from './components/FourOhFour';
import POMain from './components/po/poMain';
import POResults from './components/po/poResults';
import POSearch from './components/po/searchPO';
import ReportResult from './components/report/reportResult';
import ReportSearch from './components/report/reportSearch';
import Search from './components/search';
import SearchResult from './components/summary';
import SendNotification from './components/util/sendnotification';
import CustomerAuth from './CustomerAuth';
import Account from './views/Account/account';

const routes = [
  { path: '/p/dashboard', component: CustomerAuth(CustomerDashboard) },
  { path: '/p/report', component: ReportSearch },
  { path: '/p/report/show', component: ReportResult },
  { path: '/p/po/search', component: POSearch },
  { path: '/p/po/results', component: POResults },
  { path: '/p/po/:PONo/:LineNum', component: POMain },
  { path: '/p/search', component: Search },
  { path: '/p/booking/result', component: SearchResult },
  { path: '/p/booking/new', component: NewBooking },
  { path: '/p/booking/:BookingID', component: BookingMain },
  { path: '/p/sendmail/:BookingID', component: SendNotification },
  { path: '/p/account', component: AdminAuth(Account) },
  { path: '*', component: FourOhFour }
];

export default routes;
