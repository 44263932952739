import {
  ADD_ERROR,
  CLOSE_CREATE_MODAL,
  CLOSE_MODAL,
  DISMISS_ERROR,
  FETCH_ADDRESS_BOOK,
  GET_ALL_OPERATORS,
  GET_CARRIER_LIST,
  GET_CUSTOMER_LIST,
  HIDE_ALERT,
  IF_COMM_INVOICE_EXISTS,
  IF_HBL_EXISTS,
  IF_MBL_EXISTS,
  IF_PACKINGLIST_EXISTS,
  IS_LOADING,
  OPEN_CREATE_MODAL,
  OPEN_MODAL,
  OPEN_UPLOAD_TEMPLATE_MODAL,
  SHOW_ALERT,
  UPLOAD_COMPLETE,
  UPLOAD_RESET
} from '../actions/types';

const initialState = {
  hblExist: '404',
  mblExist: '404',
  isLoading: false,
  commInvoiceExist: '404',
  packingListExist: '404',
  showModal: false,
  showUploadTemplateModal: false,
  uploaded: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case IF_HBL_EXISTS:
      // console.log(action.payload);
      return { ...state, hblExist: true };
    // case DOES_NOT_EXIST:
    //   return { ...state, isExist: '404' };
    case IF_MBL_EXISTS:
      // console.log(action.payload);
      return { ...state, mblExist: true };
    // case DOES_NOT_EXIST:
    //   return { ...state, isExist: '404' };
    case IF_COMM_INVOICE_EXISTS:
      // console.log(action.payload);
      return { ...state, commInvoiceExist: true };
    // case DOES_NOT_EXIST:
    //   return { ...state, isExist: '404' };
    case IF_PACKINGLIST_EXISTS:
      // console.log(action.payload);
      return { ...state, packingListExist: true };
    // case DOES_NOT_EXIST:
    //   return { ...state, isExist: '404' };
    case IS_LOADING:
      return { isLoading: action.payload };
    case ADD_ERROR:
      return { ...state, errors: action.payload };
    case SHOW_ALERT: {
      return { ...state, showAlert: true };
    }
    case HIDE_ALERT: {
      return { ...state, showAlert: false };
    }
    case DISMISS_ERROR:
      return { ...state, errors: null };
    case OPEN_MODAL:
      return { ...state, showModal: true };
    case OPEN_UPLOAD_TEMPLATE_MODAL:
      return { ...state, showUploadTemplateModal: true };
    case CLOSE_MODAL:
      return { ...state, showModal: false, showUploadTemplateModal: false };
    case FETCH_ADDRESS_BOOK: {
      return { ...state, addressBook: action.payload };
    }
    case OPEN_CREATE_MODAL:
      return { ...state, showCreateModal: true };
    case CLOSE_CREATE_MODAL:
      return { ...state, showCreateModal: false };
    case GET_CUSTOMER_LIST: {
      const defaultCustomer = {
        customerNo: '',
        companyName: ''
      };
      const customer = action.payload;
      customer.unshift(defaultCustomer);
      return { ...state, customer };
    }
    case GET_ALL_OPERATORS: {
      const defaultOperator = {
        EmployeeNo: 0,
        FirstName: '',
        LastName: '',
        station: 'ALL'
      };
      const operators = action.payload;
      operators.unshift(defaultOperator);
      return { ...state, operators };
    }

    case GET_CARRIER_LIST: {
      const defaultCarrier = {
        CarrierName: ''
      };

      const carrier = action.payload;
      carrier.unshift(defaultCarrier);
      return { ...state, carrier };
    }

    case UPLOAD_COMPLETE: {
      return {
        ...state,
        uploaded: {
          status: action.payload.status,
          message: action.payload.message
        }
      };
    }

    case UPLOAD_RESET: {
      return {
        ...state,
        uploaded: {}
      };
    }

    default:
      return state;
  }
}
