import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
// import the PO components below
import Spinner from '../util/spinner';
import POComment from './poComment';
import PoHeader from './poHeader';

class POMain extends Component {
  componentDidMount() {
    // console.log(this.props.match.params);
    const { PONo, LineNum } = this.props.match.params;
    this.props.fetchPO(PONo, LineNum);
  }

  renderContent() {
    switch (this.props.user.accessLevel) {
      case null:
        return;
      case 'AGENT':
      case 'CUSTOMER':
      case 'CONTROL':
      case 'OPERATOR':
        return (
          <div>
            {(() => {
              return (
                <div>
                  <PoHeader POHeader={this.props.POHeader} />
                </div>
              );
            })()}

            <POComment />
          </div>
        );

      default:
        return (
          <div>
            <h2 className="text-danger">Access Denied</h2>
          </div>
        );
    }
  }

  render() {
    const { POHeader } = this.props;
    if (!POHeader) {
      return <Spinner />;
    }
    return (
      <div>
        <h2 className="text-center">
          PO #{POHeader.PONo} / Line #{POHeader.LineNum}
        </h2>
        {this.renderContent()}
      </div>
    );
  }
}

function mapStateToProps({ auth, POs }) {
  return {
    user: auth.user,
    POHeader: POs.POHeader
  };
}

export default connect(
  mapStateToProps,
  actions
)(POMain);
