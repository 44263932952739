import axios from 'axios'
import React from 'react'
import DropzoneS3Uploader from 'react-dropzone-s3-uploader'
import { connect } from 'react-redux'
import { ROOT_URL } from '../../actions/index'
import * as actions from '../../actions/utils'

const defaultStyle = {
  width: 276,
  height: 200,
  border: 'dashed 2px #999',
  borderRadius: 5,
  position: 'relative',
  cursor: 'pointer',
  overflow: 'hidden',
  display: 'flex',
}
class S3Uploader extends React.Component {
  handleFinishedUpload = async info => {
    const { type, close, uploading, resetUpload, client = '', url } = this.props

    resetUpload()
    // general upload for all customers
    if (type === 'auto-booking-creation') {
      try {
        const {
          data: { status, message },
        } = await axios.get(`${ROOT_URL}/api/booking/process-template`)
        close()
        uploading(status, message)
      } catch (err) {
        const {
          data: { status, message },
        } = err.response

        close()
        uploading(status, message)
      }
      return
    }

    // template upload for safeworks only
    if (type === 'safeworks-auto-booking-creation') {
      try {
        const {
          data: { status, message },
        } = await axios.get(
          `${ROOT_URL}/api/booking/process-template?path=${url}&client=${client.toLowerCase()}`,
        )
        close()
        uploading(status, message)
      } catch (err) {
        const {
          data: { status, message },
        } = err.response

        close()
        uploading(status, message)
      }
      return
    }
    // template upload for readerlink only
    if (type === 'readerlink-auto-booking-creation') {
      try {
        const {
          data: { status, message },
        } = await axios.get(
          `${ROOT_URL}/api/booking/readerlink-process-template?path=${url}`,
        )
        close()
        uploading(status, message)
      } catch (err) {
        const {
          data: { status, message },
        } = err.response

        close()
        uploading(status, message)
      }
      return
    }

    if (type === 'document') {
      close()
      uploading('ok', 'Document uploaded!')
      return
    }

    throw new Error(`Unhandled type: ${type}`)
  }

  render() {
    const { url, children, style = {} } = this.props

    const uploadOptions = {
      server: ROOT_URL,
      //   signingUrlQueryParams: { uploadType: 'avatar' },
      s3path: url + '/',
      contentDisposition: null,
    }
    const s3Url = 'http://otxusa.s3-us-west-1.amazonaws.com'

    return (
      <DropzoneS3Uploader
        onFinish={this.handleFinishedUpload}
        s3Url={s3Url}
        maxSize={1024 * 1024 * 15}
        upload={uploadOptions}
        style={{ ...defaultStyle, ...style }}
        passChildrenProps={false}
      >
        {children}
      </DropzoneS3Uploader>
    )
  }
}

export default connect(null, actions)(S3Uploader)
