// import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import moment from 'moment'; // Example for onSort prop
import React, { Component } from 'react';
import Datatable from 'react-bs-datatable'; // Import this package
import { CSVLink } from 'react-csv';
import { connect } from 'react-redux';
import '../../css/report.css';
import Spinner from '../util/spinner';

class ReportResult extends Component {
  render() {
    const { results } = this.props;
    if (!results) {
      return <Spinner />;
    }

    // Report for operations to see
    const header = [
      {
        title: 'BookingID',
        prop: 'BookingID',
        sortable: true,
        filterable: true
      },
      {
        title: 'Shipment Type',
        prop: 'ShipmentType',
        sortable: true,
        filterable: true
      },
      { title: 'Status', prop: 'bkStatus', sortable: true, filterable: true },
      {
        title: 'Consignee',
        prop: 'CNeeName',
        sortable: true,
        filterable: true
      },
      { title: 'SKU #', prop: 'SKUNo', sortable: false, filterable: true },
      { title: 'PO #', prop: 'PONo', sortable: true, filterable: true },
      {
        title: 'Shipper Name',
        prop: 'ShipperName',
        sortable: true,
        filterable: false
      },
      {
        title: 'Description/Title',
        prop: 'Description',
        sortable: true,
        filterable: false
      },
      { title: 'MBL', prop: 'MBL', sortable: true, filterable: true },
      {
        title: 'Container #',
        prop: 'ContainerNo',
        sortable: true,
        filterable: true
      },
      { title: 'ETD', prop: 'ETD', sortable: true, filterable: true },
      {
        title: 'ETA First',
        prop: 'ETAFirst',
        sortable: true,
        filterable: true
      },
      { title: 'ETA Final', prop: 'ETAFinal', sortable: true, filterable: true }

      // BookingID: item.BookingID,
      // bkStatus: item.bkStatus,
      // ETD: item.ETD,
      // ETAFinal: item.ETAFinal,
      // OrgPort: item.OrgPort,
      // FnlPort: item.FnlPort,
      // CNeeName: item.CNeeName,
      // Description: v.CargoDescription,
      // SKUNo: v.SKUNo,
      // PONo: v.PONo,
      // CargoReadyDate: v.CargoReadyDate,
      // ContainerNo: v.ContainerNo,
      // MBL: v.MBL,
      // ShipperName: v.ShipperName,
      // CargoVol: v.CargoVol,
      // cargoWt: v.cargoWt,
      // CopyQty: v.CopyQty,
      // CartonQty: v.CartonQty
    ];

    const onSortFunction = {
      date(columnValue) {
        // Convert the string date format to UTC timestamp
        // So the table could sort it by date instead of sort it by string
        return moment(columnValue, 'Do MMMM YYYY').valueOf();
      }
    };

    return (
      <React.Fragment>
        <h2 className="page-header">Report Results</h2>
        <div className="csv-btn">
          <CSVLink data={results} filename="report.csv" target="" className="btn btn-primary btn-sm">
            Download to view more...
          </CSVLink>
        </div>

        <Datatable
          id="reportTable"
          tableHeader={header}
          tableBody={results}
          keyName="userTable"
          tableClass="table-bordered table-striped table-condensed table-responsive"
          rowsPerPage={50}
          rowsPerPageOption={[10, 20, 50, 100]}
          initialSort={{ prop: 'BookingID', isAscending: false }}
          onSort={onSortFunction}
        />
        <div style={{ marginBottom: '100px' }}>
          <br />
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps({ bookings }) {
  return {
    results: bookings.results
  };
}
export default connect(mapStateToProps, null)(ReportResult);
