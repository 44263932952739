import React, { Component } from 'react';

export default class RenderCargoType extends Component {
  render() {
    const { label, input } = this.props;
    const typeOption = [
      { value: '', display: '' },
      { value: '33', display: 'CY/CY' },
      { value: '34', display: 'CFS/CFS' },
      { value: '688', display: 'CFS/CY' },
      { value: '35', display: 'CFS-HL' },
      { value: '243', display: 'RH' }
    ];
    return (
      <div className="form-group form-group-sm col-sm-6">
        <div className="row">
          <label className="col-sm-4 col-form-label">{label}</label>
          <div className="col-sm-7">
            <select className="form-control" {...input}>
              {typeOption.map(choice => (
                <option value={choice.value} key={choice.value}>
                  {choice.display}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  }
}
