import { Collapse } from 'antd';
import 'antd/lib/collapse/style/index.css';
import moment from 'moment';
import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import * as actions from '../../actions';
import RenderCargoType from '../booking/renderCargoType';
import CommInvoice from '../booking/renderCommInvoice';
import RenderContainer from '../booking/renderContainer';
import RenderDateField from '../booking/renderDateField';
import RenderField from '../booking/renderField';
import HBL from '../booking/renderHBL';
import MBL from '../booking/renderMBL';
import RenderProNO from '../booking/renderProNO';
import RenderRadio from '../booking/renderRadio';
import RenderTrucker from '../booking/renderTrucker';
import Spinner from '../util/spinner';

class BookingDetailCustomer extends Component {
  constructor(props) {
    super(props);
    this.onBkDetailSubmit = this.onBkDetailSubmit.bind(this);
    // this.approveBkDetails = this.approveBkDetails.bind(this);
  }

  componentDidMount() {
    // console.log(this.props.match.params);
    const { BookingID } = this.props.match.params;
    this.props.fetchBookingDetail(BookingID);
  }

  //   areDifferent(a, b) {
  //     var apStatusA = a.map( function(x){ return x.ApStatus1; } ).unique().sort();
  //     var apStatusB = b.map( function(y){ return y.ApStatus1; } ).unique().sort();
  //     return (apStatusA.join(',') !== apStatusB.join(',') );
  // }
  onBkDetailSubmit(values) {
    const { BookingID } = this.props.match.params;
    const { messageList } = this.props;
    const AllApproved = values.BkDetails.filter(d => d.ApStatus1 === 'A');
    // console.log(this.areDifferent(values.BkDetails, this.props.initialValues.BkDetails))
    values.BkDetails.forEach(d => {
      if (d.ApStatus1 && d.ApStatus1 === 'A') {
        d.ApStatus1 = `A${moment(Date.now()).format('MM/DD/YYYY')}`;
        d.bkStatus = 'AP';
        d.ApprovedDate = `${moment(Date.now()).format('MM/DD/YYYY')}`;
      }
      if (d.ApStatus1 && d.ApStatus1 === 'N') {
        d.ApStatus1 = `N${moment(Date.now()).format('MM/DD/YYYY')}`;
        d.bkStatus = 'TEMP';
        d.ApprovedDate = `${moment(Date.now()).format('MM/DD/YYYY')}`;
      }
      if (d.ApStatus1 && d.ApStatus1 === 'H') {
        d.ApStatus1 = `H${moment(Date.now()).format('MM/DD/YYYY')}`;
        d.bkStatus = 'HOLD';
        d.ApprovedDate = `${moment(Date.now()).format('MM/DD/YYYY')}`;
      }
    });
    // if All booking details are approved, set bkStatus = 'AP'
    if (AllApproved.length === values.BkDetails.length) {
      // API call to set Booking Header's bkstatus to AP
      this.props.approveAll(BookingID);
    }
    if (messageList) {
      const msg = messageList.map(m => m.Comments);
      var message = {};
      message.Comments = msg.join('');
    }
    // console.log(values);
    // console.log(message);
    if (message) {
      this.props.updateBookingDetailWithMsg(BookingID, message, values);
    } else {
      this.props.updateBookingDetail(BookingID, values);
    }
  }

  renderMove(field) {
    const { label, type, input } = field;
    return (
      <div className="form-group form-group-sm col-sm-6">
        <div className="row">
          <label className="col-sm-4 col-form-label">{label}</label>
          <div className="col-sm-7">
            <input className="form-control" type={type} {...input} />
          </div>
        </div>
      </div>
    );
  }

  renderUpdatedMsg() {
    if (this.props.updatedBkDetail) {
      return (
        <div className="alert alert-success text-center">
          <strong>Successfully updated!</strong>
        </div>
      );
    }
  }

  render() {
    const { booking, initialValues, handleSubmit } = this.props;
    const { Panel } = Collapse;
    const customPanelStyle = {
      border: '1px solid #999',
      borderRadius: 4,
      borderColor: '#bce8f1',
      backgroundColor: '#f8fdfd',
      marginBottom: 12,
      overflow: 'hidden'
    };
    const approvalPending = {
      color: 'red',
      paddingBottom: '10px'
    };
    const approved = {
      color: 'green',
      paddingBottom: '10px'
    };
    const tempApproved = {
      color: 'orange',
      paddingBottom: '10px'
    };
    if (!booking || !initialValues) {
      return <Spinner />;
    }
    if (!booking.BkDetails) {
      return null;
    }
    let carrier = '';

    if (booking.CarrierName) {
      carrier = booking.CarrierName.toLowerCase();
    }
    return booking.BkDetails.map((item, index) => (
      // console.log(booking);
      <Collapse key={item.BkDetailID}>
        <Panel
          header={`Detail #${index + 1}, PO #: ${item.PONo ? item.PONo : 'N/A'}, Description: ${item.CargoDescription}`}
          key={item.BkDetailID}
          style={customPanelStyle}
        >
          {(() => {
            if (item.bkStatus === 'AP' && item.ApStatus1 && item.ApStatus1.substring(0, 1) === 'A')
              return (
                <h4 style={approved}>
                  Approved
                  <i className="fa fa-check-square-o" style={{ marginLeft: '5px' }} aria-hidden="true" />
                </h4>
              );
            if (item.bkStatus === 'TEMP') return <h4 style={tempApproved}>Temp Approved</h4>;
            if (item.bkStatus === 'HOLD') return <h4 style={approvalPending}>HOLD</h4>;
            if (item.bkStatus === 'BR' && !item.ApStatus1)
              return (
                <h4 style={approvalPending}>
                  Approval Pending
                  <i className="fa fa-times-circle-o" style={{ marginLeft: '5px' }} aria-hidden="true" />
                </h4>
              );
            return null;
          })()}

          <form onSubmit={handleSubmit(this.onBkDetailSubmit)}>
            <div className="row">
              <Field
                label="Customer PO:"
                type="text"
                name={`BkDetails[${index}].csnPO`}
                component={RenderField}
                disabled
              />
              <Field
                label="Shipper Name:"
                type="text"
                name={`BkDetails[${index}].ShipperName`}
                component={RenderField}
                disabled
              />
            </div>
            <div className="row">
              {carrier ? (
                <Field
                  label="Container #:"
                  type="text"
                  carrier={carrier}
                  disabled
                  name={`BkDetails[${index}].ContainerNo`}
                  component={RenderContainer}
                />
              ) : (
                <Field
                  label="Container #:"
                  type="text"
                  name={`BkDetails[${index}].ContainerNo`}
                  component={RenderContainer}
                  disabled
                />
              )}
              <Field
                label="HBL #:"
                CNeeID={booking.CNeeID}
                hbl={item.HBL}
                type="text"
                name={`BkDetails.[${index}].HBL`}
                component={HBL}
                disabled
              />
            </div>
            <div className="row">
              <RenderCargoType label="Type:" CargoType={item.CargoType} CargoSize={item.CargoSize} />
              <Field
                label="MBL #:"
                CNeeID={booking.CNeeID}
                mbl={item.MBL}
                type="text"
                name={`BkDetails.[${index}].MBL`}
                component={MBL}
                disabled
              />
            </div>
            <div className="row">
              <Field
                label="Cargo Ready Date:"
                type="text"
                name={`BkDetails[${index}].CargoReadyDate`}
                component={RenderDateField}
                disabled
              />
              <Field
                label="Cargo Rap Date:"
                type="text"
                name={`BkDetails[${index}].CargoRapDate`}
                component={RenderDateField}
                disabled
              />
            </div>
            <div className="row">
              <Field
                label="Vendor:"
                type="text"
                name={`BkDetails[${index}].publisher`}
                component={RenderField}
                disabled
              />
            </div>
            <div className="row">
              <Field label="ISBN:" type="text" name={`BkDetails[${index}].ISBN`} component={RenderField} disabled />
              <Field
                label="Description/Title:"
                type="text"
                name={`BkDetails[${index}].CargoDescription`}
                component={RenderField}
                disabled
              />
            </div>
            <div className="row">
              <Field label="PO #:" type="text" name={`BkDetails[${index}].PONo`} component={RenderField} disabled />
              <Field
                label="Sales Order:"
                type="text"
                name={`BkDetails[${index}].saleOrder`}
                component={RenderField}
                disabled
              />
            </div>
            <div className="row">
              <Field
                label="PO Due Date #:"
                type="text"
                name={`BkDetails[${index}].CustomerInHandsDate`}
                component={RenderDateField}
                disabled
              />
              <Field
                label="PO Total:"
                type="text"
                name={`BkDetails[${index}].POTotal`}
                component={RenderField}
                disabled
              />
            </div>
            <div className="row">
              <Field
                label="Unit Cost:"
                type="text"
                name={`BkDetails[${index}].unitCost`}
                component={RenderField}
                disabled
              />
              <Field
                label="Buyer Code:"
                type="text"
                name={`BkDetails[${index}].buyCode`}
                component={RenderField}
                disabled
              />
            </div>
            <div className="row">
              <Field label="SKU #:" type="text" name={`BkDetails[${index}].SKUNo`} component={RenderField} disabled />
              <Field
                label="Delivery Addr:"
                type="text"
                name={`BkDetails[${index}].DelrAddr`}
                component={RenderField}
                disabled
              />
            </div>

            <div className="row">
              <Field
                label="Estimate Del. Date:"
                type="text"
                name={`BkDetails[${index}].EstDelivery`}
                component={RenderDateField}
              />
              <Field
                label="Actual Del. Date:"
                type="text"
                name={`BkDetails[${index}].ActDelivery`}
                component={RenderDateField}
              />
            </div>

            <div className="row">
              <Field
                label="Final Due Date:"
                type="text"
                name={`BkDetails.[${index}].FinalDueDate`}
                component={RenderDateField}
              />
              <Field label="Received by:" type="text" name={`BkDetails[${index}].ReceivedBy`} component={RenderField} />
            </div>
            <div className="row">
              <Field
                label="ProNo #:"
                type="text"
                vendorID={item.VendorURLID}
                trucker={this.props.trucker}
                name={`BkDetails[${index}].ProNo`}
                component={RenderProNO}
                disabled
              />
              <Field
                label="Trucker Name:"
                trucker={this.props.trucker}
                type="text"
                name={`BkDetails[${index}].VendorURLID`}
                disabled
                component={RenderTrucker}
              />
            </div>
            <div className="row">
              <Field label="HTS #:" type="text" name={`BkDetails[${index}].HTSNo`} component={RenderField} />
            </div>
            <div className="row">
              <Field
                label="Comm. Invoice No #:"
                CNeeID={booking.CNeeID}
                invoiceNo={item.CommInvoiceNo}
                type="text"
                name={`BkDetails[${index}].CommInvoiceNo`}
                component={CommInvoice}
              />
              {this.props.user.AccessGroup !== 'CUS_READ_ONLY' ? (
                <div className="form-group form-group-sm col-sm-6">
                  <div className="row">
                    <label className="col-sm-4 col-form-label" style={{ color: 'red' }}>
                      Approval Status:{' '}
                    </label>
                    <Field
                      label="Approve"
                      name={`BkDetails[${index}].ApStatus1`}
                      id={item.BkDetailID}
                      po={item.PONo}
                      description={item.CargoDescription}
                      type="radio"
                      value="A"
                      dvalue="A"
                      apStatus1={item.ApStatus1}
                      component={RenderRadio}
                    />
                    <Field
                      label="Temp approve"
                      name={`BkDetails[${index}].ApStatus1`}
                      po={item.PONo}
                      description={item.CargoDescription}
                      type="radio"
                      value="N"
                      dvalue="N"
                      apStatus1={item.ApStatus1}
                      component={RenderRadio}
                    />
                    <Field
                      label="Hold"
                      name={`BkDetails[${index}].ApStatus1`}
                      po={item.PONo}
                      description={item.CargoDescription}
                      type="radio"
                      value="H"
                      dvalue="H"
                      apStatus1={item.ApStatus1}
                      component={RenderRadio}
                    />
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
            <Table responsive bordered condensed>
              <thead>
                <tr>
                  <th className="text-center col-sm-2 col-sm-offset-1">VOLUME :</th>
                  <th className="text-center col-sm-2">WEIGHT :</th>
                  <th className="text-center col-sm-2">QTY. [UNITS] :</th>
                  <th className="text-center col-sm-2">QTY. [CARTON] :</th>
                  <th className="text-center col-sm-2">QTY. [PALLET] :</th>
                </tr>
              </thead>
              <tbody>
                <tr className="text-center">
                  <td>{item.CargoVol}</td>
                  <td>{item.cargoWt}</td>
                  <td>{item.CopyQty}</td>
                  <td>{item.CartonQty}</td>
                  <td>{item.PalletQty}</td>
                </tr>
                <tr>
                  <td colSpan="5">
                    Actual Commerical Value : {item.CopyQty} * {item.unitCost} = $
                    {Number(item.CopyQty * item.unitCost).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </Table>
            {this.renderUpdatedMsg()}
            <div className="row text-center">
              <button type="submit" name={`submit${index}`} className="btn btn-default">
                Update
              </button>
            </div>
          </form>
        </Panel>
      </Collapse>
    ));
  }
}

function mapStateToProps({ bookings, auth }) {
  return {
    booking: bookings.results,
    initialValues: bookings.results,
    user: auth.user,
    updatedBkDetail: bookings.updatedBkDetail,
    messageList: bookings.messageList
  };
}

BookingDetailCustomer = reduxForm({
  form: 'BookingDetailCustomer',
  enableReinitialize: true
})(BookingDetailCustomer);

BookingDetailCustomer = withRouter(connect(mapStateToProps, actions)(BookingDetailCustomer));
export default BookingDetailCustomer;
