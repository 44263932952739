/* eslint-disable react/display-name */
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import * as actions from '../actions/auth';

class PrivateRoute extends React.Component {
  componentDidMount() {
    let isSessionValid = false;
    const { user, clearUserSession } = this.props;
    if (user && user.exp) {
      isSessionValid = user.exp - Date.now() / 1000 > 0;
    }
    if (!isSessionValid) {
      clearUserSession();
    }
  }

  render() {
    const { isAuthenticated, component: Component, ...rest } = this.props;

    return (
      <Route
        {...rest}
        component={props =>
          isAuthenticated ? (
            <div>
              <Component {...props} />
            </div>
          ) : (
            <Redirect to="/signin" />
          )
        }
      />
    );
  }
}

function mapStateToProps({ auth }) {
  return {
    user: auth.user,
    isAuthenticated: auth.isAuthenticated
  };
}

export default connect(mapStateToProps, actions)(PrivateRoute);
