import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import Select from '@material-ui/core/Select';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';
import customCheckboxRadioSwitch from '../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';
import customSelectStyle from '../../assets/jss/material-dashboard-pro-react/customSelectStyle.jsx';
import CustomerSuggest from '../../views/customer_react_select';
// core components
import GridContainer from '../Grid/GridContainer.jsx';
import GridItem from '../Grid/GridItem.jsx';

const style = {
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center'
  },
  inputAdornmentIcon: {
    color: '#555'
  },
  choiche: {
    textAlign: 'center',
    cursor: 'pointer',
    marginTop: '20px'
  },
  ...customSelectStyle,
  ...customCheckboxRadioSwitch
};

const stations = ['JFK', 'LAX', 'ORD'];

class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      station: '',
      stationState: '',
      role: '',
      roleState: '',
      customers: [],
      customersState: ''
    };
  }

  handleCustomers = values => {
    this.setState({ customers: values });
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value, [`${event.target.name}State`]: 'success' });
  };

  sendState = () => {
    const { customers } = this.state;

    if (Array.isArray(customers)) {
      let customerStr = '';
      if (customers.length > 1) {
        const customerReducer = (acc, { value }) => `${value}, ${acc}`;
        customerStr = customers
          .reduce(customerReducer, '')
          .trim()
          .slice(0, -1);
      }

      if (customers.length === 1) {
        customerStr = `${customers[0].value}`;
      }
      return { ...this.state, customers: customerStr };
    }

    if (typeof customers === 'object') {
      return { ...this.state, customers: `${customers.value}` };
    }
  };

  isValidated = () => {
    const { roleState } = this.state;
    if (roleState === 'success') {
      return true;
    }
    if (roleState !== 'success') {
      this.setState({ roleState: 'error' });
    }

    return false;
  };

  renderStationDropdown() {
    const { classes } = this.props;
    const { station } = this.state;
    return (
      <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel htmlFor="station-select" className={classes.selectLabel}>
          Choose station
        </InputLabel>
        <Select
          MenuProps={{
            className: classes.selectMenu
          }}
          classes={{
            select: classes.select
          }}
          value={station}
          onChange={this.handleChange}
          inputProps={{
            name: 'station',
            id: 'station-select'
          }}
        >
          <MenuItem
            disabled
            classes={{
              root: classes.selectMenuItem
            }}
          >
            Choose Station
          </MenuItem>
          {stations.map(station => (
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }}
              key={station}
              value={station}
            >
              {station}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  render() {
    const { classes } = this.props;
    const { role, customers } = this.state;
    return (
      <div>
        <h4 className={classes.infoText}>Choose a role (required):</h4>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} lg={10}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                <div className={classes.choiche}>
                  <Radio
                    checked={role === 'CUSTOMER'}
                    onChange={this.handleChange}
                    name="role"
                    value="CUSTOMER"
                    tabIndex={-1}
                    checkedIcon={<i className={`fas fa-users ${classes.iconCheckboxIcon}`} />}
                    icon={<i className={`fas fa-users ${classes.iconCheckboxIcon}`} />}
                    classes={{
                      checked: classes.iconCheckboxChecked,
                      root: classes.iconCheckbox
                    }}
                  />
                  <h6>Customer</h6>
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <div className={classes.choiche}>
                  <Radio
                    checked={role === 'AGENT'}
                    onChange={this.handleChange}
                    name="role"
                    value="AGENT"
                    tabIndex={-1}
                    checkedIcon={<i className={`fas fa-user-tie ${classes.iconCheckboxIcon}`} />}
                    icon={<i className={`fas fa-user-tie ${classes.iconCheckboxIcon}`} />}
                    classes={{
                      checked: classes.iconCheckboxChecked,
                      root: classes.iconCheckbox
                    }}
                  />
                  <h6>Agent</h6>
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <div className={classes.choiche}>
                  <Radio
                    checked={role === 'OPERATOR'}
                    onChange={this.handleChange}
                    name="role"
                    value="OPERATOR"
                    tabIndex={-1}
                    checkedIcon={<i className={`fas fa-user ${classes.iconCheckboxIcon}`} />}
                    icon={<i className={`fas fa-user ${classes.iconCheckboxIcon}`} />}
                    classes={{
                      checked: classes.iconCheckboxChecked,
                      root: classes.iconCheckbox
                    }}
                  />
                  <h6>Operation</h6>
                </div>
              </GridItem>

              <GridItem xs={12} sm={12} md={3}>
                <div className={classes.choiche}>
                  <Radio
                    checked={role === 'CONTROL'}
                    onChange={this.handleChange}
                    name="role"
                    value="CONTROL"
                    tabIndex={-1}
                    checkedIcon={<i className={`fas fa-user-cog ${classes.iconCheckboxIcon}`} />}
                    icon={<i className={`fas fa-user-cog ${classes.iconCheckboxIcon}`} />}
                    classes={{
                      checked: classes.iconCheckboxChecked,
                      root: classes.iconCheckbox
                    }}
                  />
                  <h6>Admin</h6>
                </div>
                {role === 'OPERATOR' ? this.renderStationDropdown() : null}
              </GridItem>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                {role === 'AGENT' ? (
                  <CustomerSuggest
                    value={customers}
                    onChange={this.handleCustomers}
                    onBlur={null}
                    allowMulti
                    placeholder="Search and add one or more customers..."
                  />
                ) : null}
                {role === 'CUSTOMER' ? (
                  <CustomerSuggest
                    value={customers}
                    onChange={this.handleCustomers}
                    onBlur={null}
                    allowMulti={false}
                    placeholder="Search and add one customer only..."
                  />
                ) : null}
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(style)(Step2);
