import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import authReducer from './auth_reducer'
import bookingReducer from './booking_reducer'
import poReducer from './po_reducer'
import userReducer from './user_reducer'
import utilReducer from './util_reducer'
import portsReducer from './ports_reducer'

const rootReducer = combineReducers({
  form: formReducer,
  auth: authReducer,
  POs: poReducer,
  bookings: bookingReducer,
  ports: portsReducer,
  users: userReducer,
  util: utilReducer,
})

export default rootReducer
