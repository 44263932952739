import moment from 'moment';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import * as actions from '../../actions';
import '../../css/search.css';
import RenderDateField from '../booking/renderDateField';
import RenderField from '../booking/renderField';
import RenderStatusField from '../booking/renderStatusField';
import Spinner from '../util/spinner';
import validate from '../util/validate';

class ReportSearch extends Component {
  onSubmit(values) {
    if (values.ETDFrom) {
      values.ETDFrom = moment(values.ETDFrom).format('MM/DD/YYYY');
    }
    if (values.ETDTo) {
      values.ETDTo = moment(values.ETDTo).format('MM/DD/YYYY');
    }
    if (values.ETAFirstFrom) {
      values.ETAFirstFrom = moment(values.ETAFirstFrom).format('MM/DD/YYYY');
    }
    if (values.ETAFirstTo) {
      values.ETAFirstTo = moment(values.ETAFirstTo).format('MM/DD/YYYY');
    }
    if (values.ETAFinalFrom) {
      values.ETAFinalFrom = moment(values.ETAFinalFrom).format('MM/DD/YYYY');
    }
    if (values.ETAFinalTo) {
      values.ETAFinalTo = moment(values.ETAFinalTo).format('MM/DD/YYYY');
    }
    if (values.CargoReadyDateFrom) {
      values.CargoReadyDateFrom = moment(values.CargoReadyDateFrom).format('MM/DD/YYYY');
    }
    if (values.CargoReadyDateTo) {
      values.CargoReadyDateTo = moment(values.CargoReadyDateTo).format('MM/DD/YYYY');
    }

    if (this.props.user.accessLevel === 'AGENT') {
      // values.Agent = this.props.user.id;
      values.CNeeID = this.props.user.CustomerID.split(', ');
      // values.CNeeID = _.map(customerList).map(item => {
      //   return _.assign({
      //     CNeeID: parseInt(item)
      //   });
      // });
    }
    if (this.props.user.accessLevel === 'OPERATOR' && this.props.user.CustomerID) {
      // values.Agent = this.props.user.id;
      values.CNeeID = this.props.user.CustomerID.split(', ');
      // values.CNeeID = _.map(customerList).map(item => {
      //   return _.assign({
      //     CNeeID: parseInt(item)
      //   });
      // });
    }

    // check if customer:
    if (this.props.user.accessLevel === 'CUSTOMER') {
      values.CNeeID = this.props.user.CustomerID.split(', ');
    }
    this.props.fetchReport(values, () => {
      this.props.history.push('/p/report/show');
    });
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <strong>Oops!</strong> {this.props.errorMessage}
        </div>
      );
    }
  }

  render() {
    const { handleSubmit, user, isLoading } = this.props;
    if (isLoading) return <Spinner />;
    return (
      <div className="container-margin">
        <h2 className="text-center">Report</h2>
        {this.renderAlert()}
        <div className="row">
          <div className="col-md-12">
            <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
              <div className="row">
                <Field label="Booking ID:" placeholder="Booking ID" name="BookingID" component={RenderField} />
                {user.accessLevel !== 'CUSTOMER' ? (
                  <Field label="Consignee Name:" placeholder="Consignee Name" name="CNeeName" component={RenderField} />
                ) : (
                  <Field label="Status:" name="bkStatus" component={RenderStatusField} />
                )}
              </div>
              <div className="row">
                <Field label="PO No:" placeholder="PO #" name="PONo" component={RenderField} />
                <Field label="Vessel Name:" placeholder="Vessel Name" name="VesselName" component={RenderField} />
              </div>
              <div className="row">
                {user.accessLevel !== 'CUSTOMER' ? (
                  <Field label="Status:" name="bkStatus" component={RenderStatusField} />
                ) : (
                  ''
                )}
                <Field label="SKU No:" name="SKUNo" placeholder="SKU #" component={RenderField} />
              </div>
              <div className="row">
                <Field label="HBL:" placeholder="HBL" name="HBL" component={RenderField} />
                <Field label="MBL:" placeholder="MBL" name="MBL" component={RenderField} />
              </div>
              <div className="row">
                <Field label="Container #:" placeholder="Container #" name="ContainerNo" component={RenderField} />
                <Field label="Buyer Code:" placeholder="Buyer Code" name="buyCode" component={RenderField} />
              </div>
              <div className="row">
                <Field label="ETD From:" placeholder="MM/DD/YYYY" name="ETDFrom" component={RenderDateField} />
                <Field label="ETD To:" placeholder="MM/DD/YYYY" name="ETDTo" component={RenderDateField} />
              </div>
              <div className="row">
                <Field
                  label="ETA First From:"
                  placeholder="MM/DD/YYYY"
                  name="ETAFirstFrom"
                  component={RenderDateField}
                />
                <Field label="ETA First To:" placeholder="MM/DD/YYYY" name="ETAFirstTo" component={RenderDateField} />
              </div>
              <div className="row">
                <Field
                  label="ETA Final From:"
                  placeholder="MM/DD/YYYY"
                  name="ETAFinalFrom"
                  component={RenderDateField}
                />
                <Field label="ETA Final To:" placeholder="MM/DD/YYYY" name="ETAFinalTo" component={RenderDateField} />
              </div>
              <div className="row">
                <Field
                  label="Cargo Ready From:"
                  placeholder="MM/DD/YYYY"
                  name="CargoReadyDateFrom"
                  component={RenderDateField}
                />
                <Field
                  label="Cargo Ready To:"
                  placeholder="MM/DD/YYYY"
                  name="CargoReadyDateTo"
                  component={RenderDateField}
                />
              </div>
              <div className="text-center" style={{ marginTop: '20px' }}>
                <Button bsStyle="default" disabled={isLoading} type="submit">
                  {isLoading ? 'Loading...' : 'Search'}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ util, auth }) {
  //   console.log(state.util.errors);
  return {
    errorMessage: util.errors,
    isLoading: util.isLoading,
    user: auth.user
  };
}

export default reduxForm({
  validate,
  form: 'ReportSearch'
})(
  connect(
    mapStateToProps,
    actions
  )(ReportSearch)
);
