import React, { Component } from 'react';

export default class RenderStatusField extends Component {
  render() {
    const { label, input, disabled } = this.props;
    const statusOption = [
      { value: '', display: 'SHIPMENT STATUS' },
      { value: 'AV', display: 'ARRIVED' },
      { value: 'BP', display: 'BOOKING PENDING' },
      { value: 'CN', display: 'CANCEL' },
      { value: 'NB', display: 'NO BOOKING' },
      { value: 'OW', display: 'ON WATER' },
      { value: 'OR', display: 'ON ROUTE' }
    ];
    return (
      <div className="form-group form-group-sm col-sm-6">
        <div className="row">
          <label className="col-sm-4 col-form-label">{label}</label>
          <div className="col-sm-7">
            <select className="form-control" {...input} disabled={disabled}>
              {statusOption.map(choice => (
                <option value={choice.value} key={choice.value}>
                  {choice.display}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  }
}
