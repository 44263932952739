// @material-ui/icons
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import withStyles from '@material-ui/core/styles/withStyles';
import LockOpen from '@material-ui/icons/LockOpen';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import React from 'react';
// import SweetAlert from '../../components/Alerts/SweetAlert';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import * as useractions from '../../actions/users';
import regularFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import Card from '../../components/Card/Card.jsx';
import CardBody from '../../components/Card/CardBody.jsx';
import CardHeader from '../../components/Card/CardHeader.jsx';
import CardIcon from '../../components/Card/CardIcon.jsx';
import Button from '../../components/CustomButtons/Button.jsx';
import CustomInput from '../../components/CustomInput/CustomInput.jsx';
import SnackbarContent from '../../components/Snackbar/SnackbarContent.jsx';

class ResetPassword extends React.Component {
  state = {
    showPassword: false,
    show: false
  };

  shouldComponentUpdate = (nextProps, nextState) => nextState !== this.state;

  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  compare = (string1, string2) => {
    if (string1 === string2) {
      return true;
    }
    return false;
  };

  handleClickShowPassword = () => this.setState({ showPassword: !this.state.showPassword });

  onPasswordReset = values => {
    const {
      item: { id },
      resetPassword,
      onClose,
      destroy
    } = this.props;
    values.id = id;
    resetPassword(values, () => onClose());
    destroy();
  };

  render() {
    const required = value => (value || typeof value === 'number' ? undefined : 'Required');
    const complex = value =>
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/.test(value)
        ? undefined
        : 'Minimum 6 characters, at least 1 uppercase letter, 1 lowercase letter and 1 number';

    const { classes, handleSubmit, title, errors, submitting } = this.props;
    const { showPassword, alert } = this.state;
    // console.log(error);
    return (
      <Card>
        <CardHeader color="rose" icon>
          <CardIcon color="rose">
            <LockOpen />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>{title}</h4>
        </CardHeader>
        <CardBody>
          {alert}
          <form onSubmit={handleSubmit(this.onPasswordReset)}>
            <Field
              type="password"
              name="password"
              validate={[required, complex]}
              component={({ input, meta: { touched, error } }) => (
                <React.Fragment>
                  <CustomInput
                    success={touched && !error}
                    error={touched && !!error}
                    labelText="Password *"
                    name="password"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      ...input,
                      type: showPassword ? 'text' : 'password',
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowPassword}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  {touched && error ? <SnackbarContent message={error} color="rose" /> : ''}
                </React.Fragment>
              )}
            />

            <Field
              type="password"
              name="confirmPassword"
              validate={[required, complex]}
              component={({ input, meta: { touched, error } }) => (
                <React.Fragment>
                  <CustomInput
                    success={touched && !error}
                    error={touched && !!error}
                    labelText="Confirm Password *"
                    name="confirmPassword"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      ...input,
                      type: showPassword ? 'text' : 'password',
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowPassword}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  {touched && error ? <SnackbarContent message={error} color="rose" /> : ''}
                </React.Fragment>
              )}
            />
            {errors ? errors.map((error, i) => <SnackbarContent key={i} message={error.message} color="rose" />) : ''}
            <Button color="rose" type="submit" name="ResetPassword" disabled={submitting}>
              Submit
            </Button>
          </form>
        </CardBody>
      </Card>
    );
  }
}

const validate = values => {
  const errors = {};
  // Validate the inputs form 'values'
  if (values.password && values.confirmPassword && values.password !== values.confirmPassword) {
    errors.confirmPassword = 'Password is not the same';
  }

  return errors;
};

const mapStateToProps = ({ users }) => ({
  errors: users.userError
});

export default reduxForm({
  form: 'ResetPassword',
  enableReinitialize: true,
  destroyOnUnmount: true,
  validate
})(
  connect(
    mapStateToProps,
    useractions
  )(withStyles(regularFormsStyle)(ResetPassword))
);
