import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import * as actions from '../../actions';
import MoveBooking from '../util/moveBooking';
import Spinner from '../util/spinner';
import RenderCheckbox from './renderCheckbox';
import CommInvoice from './renderCommInvoice';
import RenderDateField from './renderDateField';
import HBL from './renderHBL';
import RenderProNo from './renderProNO';
import RenderTrucker from './renderTrucker';

class BookingAirDetail extends Component {
  constructor(props) {
    super(props);
    this.onBkDetailSubmit = this.onBkDetailSubmit.bind(this);
  }

  componentDidMount() {
    // console.log(this.props.match.params);
    const { BookingID } = this.props.match.params;
    this.props.fetchBookingDetail(BookingID);
  }

  onBkDetailSubmit(values) {
    const { BookingID } = this.props.match.params;

    this.props.updateBookingDetail(BookingID, values, () => {
      alert('Booking details updated');
      window.location.reload(true);
    });
  }

  // approveBkDetails(id, po, description) {
  //   // alert(`the Bk ID is: ${id}`);
  //   // UPDATE or insert into bkdetails ApStatus1, bkStatus
  //   const { BookingID } = this.props.match.params;
  //   let values = {};
  //   values.BkDetailID = id;
  //   values.ApStatus1 = `A${moment(Date.now()).format('M/D/YYYY')}`;
  //   values.ApStatus2 = ``;
  //   values.ApStatus3 = ``;
  //   values.bkStatus = 'AP';
  //   let msg = {};
  //   msg.Comments = `APPROVED -- PO: ${po} -- Description: ${description}`;
  //   this.props.approveStatus(id, BookingID, values, msg, () => {
  //     alert('Status Approved');
  //     window.location.reload(true);
  //   });
  // }
  // tempApproveBkDetails(id, po, description) {
  //   const { BookingID } = this.props.match.params;
  //   let values = {};
  //   values.BkDetailID = id;
  //   values.ApStatus1 = ``;
  //   values.ApStatus2 = `A${moment(Date.now()).format('M/D/YYYY')}`;
  //   values.ApStatus3 = ``;
  //   values.bkStatus = 'TEMP';
  //   let msg = {};
  //   msg.Comments = `TEMP APPROVED -- PO: ${po} -- Description: ${description}`;
  //   this.props.approveStatus(id, BookingID, values, msg, () => {
  //     alert('Status: temporarily approved');
  //     window.location.reload(true);
  //   });
  // }
  // holdBkDetails(id, po, description) {
  //   const { BookingID } = this.props.match.params;
  //   let values = {};
  //   values.BkDetailID = id;
  //   values.ApStatus1 = ``;
  //   values.ApStatus2 = ``;
  //   values.ApStatus3 = `A${moment(Date.now()).format('M/D/YYYY')}`;
  //   values.bkStatus = 'HOLD';
  //   let msg = {};
  //   msg.Comments = `HOLD -- PO: ${po} -- Description: ${description}`;
  //   this.props.approveStatus(id, BookingID, values, msg, () => {
  //     alert('Status: hold');
  //     window.location.reload(true);
  //   });
  // }
  // pendingBkDetails(id, po, description) {
  //   const { BookingID } = this.props.match.params;
  //   let values = {};
  //   values.BkDetailID = id;
  //   values.ApStatus1 = ``;
  //   values.ApStatus2 = ``;
  //   values.ApStatus3 = ``;
  //   values.bkStatus = `BR`;
  //   let msg = {};
  //   msg.Comments = `DEFAULT TO PENDING STATUS`;
  //   this.props.approveStatus(id, BookingID, values, msg, () => {
  //     alert('Status: Default to Pending');
  //     window.location.reload(true);
  //   });
  // }

  renderField(field) {
    const { label, type, input, ...rest } = field;
    return (
      <div className="form-group form-group-sm col-sm-6">
        <div className="row">
          <label className="col-sm-4 col-form-label">{label}</label>
          <div className="col-sm-7">
            <input className="form-control" type={type} {...input} {...rest} />
          </div>
        </div>
      </div>
    );
  }

  renderCargo(field) {
    const { label, type, input, ...rest } = field;
    return (
      <div className="form-group form-group-sm col-sm-12">
        <div className="row">
          <div className="col-sm-12">
            <input className="form-control" type={type} {...input} {...rest} />
          </div>
        </div>
      </div>
    );
  }

  renderUpdatedMsg() {
    if (this.props.updatedBkDetail) {
      return (
        <div className="alert alert-success text-center">
          <strong>Successfully updated!</strong>
        </div>
      );
    }
  }

  renderMove(field) {
    const { label, type, input, ...rest } = field;

    return (
      <div className="form-group form-group-sm col-sm-6">
        <div className="row">
          <label className="col-sm-4 col-form-label">{label}</label>
          <div className="col-sm-7">
            <input className="form-control" type={type} {...input} {...rest} />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { booking, initialValues, handleSubmit } = this.props;
    const approvalPending = {
      color: 'red',
      paddingBottom: '10px'
    };
    const approved = {
      color: 'green',
      paddingBottom: '10px'
    };
    const tempApproved = {
      color: 'orange',
      paddingBottom: '10px'
    };
    if (!booking || !initialValues) {
      return <Spinner />;
    }
    if (!booking.BkDetails) {
      return null;
    }
    // console.info(booking);
    return booking.BkDetails.map((item, index) => (
      <fieldset className="for-panel" key={index}>
        <legend>Booking Detail #{index + 1}</legend>
        {(() => {
          if (item.bkStatus === 'AP' || item.ApStatus1)
            return (
              <h4 style={approved}>
                Approved
                <i className="fa fa-check-square-o" style={{ marginLeft: '5px' }} aria-hidden="true" />
              </h4>
            );
          if (item.bkStatus === 'TEMP') return <h4 style={tempApproved}>Temp Approved</h4>;
          if (item.bkStatus === 'HOLD') return <h4 style={approvalPending}>HOLD</h4>;
          if (item.bkStatus === 'BR' && !item.ApStatus1)
            return (
              <h4 style={approvalPending}>
                Approval Pending
                <i className="fa fa-times-circle-o" style={{ marginLeft: '5px' }} aria-hidden="true" />
              </h4>
            );
          return null;
        })()}
        <div className="row">
          <MoveBooking BkDetailID={item.BkDetailID} details={item} />
        </div>
        <hr />
        <form onSubmit={handleSubmit(this.onBkDetailSubmit)}>
          <div className="row">
            <Field label="Customer PO:" type="text" name={`BkDetails[${index}].csnPO`} component={this.renderField} />
            <Field
              label="Shipper Name:"
              type="text"
              name={`BkDetails[${index}].ShipperName`}
              component={this.renderField}
            />
          </div>
          <div className="row">
            <Field
              label="HAWB #:"
              CNeeID={booking.CNeeID}
              hbl={item.HBL}
              type="text"
              name={`BkDetails.[${index}].HBL`}
              component={HBL}
            />
            <Field
              label="Description / Title:"
              type="text"
              name={`BkDetails[${index}].CargoDescription`}
              component={this.renderField}
            />
          </div>
          <div className="row">
            <Field
              label="Cargo Ready Date:"
              type="text"
              name={`BkDetails[${index}].CargoReadyDate`}
              component={RenderDateField}
            />
            <Field
              label="Cargo Rap Date:"
              type="text"
              name={`BkDetails[${index}].CargoRapDate`}
              component={RenderDateField}
            />
          </div>
          <div className="row">
            <Field label="PO #:" type="text" name={`BkDetails[${index}].PONo`} component={this.renderField} />
            <Field
              label="PO Due Date #:"
              type="text"
              name={`BkDetails[${index}].CustomerInHandsDate`}
              component={RenderDateField}
            />
          </div>
          <div className="row">
            <Field label="SKU / ITEM #:" type="text" name={`BkDetails[${index}].SKUNo`} component={this.renderField} />
            <Field
              label="Delivery Addr:"
              type="text"
              name={`BkDetails[${index}].DelrAddr`}
              component={this.renderField}
            />
          </div>

          <div className="row">
            <Field
              label="Estimate Del. Date:"
              type="text"
              name={`BkDetails[${index}].EstDelivery`}
              component={RenderDateField}
            />
            <Field
              label="Actual Del. Date:"
              type="text"
              name={`BkDetails[${index}].ActDelivery`}
              component={RenderDateField}
            />
          </div>

          <div className="row">
            <Field
              label="Final Due Date:"
              type="text"
              name={`BkDetails.[${index}].FinalDueDate`}
              component={RenderDateField}
            />
            <Field
              label="Received by:"
              type="text"
              name={`BkDetails[${index}].ReceivedBy`}
              component={this.renderField}
            />
          </div>
          <div className="row">
            <Field
              label="ProNo #:"
              type="text"
              vendorID={item.VendorURLID}
              trucker={this.props.trucker}
              name={`BkDetails[${index}].ProNo`}
              component={RenderProNo}
            />
            <Field
              label="Trucker Name:"
              type="text"
              name={`BkDetails[${index}].VendorURLID`}
              component={RenderTrucker}
            />
          </div>
          <div className="row">
            <Field label="HTS #:" type="text" name={`BkDetails[${index}].HTSNo`} component={this.renderField} />

            {/* <div className="col-sm-6">
                <ButtonToolbar className="pull-right button-lineup">
                  <Button
                    bsSize="small"
                    bsStyle="default"
                    onClick={() => {
                      if (window.confirm(`Mark as 'Approved'?`)) {
                        this.approveBkDetails(item.BkDetailID, item.PONo, item.CargoDescription);
                      }
                    }}
                  >
                    Approve
                  </Button>
                  <Button
                    bsSize="small"
                    bsStyle="default"
                    onClick={() => {
                      if (window.confirm(`Mark as 'temporarily approve'?`)) {
                        this.tempApproveBkDetails(item.BkDetailID, item.PONo, item.CargoDescription);
                      }
                    }}
                  >
                    Temp Approve
                  </Button>
                  <Button
                    bsSize="small"
                    bsStyle="default"
                    onClick={() => {
                      if (window.confirm(`Mark as 'hold'?`)) {
                        this.holdBkDetails(item.BkDetailID, item.PONo, item.CargoDescription);
                      }
                    }}
                  >
                    Hold
                  </Button>
                  <Button
                    bsSize="small"
                    bsStyle="default"
                    onClick={() => {
                      if (window.confirm(`Mark as 'pending'?`)) {
                        this.pendingBkDetails(item.BkDetailID, item.PONo, item.CargoDescription);
                      }
                    }}
                  >
                    Pending
                  </Button>
                </ButtonToolbar>
              </div> */}
          </div>
          <div className="row">
            <Field
              label="Comm. Invoice No #:"
              CNeeID={booking.CNeeID}
              invoiceNo={item.CommInvoiceNo}
              type="text"
              name={`BkDetails[${index}].CommInvoiceNo`}
              component={CommInvoice}
            />
          </div>
          <div className="row">
            <label className="col-sm-2" style={{ color: 'red' }}>
              Missing Documents:
            </label>
            <Field
              detailID={item.BkDetailID}
              BookingID={this.props.match.params.BookingID}
              label="HBL:"
              type="checkbox"
              docName="MissingDoc1"
              name={`BkDetails[${index}].MissingDoc1`}
              component={RenderCheckbox}
            />
            <Field
              detailID={item.BkDetailID}
              BookingID={this.props.match.params.BookingID}
              label="Commerical Invoice And/Or Packing List:"
              type="checkbox"
              docName="MissingDoc2"
              name={`BkDetails[${index}].MissingDoc2`}
              component={RenderCheckbox}
            />
            <Field
              detailID={item.BkDetailID}
              BookingID={this.props.match.params.BookingID}
              label="ORIGIN CHARGES NOT RECEIVED:"
              type="checkbox"
              docName="MissingDoc3"
              name={`BkDetails[${index}].MissingDoc3`}
              component={RenderCheckbox}
            />
          </div>

          <Table responsive bordered condensed>
            <thead>
              <tr>
                <th className="text-center col-sm-2 col-sm-offset-1">VOLUME :</th>
                <th className="text-center col-sm-2">WEIGHT :</th>
                <th className="text-center col-sm-2">QTY. [UNITS] :</th>
                <th className="text-center col-sm-2">QTY. [CARTON] :</th>
                <th className="text-center col-sm-2">QTY. [PALLET] :</th>
              </tr>
            </thead>
            <tbody>
              {/* <tr className="text-center">
                  <td>{item.CargoVol}</td>
                  <td>{item.cargoWt}</td>
                  <td>{item.CopyQty}</td>
                  <td>{item.CartonQty}</td>
                  <td>{item.PalletQty}</td>
                </tr> */}
              <tr className="">
                <td>
                  <Field type="text" name={`BkDetails[${index}].CargoVol`} component={this.renderCargo} />
                </td>
                <td>
                  <Field type="text" name={`BkDetails[${index}].GrossWeight`} component={this.renderCargo} />
                </td>
                <td>
                  <Field type="text" name={`BkDetails[${index}].ChargeableWeight`} component={this.renderCargo} />
                </td>
                <td>
                  <Field type="text" name={`BkDetails[${index}].CartonQty`} component={this.renderCargo} />
                </td>
                <td>
                  <Field type="text" name={`BkDetails[${index}].PalletQty`} component={this.renderCargo} />
                </td>
              </tr>
              {/* <tr>
                  <td colSpan="5">
                    Actual Commerical Value : {item.CopyQty} * {item.unitCost} = ${Number(
                      item.CopyQty * item.unitCost
                    ).toFixed(2)}
                  </td>
                </tr> */}
            </tbody>
          </Table>
          {this.renderUpdatedMsg()}
          <div className="row text-center">
            <button type="submit" name={`submit${index}`} className="btn btn-default">
              Update
            </button>
          </div>
        </form>
      </fieldset>
    ));
  }
}

function mapStateToProps(state) {
  return {
    booking: state.bookings.results,
    updatedBkDetail: state.bookings.updatedBkDetail,
    initialValues: state.bookings.results,
    user: state.auth.user
  };
}

BookingAirDetail = reduxForm({
  form: 'BookingAirDetail',
  enableReinitialize: true
})(BookingAirDetail);

BookingAirDetail = withRouter(connect(mapStateToProps, actions)(BookingAirDetail));
export default BookingAirDetail;
