import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';

class RenderCarrier extends Component {
  componentDidMount() {
    this.props.fetchCarrierList();
  }
  render() {
    const {
      carrier,
      label,
      input,
      meta: { touched, error }
    } = this.props;
    if (!this.props.carrier) {
      return <div>loading...</div>;
    }

    return (
      <div className="form-group form-group-sm col-sm-6">
        <div className="row">
          <label className="col-sm-4 col-form-label">{label}</label>
          <div className="col-sm-7">
            <select className="form-control" {...input}>
              {carrier.map(choice => (
                <option value={choice.CarrierName} key={choice.CarrierName}>
                  {choice.CarrierName}
                </option>
              ))}
            </select>
            <div className="text-danger">{touched ? error : ''}</div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ util }) {
  return {
    carrier: util.carrier
  };
}

export default connect(
  mapStateToProps,
  actions
)(RenderCarrier);
