import React from 'react';

const RenderUrgent = props => {
  const {
    label,
    disabled,
    input,
    meta: { touched, error }
  } = props;
  return (
    <div className="form-group form-group-sm col-sm-6">
      <div className="row">
        <label className="col-sm-2 col-form-label">
          <span style={{ color: 'red' }}>{label}</span>
        </label>
        <div className="col-sm-3">
          <input type="checkbox" checked={input.value} disabled={disabled} {...input} />
          <div className="text-danger">{touched ? error : ''}</div>
        </div>
      </div>
    </div>
  );
};
export default RenderUrgent;
