import React, { Component } from 'react';

export default class RenderBuyTerms extends Component {
  render() {
    const { label, input } = this.props;
    const statusOption = [
      { value: '', display: '' },
      { value: 'CIF Destination', display: 'CIF Destination' },
      { value: 'CIF Original Port', display: 'CIF Original Port' },
      { value: 'EX Works', display: 'EX Works' },
      { value: 'FOB Port', display: 'FOB Port' },
      { value: 'Other', display: 'Other' }
    ];
    return (
      <div className="form-group form-group-sm col-sm-6">
        <div className="row">
          <label className="col-sm-4 col-form-label">{label}</label>
          <div className="col-sm-7">
            <select className="form-control" {...input}>
              {statusOption.map(choice => (
                <option value={choice.value} key={choice.value}>
                  {choice.display}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  }
}
