import axios from 'axios'
import jwtDecode from 'jwt-decode'
import querystring from 'query-string'
import {
  ADD_ERROR,
  ALERT_MISSING_DOCS,
  APPROVE_ALL,
  APPROVE_STATUS,
  AUTH_ERROR,
  BOOKING_DELETED,
  BOOKING_FOR_NOTIFICATION,
  CLONE_BOOKING_DETAILS,
  CLOSE_CREATE_MODAL,
  CLOSE_MODAL,
  COUNT_BOOKING_APPROVED,
  COUNT_BOOKING_DELIVERED,
  COUNT_BOOKING_SHIPPED,
  COUNT_SHIPMENT_TYPE,
  CREATE_BOOKING_DETAIL,
  CREATE_BOOKING_HEADER,
  CREATE_COMMENTS,
  CREATE_PO_COMMENTS,
  DELETE_COMMENTS,
  DELETE_DETAILS,
  DELETE_PO_COMMENT,
  DISMISS_ERROR,
  FETCH_ADDRESS_BOOK,
  FETCH_CONTAINERS,
  FETCH_CUSTOMER_DASHBOARD,
  FETCH_PORTS,
  FETCH_REPORT,
  FETCH_USER,
  FILE_ERROR,
  // GET_BOOKING_DETAIL,
  GET_ALL_OPERATORS,
  GET_BOOKING,
  GET_BOOKING_COMMENT,
  GET_BOOKING_HEADER,
  GET_CARRIER_LIST,
  GET_CUSTOMER_LIST,
  GET_MSG_FROM_RADIO,
  GET_PO_COMMENT,
  GET_PO_HEADER,
  GET_TRUCKER_LIST,
  IF_COMM_INVOICE_EXISTS,
  IF_HBL_EXISTS,
  IF_MBL_EXISTS,
  IF_PACKINGLIST_EXISTS,
  MOVE_BOOKING,
  MOVE_PO_BOOKING,
  OPEN_CREATE_MODAL,
  OPEN_MODAL,
  OPEN_UPLOAD_TEMPLATE_MODAL,
  SEARCH_BOOKINGS,
  SEARCH_PO,
  SENT_MAIL,
  SET_CURRENT_USER,
  SHOW_ALERT,
  UNAUTH_USER,
  UPDATE_BOOKING_DETAIL,
  UPDATE_BOOKING_HEADER,
  UPDATE_PO_HEADER,
} from './types'
import { setIsLoading } from './utils'
import { isObject } from 'lodash'

// export const ROOT_URL = 'https://pom-api-staging.herokuapp.com';
let API_URL = ''
if (process.env.REACT_APP_API_ENV === 'production') {
  API_URL = process.env.REACT_APP_API_URL
} else if (process.env.REACT_APP_API_ENV === 'staging') {
  API_URL = process.env.REACT_APP_STAGING_API_URL
} else {
  API_URL = 'http://localhost:8000'
}
export const ROOT_URL = API_URL

export function setAuthorizationToken() {
  const token = localStorage.getItem('jwtToken')

  if (token) {
    axios.defaults.headers.common.Authorization = `${token}`
  } else {
    delete axios.defaults.headers.common.Authorization
  }
}

export function signinUser({ username, password }, callback) {
  return function(dispatch) {
    // Submit username/password to server
    dispatch(setIsLoading(true))
    axios
      .post(`${ROOT_URL}/signin`, { username, password })
      .then(res => {
        dispatch(setIsLoading(false))
        const { token } = res.data
        localStorage.setItem('jwtToken', `Bearer ${token}`)
        dispatch(setCurrentUser(jwtDecode(token)))
        callback()
      })
      .catch(() => {
        dispatch(setIsLoading(false))
        dispatch(authError('Bad login info'))
      })
  }
}

export function setCurrentUser(user) {
  return {
    type: SET_CURRENT_USER,
    user,
  }
}

export const signoutUser = () => {
  localStorage.removeItem('jwtToken')
  return {
    type: UNAUTH_USER,
  }
}

export const fetchUser = () => async dispatch => {
  const response = await axios.get(`${ROOT_URL}/api/current_user`)
  dispatch({ type: FETCH_USER, payload: response.data })
}
// export const toggleUpdated = () => async dispatch => {
//   dispatch({ type: FETCH_USER, });
// };

export const fetchAddress = CNeeID => async dispatch => {
  const response = await axios.get(`${ROOT_URL}/api/address?CNeeID=${CNeeID}`)
  dispatch({ type: FETCH_ADDRESS_BOOK, payload: response.data })
}

export const getShipmentType = CNeeID => async dispatch => {
  const response = await axios.get(
    `${ROOT_URL}/api/dashboard/getShipmentType?CNeeID=${CNeeID}`,
  )
  dispatch({ type: COUNT_SHIPMENT_TYPE, payload: response.data })
}
export const getNumOfShipped = CNeeID => async dispatch => {
  const response = await axios.get(
    `${ROOT_URL}/api/dashboard/getNumOfShipped?CNeeID=${CNeeID}`,
  )
  dispatch({ type: COUNT_BOOKING_SHIPPED, payload: response.data })
}
export const getNumOfDelivered = CNeeID => async dispatch => {
  const response = await axios.get(
    `${ROOT_URL}/api/dashboard/getNumOfDelivered?CNeeID=${CNeeID}`,
  )
  dispatch({ type: COUNT_BOOKING_DELIVERED, payload: response.data })
}
export const getNumOfApproved = CNeeID => async dispatch => {
  const response = await axios.get(
    `${ROOT_URL}/api/dashboard/getNumOfApproved?CNeeID=${CNeeID}`,
  )
  dispatch({ type: COUNT_BOOKING_APPROVED, payload: response.data })
}
export const fetchCustomerDashboard = CNeeID => async dispatch => {
  const response = await axios.get(
    `${ROOT_URL}/api/dashboard/cpanel?CNeeID=${CNeeID}`,
  )
  dispatch({ type: FETCH_CUSTOMER_DASHBOARD, payload: response.data })
}
export const approveAll = id => async dispatch => {
  const values = {}
  values.bkStatus = 'AP'
  const response = await axios.put(
    `${ROOT_URL}/api/booking/header/approve/${id}`,
    values,
  )
  dispatch({ type: APPROVE_ALL, payload: response.data })
}

export const fetchContainers = id => async dispatch => {
  const response = await axios.get(
    `${ROOT_URL}/api/booking/detail/containers/${id}`,
  )
  dispatch({ type: FETCH_CONTAINERS, payload: response.data })
}

const messageList = []
export function getMsgFromRadio(msg) {
  messageList.push(msg)

  return {
    type: GET_MSG_FROM_RADIO,
    messageList,
  }
}

export function searchBooking(values, callback) {
  return function(dispatch) {
    axios
      .post(`${ROOT_URL}/api/booking`, values)
      .then(response => {
        dispatch({ type: SEARCH_BOOKINGS, payload: response.data })
        callback()
      })
      .catch(() => {
        // If request is bad...
        // - Show an error to user
        dispatch(addError('Booking Not found'))
      })
  }
}
export function searchBookingByQuery(values) {
  return function(dispatch) {
    axios
      .get(`${ROOT_URL}/api/booking`, {
        params: values,
      })
      .then(response => {
        dispatch({ type: SEARCH_BOOKINGS, payload: response.data })
      })
      .catch(() => {
        dispatch(addError('Booking Not found'))
      })
  }
}
export const searchPO = (values, callback) => dispatch => {
  dispatch(setIsLoading(true))
  axios
    .get(`${ROOT_URL}/api/po`, {
      params: values,
    })
    .then(response => {
      dispatch(setIsLoading(false))
      dispatch({ type: SEARCH_PO, payload: response.data })
      callback()
    })
    .catch(() => {
      dispatch(setIsLoading(false))
      dispatch(addError('PO Not found'))
    })
}

export function openModal() {
  return function(dispatch) {
    dispatch({ type: OPEN_MODAL })
  }
}

export function openUploadTemplateModal() {
  return function(dispatch) {
    dispatch({ type: OPEN_UPLOAD_TEMPLATE_MODAL })
  }
}

export function closeModal() {
  return function(dispatch) {
    dispatch({ type: CLOSE_MODAL })
  }
}

export function openCreateModal() {
  return function(dispatch) {
    dispatch({ type: OPEN_CREATE_MODAL })
  }
}
export function closeCreateModal() {
  return function(dispatch) {
    dispatch({ type: CLOSE_CREATE_MODAL })
  }
}
export function sendMail(values, callback) {
  return function(dispatch) {
    axios
      .post(`${ROOT_URL}/api/sendmail`, values)
      .then(response => {
        dispatch({ type: SENT_MAIL, payload: response.data })
        callback()
      })
      .catch()
  }
}

export function authError(error) {
  return {
    type: AUTH_ERROR,
    payload: error,
  }
}
export function addError(error) {
  return {
    type: ADD_ERROR,
    payload: error,
  }
}
export function dismissError() {
  return {
    type: DISMISS_ERROR,
  }
}

export function fetchReport(values, callback) {
  return function(dispatch) {
    dispatch(setIsLoading(true))
    axios
      .post(`${ROOT_URL}/api/booking`, values)
      .then(response => {
        dispatch(setIsLoading(false))
        dispatch({ type: FETCH_REPORT, payload: response.data })
        callback()
      })
      .catch(() => {
        dispatch(setIsLoading(false))
        dispatch(addError('No report found!'))
      })
  }
}

export function fetchPO(PONo, LineNum) {
  return function(dispatch) {
    axios
      .get(`${ROOT_URL}/api/po/${PONo}/${LineNum}`)
      .then(response => {
        dispatch({
          type: GET_PO_HEADER,
          payload: response.data,
        })
      })
      .catch(() => {
        dispatch(addError('PO Not found'))
      })
  }
}
export function fetchPorts() {
  return function(dispatch) {
    axios
      .get(`${ROOT_URL}/api/ports/all`)
      .then(response => {
        if (response.data) {
          dispatch({
            type: FETCH_PORTS,
            payload: response.data,
          })
        }
      })
      .catch(() => {
        dispatch(addError('Ports Not Found'))
      })
  }
}
export function fetchBookingHeader(id) {
  return function(dispatch) {
    axios
      .get(`${ROOT_URL}/api/booking/header/${id}`)
      .then(response => {
        if (response.data.message === 'Booking Not Found') {
          dispatch(addError('Booking Not Found'))
        }

        if (response.data) {
          dispatch({
            type: GET_BOOKING_HEADER,
            payload: response.data,
          })
        }
      })
      .catch(() => {
        dispatch(addError('Booking Not Found'))
      })
  }
}

export function fetchBookingComment(id) {
  return function(dispatch) {
    axios
      .get(`${ROOT_URL}/api/booking/comment/${id}`)
      .then(response => {
        dispatch({
          type: GET_BOOKING_COMMENT,
          payload: response,
        })
      })
      .catch(() => {
        dispatch(addError('Comment Not found'))
      })
  }
}
export function fetchBookingCommentExcludeInternal(id) {
  return function(dispatch) {
    axios
      .get(`${ROOT_URL}/api/booking/comment/${id}?isInternal=false`)
      .then(response => {
        dispatch({
          type: GET_BOOKING_COMMENT,
          payload: response,
        })
      })
      .catch(() => {
        dispatch(addError('Comment Not found'))
      })
  }
}
export function fetchPOComment(PONo, LineNum) {
  return function(dispatch) {
    axios
      .get(`${ROOT_URL}/api/po/comment/${PONo}/${LineNum}`)
      .then(response => {
        dispatch({
          type: GET_PO_COMMENT,
          payload: response.data,
        })
      })
      .catch(() => {
        dispatch(addError('Comment Not found'))
      })
  }
}
export function fetchBookingDetail(id) {
  return function(dispatch) {
    axios
      .get(`${ROOT_URL}/api/booking/${id}`)
      .then(response => {
        // console.log(response.data);
        dispatch({
          type: GET_BOOKING,
          payload: response,
        })
      })
      .catch(() => {
        // If request is bad...
        // - Show an error to user
        dispatch(addError('Booking Detail Not found'))
      })
  }
}

export function fetchBookingForNotification(id) {
  return function(dispatch) {
    axios
      .get(`${ROOT_URL}/api/booking/sendmail/${id}`)
      .then(response => {
        // console.log(response.data);
        dispatch({
          type: BOOKING_FOR_NOTIFICATION,
          payload: response,
        })
      })
      .catch(() => {
        // If request is bad...
        // - Show an error to user
        dispatch(
          addError(
            'No booking details ready to be sent, please make sure a booking detail and comment are entered.',
          ),
        )
      })
  }
}
export function fetchCustomerList() {
  const employeeNo = jwtDecode(localStorage.getItem('jwtToken')).id
  return function(dispatch) {
    axios
      .get(`${ROOT_URL}/api/employee/${employeeNo}`)
      .then(response => {
        let customer = response.data.customerID.split(',')
        customer = customer.map(i => i.trim())
        axios
          .post(`${ROOT_URL}/api/customer/`, customer)
          .then(response => {
            dispatch({
              type: GET_CUSTOMER_LIST,
              payload: response.data,
            })
          })
          .catch(() => {
            dispatch(addError('No customer is associated with this account'))
          })
      })
      .catch(() => {
        dispatch(addError('No customer is associated with this account'))
      })
  }
}

export function fetchTruckerList() {
  return function(dispatch) {
    axios
      .get(`${ROOT_URL}/api/trucker/all`)
      .then(response => {
        dispatch({
          type: GET_TRUCKER_LIST,
          payload: response.data,
        })
      })
      .catch(() => {
        dispatch(addError('No trucker found'))
      })
  }
}
export function getAllOperators() {
  return function(dispatch) {
    axios
      .get(`${ROOT_URL}/api/users/operators`)
      .then(response => {
        dispatch({
          type: GET_ALL_OPERATORS,
          payload: response.data,
        })
      })
      .catch(() => {
        addError('No operators found')
      })
  }
}
export function fetchCarrierList() {
  return function(dispatch) {
    axios
      .get(`${ROOT_URL}/api/carrier/all`)
      .then(response => {
        dispatch({
          type: GET_CARRIER_LIST,
          payload: response.data,
        })
      })
      .catch(() => {
        addError('No carrier found')
      })
  }
}
export function updateBookingHeader(id, values) {
  if (isObject(values.DstPort)) {
    values.DstPort = values.DstPort.value
  }
  if (isObject(values.FnlPort)) {
    values.FnlPort = values.FnlPort.value
  }
  if (isObject(values.OrgPort)) {
    values.OrgPort = values.OrgPort.value
  }
  return function(dispatch) {
    axios
      .put(`${ROOT_URL}/api/booking/header/${id}`, values)
      .then(response => {
        dispatch({ type: UPDATE_BOOKING_HEADER, payload: response.data })
      })
      .catch(() => {
        dispatch(addError('Not updated'))
      })
  }
}
export function updatePOHeader(values) {
  const { id } = values
  return function(dispatch) {
    axios
      .put(`${ROOT_URL}/api/po/${id}`, values)
      .then(response => {
        dispatch({ type: UPDATE_PO_HEADER, payload: response.data })
      })
      .catch(() => {
        dispatch(addError('Not updated'))
      })
  }
}
export function moveBooking(id, values, callback) {
  return function(dispatch) {
    axios
      .put(`${ROOT_URL}/api/booking/detail/move/${id}`, values)
      .then(response => {
        dispatch({ type: MOVE_BOOKING })
        callback()
      })
      .catch(() => {
        dispatch(addError('MOVE BOOKING FAILED!'))
      })
  }
}
export function cloneDetail(values) {
  return function(dispatch) {
    axios
      .post(`${ROOT_URL}/api/booking/detail/clone`, values)
      .then(response => {
        dispatch({ type: CLONE_BOOKING_DETAILS, payload: response.data })
        dispatch({ type: SHOW_ALERT })
      })
      .catch(() => {
        dispatch(addError('CLONE BOOKING DETAILS FAILED!'))
      })
  }
}
export function movePOtoBooking(values) {
  return function(dispatch) {
    axios
      .put(`${ROOT_URL}/api/booking/po/move`, values)
      .then(response => {
        dispatch({ type: MOVE_PO_BOOKING })
      })
      .catch(() => {
        dispatch(addError('MOVE BOOKING FAILED!'))
      })
  }
}
export function updateBookingDetailWithMsg(bkID, msg, values) {
  msg.Owner = jwtDecode(localStorage.getItem('jwtToken')).username
  msg.CType = jwtDecode(localStorage.getItem('jwtToken')).accessLevel
  return function(dispatch) {
    axios
      .put(`${ROOT_URL}/api/booking/detail/${bkID}`, values)
      .then(response => {
        dispatch({ type: UPDATE_BOOKING_DETAIL, payload: response.data })
      })
      .catch(() => {
        dispatch(addError('Not updated'))
      })
    axios
      .post(`${ROOT_URL}/api/booking/comment/${bkID}`, msg)
      .then(response => {
        dispatch({ type: CREATE_COMMENTS, payload: response.data })
      })
      .catch(() => {
        dispatch(addError('Insert comment failed!'))
      })
  }
}
export function updateBookingDetail(bkID, values) {
  values.BkDetails.forEach(obj => {
    for (const prop in obj) {
      if (typeof obj[prop] === 'string') {
        obj[prop] = obj[prop].trim()
      }
    }
  })
  return function(dispatch) {
    axios
      .put(`${ROOT_URL}/api/booking/detail/${bkID}`, values)
      .then(response => {
        dispatch({ type: UPDATE_BOOKING_DETAIL, payload: response.data })
      })
      .catch(() => {
        dispatch(addError('Not updated'))
      })
  }
}

export function deleteBooking(id, callback) {
  const values = { Status: 'D' }
  return function(dispatch) {
    axios
      .patch(`${ROOT_URL}/api/booking/delete/${id}/`, values)
      .then(response => {
        dispatch({ type: BOOKING_DELETED })
        callback()
      })
      .catch(() => {
        dispatch(addError('Booking deletion failed!'))
      })
  }
}

export function createBookingHeader(values, callback) {
  return function(dispatch) {
    const initialBkDetail = {}
    if (isObject(values.DstPort)) {
      values.DstPort = values.DstPort.value
    }
    if (isObject(values.FnlPort)) {
      values.FnlPort = values.FnlPort.value
    }
    if (isObject(values.OrgPort)) {
      values.OrgPort = values.OrgPort.value
    }
    axios
      .post(`${ROOT_URL}/api/booking/header/`, values)
      .then(response => {
        // console.log(response.data);
        dispatch({ type: CREATE_BOOKING_HEADER, payload: response.data })
        initialBkDetail.BookingID = response.data.BookingID
      })
      .then(() => {
        axios
          .post(
            `${ROOT_URL}/api/booking/detail/createInitial/`,
            querystring.stringify(initialBkDetail),
          )
          .then(response => {
            // console.log(initialBkDetail);
            dispatch({ type: CREATE_BOOKING_DETAIL, payload: response.data })
            callback()
          })
      })
      .catch(() => {
        dispatch(addError('Create failed'))
      })
  }
}
export function createBookingDetail(values, callback) {
  return function(dispatch) {
    axios
      .post(`${ROOT_URL}/api/booking/detail/`, values)
      .then(response => {
        // console.log(response.data);
        dispatch({ type: CREATE_BOOKING_DETAIL, payload: response.data })
        callback()
      })
      .catch(() => {
        dispatch(addError('Create failed'))
      })
  }
}
export function createComment(id, values, callback) {
  values.Owner = jwtDecode(localStorage.getItem('jwtToken')).username
  values.CType = jwtDecode(localStorage.getItem('jwtToken')).accessLevel
  return function(dispatch) {
    axios
      .post(`${ROOT_URL}/api/booking/comment/${id}`, values)
      .then(response => {
        dispatch({ type: CREATE_COMMENTS, payload: response.data })
        callback()
      })
      .catch(() => {
        dispatch(addError('Not updated'))
      })
  }
}
export function createPOComment(PONo, LineNum, values, callback) {
  values.Owner = jwtDecode(localStorage.getItem('jwtToken')).username
  values.CType = jwtDecode(localStorage.getItem('jwtToken')).accessLevel
  return function(dispatch) {
    axios
      .post(`${ROOT_URL}/api/po/comment/${PONo}/${LineNum}`, values)
      .then(response => {
        dispatch({ type: CREATE_PO_COMMENTS, payload: response.data })
        callback()
      })
      .catch(() => {
        dispatch(addError('Not updated'))
      })
  }
}
export const remove = id => ({
  type: DELETE_COMMENTS,
  id,
})
export const removePOComment = id => ({
  type: DELETE_PO_COMMENT,
  id,
})
export function deactivateComment(CommentID, callback) {
  return function(dispatch) {
    axios
      .delete(`${ROOT_URL}/api/comments/${CommentID}`)
      .then(() => dispatch(remove(CommentID)))
      .catch(() => {
        dispatch(addError('ERROR WHEN DELETING '))
      })
  }
}
export function deletePOComment(CommentID, callback) {
  return function(dispatch) {
    axios
      .delete(`${ROOT_URL}/api/po/comment/${CommentID}`)
      .then(() => dispatch(removePOComment(CommentID)))
      .catch(() => {
        dispatch(addError('ERROR WHEN DELETING '))
      })
  }
}
export function destoryDetail(DetailID, callback) {
  const values = { dStatus: 'D' }
  return function(dispatch) {
    axios
      .put(`${ROOT_URL}/api/booking/bkdetail/${DetailID}`, values)
      .then(response => {
        dispatch({ type: DELETE_DETAILS })
        callback()
      })
      .catch(() => {
        dispatch(addError('ERROR WHEN DELETING '))
      })
  }
}

export function approveStatus(id, bkID, values, msg, callback) {
  msg.Owner = jwtDecode(localStorage.getItem('jwtToken')).username
  msg.CType = jwtDecode(localStorage.getItem('jwtToken')).accessLevel

  return function(dispatch) {
    axios
      .post(`${ROOT_URL}/api/booking/bkdetail/approve/${id}`, values)
      .then(response => {
        dispatch({ type: APPROVE_STATUS })
      })
      .catch(() => {
        dispatch(addError('Approved failed'))
      })
    axios
      .post(`${ROOT_URL}/api/booking/comment/${bkID}`, msg)
      .then(response => {
        dispatch({ type: CREATE_COMMENTS, payload: response.data })
        callback()
      })
      .catch(() => {
        dispatch(addError('Insert comment failed!'))
      })
  }
}

export function docAlert(id, bkID, values, msg, callback) {
  msg.Owner = jwtDecode(localStorage.getItem('jwtToken')).username
  msg.CType = jwtDecode(localStorage.getItem('jwtToken')).accessLevel
  // console.log(values);
  return function(dispatch) {
    axios
      .post(
        `${ROOT_URL}/api/booking/bkdetail/missingDocAlert/${id}`,
        querystring.stringify(values),
      )
      .then(response => {
        dispatch({ type: ALERT_MISSING_DOCS })
      })
      .catch(() => {
        dispatch(addError('ALERT MISSING DOCS FAILED'))
      })
    axios
      .post(`${ROOT_URL}/api/booking/comment/${bkID}`, msg)
      .then(response => {
        dispatch({ type: CREATE_COMMENTS, payload: response.data })
        callback()
      })
      .catch(() => {
        dispatch(addError('Insert comment failed!'))
      })
  }
}

export function checkHBLExist(filename, folder) {
  return function(dispatch) {
    const link = `https://otxusa.s3-us-west-1.amazonaws.com/sfo_uploads/${folder}/${filename}.pdf`
    axios
      .head(link)
      .then(response => {
        // console.info(`response status: ${response.status}`);
        // if (!response) {
        //   console.log('No response');
        // }

        if (response.status === 200) {
          dispatch({
            type: IF_HBL_EXISTS,
          })
        }
        // if (response.status === 404) {
        //   dispatch({
        //     type: DOES_NOT_EXIST
        //   });
        // }
      })
      .catch(() => {
        // console.log('error');
        dispatch(checkFileError('Something is wrong with file checking'))
      })
  }
}
export function checkCommInvoiceExist(filename, folder) {
  return function(dispatch) {
    const link = `https://otxusa.s3-us-west-1.amazonaws.com/sfo_uploads/${folder}/${filename}.pdf`
    axios
      .head(link)
      .then(response => {
        // console.info(`response status: ${response.status}`);
        // if (!response) {
        //   console.log('No response');
        // }

        if (response.status === 200) {
          dispatch({
            type: IF_COMM_INVOICE_EXISTS,
          })
          // console.log('found');
        }
        // if (response.status === 404) {
        //   dispatch({
        //     type: DOES_NOT_EXIST
        //   });
        // }
      })
      .catch(() => {
        // console.log('error');
        dispatch(checkFileError('Something is wrong with file checking'))
      })
  }
}
export function checkMBLExist(filename, folder) {
  return function(dispatch) {
    const link = `https://otxusa.s3-us-west-1.amazonaws.com/sfo_uploads/${folder}/${filename}.pdf`
    axios
      .head(link)
      .then(response => {
        // console.info(`response status: ${response.status}`);
        // if (!response) {
        //   console.log('No response');
        // }

        if (response.status === 200) {
          dispatch({
            type: IF_MBL_EXISTS,
          })
        }
        // if (response.status === 404) {
        //   dispatch({
        //     type: DOES_NOT_EXIST
        //   });
        // }
      })
      .catch(() => {
        // console.log('error');
        dispatch(checkFileError('Something is wrong with file checking'))
      })
  }
}
export function checkPackingListExist(filename, folder) {
  return function(dispatch) {
    const link = `https://otxusa.s3-us-west-1.amazonaws.com/sfo_uploads/${folder}/${filename}.pdf`
    axios
      .head(link)
      .then(response => {
        // console.info(`response status: ${response.status}`);
        // if (!response) {
        //   console.log('No response');
        // }

        if (response.status === 200) {
          dispatch({
            type: IF_PACKINGLIST_EXISTS,
          })
        }
        // if (response.status === 404) {
        //   dispatch({
        //     type: DOES_NOT_EXIST
        //   });
        // }
      })
      .catch(() => {
        // console.log('error');
        dispatch(checkFileError('Something is wrong with file checking'))
      })
  }
}

export function checkFileError(error) {
  return {
    type: FILE_ERROR,
    payload: error,
  }
}
