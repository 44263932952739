import { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';

class Signout extends Component {
  componentDidMount() {
    this.props.signoutUser();
    this.props.history.push('/signin');
  }
  render() {
    return null;
  }
}

export default connect(
  null,
  actions
)(Signout);
