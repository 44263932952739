import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';

// import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';

class RenderRadio extends Component {
  render() {
    const { dvalue, apStatus1, input, label, type, po, description } = this.props;
    //   console.log(dvalue, apStatus1);

    // console.log(`status: ${status}, input value: ${input.value}`);
    if (apStatus1) {
      return (
        <label style={{ marginRight: '10px' }}>
          <input type={type} checked={dvalue === apStatus1} disabled {...input} />
          &nbsp;&nbsp; {label}
        </label>
      );
    }
    return (
      <label style={{ marginRight: '10px' }}>
        <input
          type={type}
          onClick={() => {
            var msg = {};
            if (dvalue === 'A') {
              msg.Comments = `APPROVED PO# ${po} --- ${description}; \n`;
              msg.description = description;
            }
            if (dvalue === 'N') {
              msg.Comments = `TEMP APPROVED PO# ${po} --- ${description}; \n`;
              msg.description = description;
            }
            if (dvalue === 'H') {
              msg.Comments = `HOLD PO# ${po} --- ${description}; \n`;
              msg.description = description;
            }
            // console.log(msg);
            this.props.getMsgFromRadio(msg);
          }}
          checked={dvalue === apStatus1}
          {...input}
        />
        &nbsp;&nbsp; {label}
      </label>
    );
    //   return (
    //     <ToggleButtonGroup type="radio" name="options" defaultValue={1}>
    //       <ToggleButton value={1}>Radio 1 (pre-checked)</ToggleButton>
    //       <ToggleButton value={2}>Radio 2</ToggleButton>
    //       <ToggleButton value={3}>Radio 3</ToggleButton>
    //     </ToggleButtonGroup>
    //   );
  }
}
export default connect(
  null,
  actions
)(RenderRadio);
