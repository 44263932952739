import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { arrayPush } from 'redux-form';
import { Modal, Button } from 'react-bootstrap';
import { fetchAddress, openModal, closeModal } from '../../actions';
import Spinner from './spinner';

import '../../css/sendmail.css';

class RenderFieldNotification extends Component {
  constructor() {
    super();
    this.text_truncate = this.text_truncate.bind(this);
  }
  componentDidMount() {
    if (this.props.label === 'To:' || this.props.label === 'Cc:') this.props.fetchAddress(this.props.CNeeID);
  }
  text_truncate(str, length, ending) {
    if (length == null) {
      length = 100;
    }
    if (ending == null) {
      ending = '...';
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  }
  render() {
    const {
      addressBook,
      CNeeName,
      label,
      type,
      input,
      disabled,
      meta: { error, touched }
    } = this.props;
    if (!addressBook) {
      return <Spinner />;
    }
    return (
      <div>
        <div>
          <Modal bsSize="large" show={this.props.showModal} onHide={this.props.closeModal}>
            <Modal.Header closeButton>
              <Modal.Title>
                {' '}
                <div className="container">Address Book: {CNeeName}</div>{' '}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
                <h3>To:</h3>
                <div className="row">
                  {addressBook.map((item, index) => {
                    return (
                      <div className="col-sm-4 col-xs-6" key={index}>
                        <div
                          onClick={() => this.props.arrayPush('SendNotification', 'to', item.Email.toString())}
                          className="btn btn-default btn-xs"
                        >
                          {this.text_truncate(item.Name, 24)}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <h3>Cc:</h3>
                <div className="row">
                  {addressBook.map((item, index) => {
                    return (
                      <div className="col-sm-4 col-xs-6" key={index}>
                        <div
                          onClick={() => this.props.arrayPush('SendNotification', 'cc', item.Email)}
                          className="btn btn-default btn-xs"
                        >
                          {this.text_truncate(item.Name, 24)}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.props.closeModal}>Close</Button>
            </Modal.Footer>
          </Modal>
        </div>
        <div className="form-group form-group-sm">
          <div className="row">
            <div className="col-sm-3">
              <label>
                {label}
                <span onClick={this.props.openModal}>
                  {label === 'To:' || label === 'Cc:' ? (
                    <i className="fa fa-address-book-o fa-lg" aria-hidden="true" />
                  ) : (
                    ''
                  )}
                </span>
              </label>
            </div>
            <div className="col-sm-9">
              <input className="form-control" type={type} {...input} disabled={disabled} />
              {label === 'To:' || label === 'Cc:' ? (
                <div className="text-info">For multiple emails separate with comma or semicolon</div>
              ) : (
                ''
              )}
              {touched && error && <span className="text-danger">{error}</span>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps({ util }) {
  return {
    showModal: util.showModal,
    addressBook: util.addressBook
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchAddress, openModal, closeModal, arrayPush }, dispatch);
}

RenderFieldNotification = connect(
  mapStateToProps,
  mapDispatchToProps
)(RenderFieldNotification);
export default RenderFieldNotification;
// export default connect(mapStateToProps, actions)(RenderFieldNotification);
