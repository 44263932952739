import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from './actions';

export default function(ComposedComponent) {
  class AdminAuth extends Component {
    componentDidMount() {
      const { role } = this.props;
      if (role !== 'CONTROL') {
        this.props.history.push('/p/search');
        this.props.addError('ACCESS DENIED');
      }
    }

    UNSAFE_componentWillUpdate(nextProps) {
      if (nextProps.user.accessLevel === 'CONTROL') {
        this.props.history.push('/p/search');
        this.props.addError('ACCESS DENIED');
      }
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  function mapStateToProps({ auth }) {
    return {
      isAuthenticated: auth.isAuthenticated,
      role: auth.user.accessLevel
    };
  }
  return withRouter(
    connect(
      mapStateToProps,
      actions
    )(AdminAuth)
  );
}
