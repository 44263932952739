import React, { Component } from 'react';
import { Button, ButtonToolbar, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import * as actions from '../../actions';
import '../../css/booking.css';
import RenderCheckbox from '../booking/renderCheckbox';
import CommInvoice from '../booking/renderCommInvoice';
import RenderDateField from '../booking/renderDateField';
// import RenderContainer from '../booking/renderContainer';
import HAWB from '../booking/renderHBL';
import MoveBooking from '../util/moveBooking';
import Spinner from '../util/spinner';
import validate from '../util/validate';
import DetailModal from './createDetailModal';

class BookingAirDetail extends Component {
  constructor(props) {
    super(props);
    this.onBkDetailSubmit = this.onBkDetailSubmit.bind(this);
    this.deleteDetail = this.deleteDetail.bind(this);
  }

  componentDidMount() {
    // console.log(this.props.match.params);
    const { BookingID } = this.props.match.params;
    this.props.fetchBookingDetail(BookingID);
  }

  onBkDetailSubmit(values) {
    const { BookingID } = this.props.match.params;
    this.props.updateBookingDetail(BookingID, values, () => {
      alert('Booking details updated');
      window.location.reload(true);
    });
  }

  deleteDetail(DetailID) {
    this.props.destoryDetail(DetailID, () => {
      alert('Booking Detail Deleted!');
      window.location.reload(true);
    });
  }

  renderField(field) {
    const { label, type, input, ...rest } = field;
    return (
      <div className="form-group form-group-sm col-sm-6">
        <div className="row">
          <label className="col-sm-4 col-form-label">{label}</label>
          <div className="col-sm-7">
            <input className="form-control" type={type} {...input} {...rest} />
          </div>
        </div>
      </div>
    );
  }

  renderCargo(field) {
    const { label, type, input, ...rest } = field;
    return (
      <div className="form-group form-group-sm col-sm-12">
        <div className="row">
          <div className="col-sm-12">
            <input className="form-control" type={type} {...input} {...rest} />
          </div>
        </div>
      </div>
    );
  }

  renderUpdatedMsg() {
    if (this.props.updatedBkDetail) {
      return (
        <div className="alert alert-success text-center">
          <strong>Successfully updated!</strong>
        </div>
      );
    }
  }

  renderMove(field) {
    const { label, type, input, ...rest } = field;
    return (
      <div className="form-group form-group-sm col-sm-6">
        <div className="row">
          <label className="col-sm-4 col-form-label">{label}</label>
          <div className="col-sm-7">
            <input className="form-control" type={type} {...input} {...rest} />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { booking, initialValues, handleSubmit, ShipmentType } = this.props;
    const approvalPending = {
      color: 'red',
      paddingBottom: '10px'
    };
    const approved = {
      color: 'green',
      paddingBottom: '10px'
    };
    const tempApproved = {
      color: 'orange',
      paddingBottom: '10px'
    };

    if (!booking || !initialValues) {
      return <Spinner />;
    }
    if (!booking.BkDetails) {
      return (
        <div>
          <DetailModal
            ShipmentType={ShipmentType}
            showCreateModal={this.props.showCreateModal}
            closeCreateModal={this.props.closeCreateModal}
          />
          <fieldset className="for-panel">
            <legend>BOOKING DETAILS</legend>
            <div className="page-header">
              <ButtonToolbar className="detail-submenu">
                <Button bsSize="small" bsStyle="default" onClick={this.props.openCreateModal}>
                  Add New Detail
                </Button>
              </ButtonToolbar>
            </div>
          </fieldset>
        </div>
      );
    }
    return (
      <div>
        <DetailModal
          ShipmentType={booking.ShipmentType}
          showCreateModal={this.props.showCreateModal}
          closeCreateModal={this.props.closeCreateModal}
        />
        {booking.BkDetails.map((item, index) => (
          <fieldset className="for-panel" key={index}>
            <legend>BOOKING DETAILS #{index + 1}</legend>
            {(() => {
              if (item.bkStatus === 'AP' || item.ApStatus1)
                return (
                  <h4 style={approved}>
                    Approved
                    <i className="fa fa-check-square-o" style={{ marginLeft: '5px' }} aria-hidden="true" />
                  </h4>
                );
              if (item.bkStatus === 'TEMP') return <h4 style={tempApproved}>Temp Approved</h4>;
              if (item.bkStatus === 'HOLD') return <h4 style={approvalPending}>HOLD</h4>;
              if (item.bkStatus === 'BR' && !item.ApStatus1)
                return (
                  <h4 style={approvalPending}>
                    Approval Pending
                    <i className="fa fa-times-circle-o" style={{ marginLeft: '5px' }} aria-hidden="true" />
                  </h4>
                );
              return null;
            })()}
            <div className="page-header">
              <ButtonToolbar className="detail-submenu">
                <Button bsSize="small" bsStyle="default" onClick={this.props.openCreateModal}>
                  Add New Detail
                </Button>
                <Button
                  bsSize="small"
                  bsStyle="default"
                  onClick={() => {
                    if (window.confirm('Delete the item?')) {
                      this.deleteDetail(`${item.BkDetailID}`);
                    }
                  }}
                >
                  Delete
                </Button>
              </ButtonToolbar>
              <div className="row">
                <MoveBooking BkDetailID={item.BkDetailID} details={item} />
              </div>
            </div>
            <form onSubmit={handleSubmit(this.onBkDetailSubmit)}>
              <div className="row">
                <Field
                  label="Customer PO:"
                  type="text"
                  name={`BkDetails[${index}].csnPO`}
                  component={this.renderField}
                />
                <Field
                  label="Shipper Name:"
                  type="text"
                  name={`BkDetails[${index}].ShipperName`}
                  component={this.renderField}
                />
              </div>
              <div className="row">
                <Field
                  label="HAWB #:"
                  hbl={item.HAWB}
                  CNeeID={booking.CNeeID}
                  type="text"
                  name={`BkDetails[${index}].HAWB`}
                  component={HAWB}
                />
                <Field
                  label="Description/Title:"
                  type="text"
                  name={`BkDetails[${index}].CargoDescription`}
                  component={this.renderField}
                />
              </div>
              <div className="row">
                <Field
                  label="Cargo Ready Date:"
                  type="text"
                  name={`BkDetails[${index}].CargoReadyDate`}
                  component={RenderDateField}
                />
                <Field
                  label="Cargo Rap Date:"
                  type="text"
                  name={`BkDetails[${index}].CargoRapDate`}
                  component={RenderDateField}
                />
              </div>
              <div className="row">
                <Field label="PO #:" type="text" name={`BkDetails[${index}].PONo`} component={this.renderField} />
                <Field
                  label="PO Due Date #:"
                  type="text"
                  name={`BkDetails[${index}].CustomerInHandsDate`}
                  component={RenderDateField}
                />
              </div>
              <div className="row">
                <Field
                  label="SKU / ITEM #:"
                  type="text"
                  name={`BkDetails[${index}].SKUNo`}
                  component={this.renderField}
                />
                <Field
                  label="Delivery Addr:"
                  type="text"
                  name={`BkDetails[${index}].DelrAddr`}
                  component={this.renderField}
                />
              </div>

              <div className="row">
                <Field
                  label="Estimate Del. Date:"
                  type="text"
                  name={`BkDetails[${index}].EstDelivery`}
                  component={RenderDateField}
                />
                <Field
                  label="Actual Del. Date:"
                  type="text"
                  name={`BkDetails[${index}].ActDelivery`}
                  component={RenderDateField}
                />
              </div>

              <div className="row">
                <Field
                  label="Final Due Date:"
                  type="text"
                  name={`BkDetails[${index}].FinalDueDate`}
                  component={RenderDateField}
                />
                <Field
                  label="Received by:"
                  type="text"
                  name={`BkDetails[${index}].ReceivedBy`}
                  component={this.renderField}
                />
              </div>
              <div className="row">
                <Field label="HTS #:" type="text" name={`BkDetails[${index}].HTSNo`} component={this.renderField} />
                <Field
                  label="Comm. Invoice No #:"
                  CNeeID={booking.CNeeID}
                  invoiceNo={item.CommInvoiceNo}
                  type="text"
                  name={`BkDetails[${index}].CommInvoiceNo`}
                  component={CommInvoice}
                />
              </div>
              <div className="row">
                <label className="col-sm-2" style={{ color: 'red' }}>
                  Missing Documents:
                </label>
                <Field
                  detailID={item.BkDetailID}
                  BookingID={this.props.match.params.BookingID}
                  label="HAWB:"
                  type="checkbox"
                  docName="MissingDoc1"
                  name={`BkDetails[${index}].MissingDoc1`}
                  component={RenderCheckbox}
                />
                <Field
                  detailID={item.BkDetailID}
                  BookingID={this.props.match.params.BookingID}
                  label="Commerical Invoice And/Or Packing List:"
                  type="checkbox"
                  docName="MissingDoc2"
                  name={`BkDetails[${index}].MissingDoc2`}
                  component={RenderCheckbox}
                />
                <Field
                  detailID={item.BkDetailID}
                  BookingID={this.props.match.params.BookingID}
                  label="ORIGIN CHARGES NOT RECEIVED:"
                  type="checkbox"
                  docName="MissingDoc3"
                  name={`BkDetails[${index}].MissingDoc3`}
                  component={RenderCheckbox}
                />
              </div>
              <Table responsive bordered condensed>
                <thead>
                  <tr>
                    <th className="text-center col-sm-2 col-sm-offset-1">VOLUME :</th>
                    <th className="text-center col-sm-2">GROSS WT :</th>
                    <th className="text-center col-sm-2">CHARGEABLE WT :</th>
                    <th className="text-center col-sm-2">QTY. [CARTON] :</th>
                    <th className="text-center col-sm-2">QTY. [PALLET] :</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="">
                    <td>
                      <Field type="text" name={`BkDetails[${index}].CargoVol`} component={this.renderCargo} />
                    </td>
                    <td>
                      <Field type="text" name={`BkDetails[${index}].GrossWeight`} component={this.renderCargo} />
                    </td>
                    <td>
                      <Field type="text" name={`BkDetails[${index}].ChargeableWeight`} component={this.renderCargo} />
                    </td>
                    <td>
                      <Field type="text" name={`BkDetails[${index}].CartonQty`} component={this.renderCargo} />
                    </td>
                    <td>
                      <Field type="text" name={`BkDetails[${index}].PalletQty`} component={this.renderCargo} />
                    </td>
                  </tr>
                  {/* <tr>
                      <td colSpan="5">
                        Actual Commerical Value : {item.CopyQty} * {item.unitCost} = ${Number(
                          item.CopyQty * item.unitCost
                        ).toFixed(2)}
                      </td>
                    </tr> */}
                </tbody>
              </Table>
              {this.renderUpdatedMsg()}
              <div className="row text-center">
                <button type="submit" name={`submit${index}`} className="btn btn-default">
                  Update
                </button>
              </div>
            </form>
          </fieldset>
        ))}
      </div>
    );
  }
}

function mapStateToProps({ bookings, auth, util }) {
  return {
    booking: bookings.results,
    updatedBkDetail: bookings.updatedBkDetail,
    initialValues: bookings.results,
    showCreateModal: util.showCreateModal,
    user: auth.user
  };
}

BookingAirDetail = reduxForm({
  validate,
  form: 'BookingAirDetail',
  enableReinitialize: true
})(BookingAirDetail);

BookingAirDetail = withRouter(connect(mapStateToProps, actions)(BookingAirDetail));
export default BookingAirDetail;
