import React from 'react';
import Spinner from 'react-spinkit';

import '../../css/spinner.css';

const Spin = () => (
  <div className="spinner">
    <Spinner name="three-bounce" />
    <div className="loading">Loading...</div>
  </div>
);

export default Spin;
