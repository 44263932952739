import { isEmpty } from 'lodash'
import moment from 'moment'
import React, { Component } from 'react'
import { Button, ButtonToolbar } from 'react-bootstrap'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Scrollchor from 'react-scrollchor'
import { Field, reduxForm } from 'redux-form'
import * as actions from '../../actions'
import { ADD_ERROR } from '../../actions/types'
import '../../css/booking.css'
import CustomSweetAlert from '../Alerts/SweetAlert'
import RenderDateField from '../booking/renderDateField'
import RenderField from '../booking/renderField'
import RenderMAWB from '../booking/renderMAWB'
import RenderReactSelect from '../booking/RenderReactSelect'
import RenderStatusField from '../booking/renderStatusField'
import UploadModal from '../renderUploadModal'
import Spinner from '../util/spinner'
import validate from '../util/validate'
import RenderBuyTerms from './renderBuyTerms'
import RenderConsignee from './renderConsignee'

class BookingAirHeader extends Component {
  constructor(props) {
    super(props)
    this.onBookingSubmit = this.onBookingSubmit.bind(this)
  }

  componentDidMount() {
    // console.log(this.props.match.params);
    const { BookingID } = this.props.match.params
    const { accessLevel, CustomerID } = this.props.user
    const { CNeeID } = this.props.booking
    const customerList = CustomerID.split(', ')
    // console.log(typeof customerList, ' ', customerList);
    this.props.fetchBookingHeader(BookingID)
    this.props.fetchPorts()
    if (accessLevel === 'AGENT') {
      const validCustomerID = customerList
        .filter(id => id === CNeeID.toString())
        .toString()
      // console.log(typeof validCustomerID);
      if (!validCustomerID) {
        this.props.history.push('/p/search')
        this.props.dispatch(this.addError('ACCESS DENIED'))
      }
    }
  }

  addError(error) {
    return {
      type: ADD_ERROR,
      payload: error,
    }
  }

  onBookingSubmit(values) {
    const { user, customer } = this.props
    const { BookingID } = this.props.match.params
    values.UpdateBy = user.username
    values.UpdateOn = Date.now()
    customer.forEach(item => {
      if (item.customerNo.toString() === values.CNeeID) {
        values.CNeeName = item.companyName
      }
    })
    this.props.updateBookingHeader(BookingID, values)
    // console.log(values);
  }

  renderUpdatedMsg() {
    if (this.props.updatedBooking) {
      return (
        <div className="alert alert-success text-center">
          <strong>Successfully updated!</strong>
        </div>
      )
    }
  }

  render() {
    const { booking, initialValues, handleSubmit, uploaded, ports } = this.props
    if (!booking || !initialValues || isEmpty(ports)) {
      return <Spinner />
    }
    return (
      <fieldset className="for-panel">
        <ButtonToolbar>
          <Button bsSize="small">
            <Scrollchor to="#comments" style={{ color: 'inherit' }}>
              Comments
            </Scrollchor>
          </Button>
        </ButtonToolbar>
        <div className="row">
          <div className="col-sm-6">Prepare By: {booking.PrepareBy}</div>
          <div className="col-sm-6">
            <ButtonToolbar className="pull-right button-lineup">
              <Button bsSize="small" bsStyle="default" href="/p/booking/new">
                New
              </Button>
              <Button
                bsSize="small"
                bsStyle="default"
                onClick={this.props.openModal}
              >
                Upload
              </Button>
              <Button
                bsSize="small"
                bsStyle="default"
                href={`/p/sendmail/${booking.BookingID}`}
              >
                Send Notification
              </Button>
            </ButtonToolbar>
          </div>
          <UploadModal
            url={`sfo_uploads/${booking.CNeeID}`}
            showModal={this.props.showModal}
            closeModal={this.props.closeModal}
          />
          {uploaded && uploaded.status === 'ok' ? (
            <CustomSweetAlert type="success" title={uploaded.message} />
          ) : null}
        </div>
        <hr />
        <form onSubmit={handleSubmit(this.onBookingSubmit)}>
          <div className="row">
            <div className="col-sm-6 ">
              <strong>
                BOOKING{' '}
                <span style={{ color: 'red' }}>({booking.ShipmentType})</span> #{' '}
              </strong>
              {booking.BookingID} -- created on{' '}
              {moment(booking.createDate).format('MM/DD/YYYY hh:mm A')}
            </div>
            <Field
              label="Consignee:"
              name="CNeeID"
              component={RenderConsignee}
            />
          </div>
          <hr />
          <div className="row">
            <Field label="Flight No:" name="FlightNo" component={RenderField} />
            <Field
              label="Booking Status:"
              name="bkStatus"
              component={RenderStatusField}
            />
          </div>
          <div className="row">
            <Field
              label="Connecting Flight No:"
              type="text"
              name="ConnectingFlightNo"
              component={RenderField}
            />
          </div>
          <div className="row">
            <Field
              label="Shpmt. buying terms:"
              type="text"
              name="buyTerm"
              component={RenderBuyTerms}
            />
            <Field
              label="Master AWB:"
              type="text"
              name="mawb"
              component={RenderMAWB}
            />
          </div>
          <div className="row">
            <Field
              label="Original Port:"
              type="text"
              name="OrgPort"
              data={ports.orgPorts}
              component={RenderReactSelect}
            />
            <Field
              label="ETD:"
              type="text"
              name="ETD"
              component={RenderDateField}
            />
          </div>
          <div className="row">
            <Field
              label="Transit Port:"
              type="text"
              name="DstPort"
              data={ports.dstPorts}
              component={RenderReactSelect}
            />
            <Field
              label="(Transit Port) ETA:"
              type="text"
              name="ETAFirst"
              component={RenderDateField}
            />
          </div>
          <div className="row">
            <Field
              label="Final Destination:"
              type="text"
              name="FnlPort"
              data={ports.fnlPorts}
              component={RenderReactSelect}
            />
            <Field
              label="(Final port) ETA:"
              type="text"
              name="ETAFinal"
              component={RenderDateField}
            />
          </div>
          <div className="row">
            <Field
              label="CW File #:"
              type="text"
              name="CWFileNo"
              component={RenderField}
            />
          </div>
          {this.renderUpdatedMsg()}
          <div className="row text-center">
            <button
              type="submit"
              name="submitBooking"
              className="btn btn-default"
            >
              Update
            </button>
          </div>
          <hr />
        </form>
      </fieldset>
    )
  }
}

function mapStateToProps({ bookings, auth, util, ports }) {
  return {
    booking: bookings.bookingHeader,
    customer: util.customer,
    errorMessage: util.errors,
    initialValues: bookings.bookingHeader,
    ports,
    showModal: util.showModal,
    updatedBooking: bookings.updatedBooking,
    uploaded: util.uploaded,
    user: auth.user,
  }
}

BookingAirHeader = reduxForm({
  form: 'BookingAirHeader',
  enableReinitialize: true,
  validate,
})(BookingAirHeader)

BookingAirHeader = withRouter(
  connect(mapStateToProps, actions)(BookingAirHeader),
)
export default BookingAirHeader
