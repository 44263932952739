import React from 'react'

const renderField = props => {
  const {
    label,
    disabled,
    placeholder,
    type,
    input,
    meta: {touched, error},
  } = props
  return (
    <div className="form-group form-group-sm col-sm-6">
      <div className="row">
        <label className="col-sm-4 col-form-label">{label}</label>
        <div className="col-sm-7">
          <input
            className="form-control"
            type={type}
            placeholder={placeholder}
            disabled={disabled}
            {...input}
          />
          <div className="text-danger">{touched ? error : ''}</div>
        </div>
      </div>
    </div>
  )
}
export default renderField
