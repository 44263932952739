import random from 'lodash/random';
import React from 'react';
import { connect } from 'react-redux';
import * as userActions from '../../actions/users';
import GridContainer from '../Grid/GridContainer.jsx';
import GridItem from '../Grid/GridItem.jsx';
// core components
import Wizard from '../Wizard/Wizard.jsx';
import Step1 from './Step1.jsx';
import Step2 from './Step2.jsx';
import Step3 from './Step3.jsx';

class WizardView extends React.Component {
  state = {
    key: 0
  };

  handleSubmit(e) {
    const { createUser, onClose } = this.props;
    createUser(e, () => {
      onClose();
      this.setState({ key: random(1, 10000) });
    });
  }

  render() {
    const { key } = this.state;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={8}>
          <Wizard
            key={key}
            validate
            steps={[
              { stepName: 'About', stepComponent: Step1, stepId: 'about' },
              { stepName: 'Account', stepComponent: Step2, stepId: 'account' },
              { stepName: 'Credentials', stepComponent: Step3, stepId: 'address' }
            ]}
            title="Create User Account"
            subtitle="This information will let us know more about you."
            finishButtonClick={e => {
              this.handleSubmit(e);
            }}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

export default connect(
  null,
  userActions
)(WizardView);
