import InputAdornment from '@material-ui/core/InputAdornment';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Email from '@material-ui/icons/Email';
// @material-ui/icons
import Face from '@material-ui/icons/Face';
import RecordVoiceOver from '@material-ui/icons/RecordVoiceOver';
import React from 'react';
import CustomInput from '../CustomInput/CustomInput.jsx';
// core components
import GridContainer from '../Grid/GridContainer.jsx';
import GridItem from '../Grid/GridItem.jsx';

const style = {
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center'
  },
  inputAdornmentIcon: {
    color: '#555'
  },
  inputAdornment: {
    position: 'relative'
  }
};

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      FirstName: '',
      FirstNameState: '',
      LastName: '',
      LastNameState: '',
      email: '',
      emailState: ''
    };
  }

  // function that returns true if value is email, false otherwise
  verifyEmail = value => {
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  // function that verifies if a string has a given length or not
  verifyLength = (value, length) => {
    if (value.length >= length) {
      if (/^[a-z]+$/i.test(value)) {
        return true;
      }
      return false;
    }
    return false;
  };

  sendState() {
    return this.state;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [`${stateName}State`]: 'success' });
        } else {
          this.setState({ [`${stateName}State`]: 'error' });
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [`${stateName}State`]: 'success' });
        } else {
          this.setState({ [`${stateName}State`]: 'error' });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  }

  isValidated() {
    const { FirstNameState, LastNameState, emailState } = this.state;
    if (FirstNameState === 'success' && LastNameState === 'success' && emailState === 'success') {
      return true;
    }
    if (FirstNameState !== 'success') {
      this.setState({ FirstNameState: 'error' });
    }
    if (LastNameState !== 'success') {
      this.setState({ LastNameState: 'error' });
    }
    if (emailState !== 'success') {
      this.setState({ emailState: 'error' });
    }

    return false;
  }

  render() {
    const { classes } = this.props;
    const { FirstNameState, LastNameState, emailState } = this.state;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8} lg={8}>
          <h4 className={classes.infoText}>Let's start with the basic information (with validation)</h4>
        </GridItem>
        <GridItem xs={12} sm={12} md={8} lg={8}>
          <CustomInput
            success={FirstNameState === 'success'}
            error={FirstNameState === 'error'}
            labelText={
              <span>
                First Name <small>(required)</small>
              </span>
            }
            id="FirstName"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              autoComplete: 'user given-name',
              onChange: event => this.change(event, 'FirstName', 'length', 3),
              endAdornment: (
                <InputAdornment position="end" className={classes.inputAdornment}>
                  <Face className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
          <CustomInput
            success={LastNameState === 'success'}
            error={LastNameState === 'error'}
            labelText={
              <span>
                Last Name <small>(required)</small>
              </span>
            }
            id="LastName"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              autoComplete: 'user family-name',
              onChange: event => this.change(event, 'LastName', 'length', 3),
              endAdornment: (
                <InputAdornment position="end" className={classes.inputAdornment}>
                  <RecordVoiceOver className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={8} lg={8}>
          <CustomInput
            success={emailState === 'success'}
            error={emailState === 'error'}
            labelText={
              <span>
                Email <small>(required)</small>
              </span>
            }
            id="email"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              autoComplete: 'user email',
              type: 'email',
              onChange: event => this.change(event, 'email', 'email'),
              endAdornment: (
                <InputAdornment position="end" className={classes.inputAdornment}>
                  <Email className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(style)(Step1);
