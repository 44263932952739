import { isEmpty } from 'lodash'
import moment from 'moment'
import React, { Component } from 'react'
import { Button, ButtonToolbar } from 'react-bootstrap'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Scrollchor from 'react-scrollchor'
import { Field, reduxForm } from 'redux-form'
import * as actions from '../../actions'
import { ADD_ERROR } from '../../actions/types'
import CustomSweetAlert from '../Alerts/SweetAlert'
import UploadModal from '../renderUploadModal'
import Spinner from '../util/spinner'
import validate from '../util/validate'
import RenderDateField from './renderDateField'
import RenderField from './renderField'
import RenderMAWB from './renderMAWB'
import RenderReactSelect from './RenderReactSelect'
import RenderStatusField from './renderStatusField'

class BookingAirHeader extends Component {
  constructor(props) {
    super(props)
    this.onBookingSubmit = this.onBookingSubmit.bind(this)
  }

  componentDidMount() {
    // console.log(this.props.match.params);
    const { BookingID } = this.props.match.params
    const { accessLevel, CustomerID } = this.props.user
    const { CNeeID } = this.props.booking
    this.props.fetchBookingHeader(BookingID)
    this.props.fetchPorts()
    // check if the operation account has a customer ID,
    // meaning that this operator account is only able to view these customers' bookings
    if (this.props.user.CustomerID && accessLevel === 'OPERATOR') {
      const customerList = CustomerID.split(', ')
      const validCustomerID = customerList
        .filter(id => id === CNeeID.toString())
        .toString()
      // console.log(typeof validCustomerID);
      if (!validCustomerID) {
        this.props.history.push('/p/search')
        this.props.dispatch(this.addError('ACCESS DENIED'))
      }
    }
  }

  addError(error) {
    return {
      type: ADD_ERROR,
      payload: error,
    }
  }

  onBookingSubmit(values) {
    const { BookingID } = this.props.match.params
    const { user } = this.props
    values.UpdateBy = user.username
    values.UpdateOn = Date.now()
    this.props.updateBookingHeader(BookingID, values)
  }

  renderUpdatedMsg() {
    if (this.props.updatedBooking) {
      return (
        <div className="alert alert-success text-center">
          <strong>Successfully updated!</strong>
        </div>
      )
    }
  }

  deleteBooking(id) {
    this.props.deleteBooking(id, () => {
      this.props.history.push(`/p/search`)
    })
  }

  render() {
    const { booking, initialValues, handleSubmit, uploaded, ports } = this.props
    if (!booking || !initialValues || isEmpty(ports)) {
      return <Spinner />
    }

    return (
      <fieldset className="for-panel" id="header">
        <ButtonToolbar>
          <Button bsSize="small">
            <Scrollchor to="#comments" style={{ color: 'inherit' }}>
              Comments
            </Scrollchor>
          </Button>
          <Button
            bsSize="small"
            onClick={() => {
              if (window.confirm(`Are you sure to delete this booking?`)) {
                this.deleteBooking(booking.BookingID)
              }
            }}
          >
            Delete
          </Button>
        </ButtonToolbar>
        <div className="row">
          <div className="col-sm-6">Prepare By: {booking.PrepareBy}</div>
          <div className="col-sm-6">
            <ButtonToolbar className="pull-right button-lineup">
              <Button
                bsSize="small"
                bsStyle="default"
                onClick={this.props.openModal}
              >
                Upload
              </Button>
              <Button
                bsSize="small"
                bsStyle="default"
                href={`/p/sendmail/${booking.BookingID}`}
              >
                Send Notification
              </Button>
            </ButtonToolbar>
          </div>
          <UploadModal
            url={`sfo_uploads/${booking.CNeeID}`}
            showModal={this.props.showModal}
            closeModal={this.props.closeModal}
          />
          {uploaded && uploaded.status === 'ok' ? (
            <CustomSweetAlert type="success" title={uploaded.message} />
          ) : null}
        </div>
        <form onSubmit={handleSubmit(this.onBookingSubmit)}>
          <div className="row">
            <div className="col-sm-6 ">
              <strong>
                BOOKING{' '}
                <span style={{ color: 'red' }}>({booking.ShipmentType})</span> #{' '}
              </strong>
              {booking.BookingID} -- created on{' '}
              {moment(booking.createDate).format('MM/DD/YYYY hh:mm A')}
            </div>
            <div className="col-sm-6 pull-right button-lineup">
              <strong>Consignee:</strong> [{booking.CNeeID}] {booking.CNeeName}
            </div>
          </div>

          <hr />
          <div className="row">
            <Field label="Flight No:" name="FlightNo" component={RenderField} />
            <Field
              label="Booking Status:"
              name="bkStatus"
              component={RenderStatusField}
            />
          </div>
          <div className="row">
            <Field
              label="Connecting Flight No:"
              name="ConnectingFlightNo"
              component={RenderField}
            />
          </div>
          <div className="row">
            <Field
              label="Shpmt. buying terms:"
              type="text"
              name="buyTerm"
              component={RenderField}
            />
            <Field
              label="Master AWB:"
              type="text"
              name="mawb"
              component={RenderMAWB}
            />
          </div>
          <div className="row">
            <Field
              label="CW File #:"
              type="text"
              name="CWFileNo"
              component={RenderField}
            />
            <Field
              label="ISF File Date:"
              type="text"
              name="ISFDate"
              component={RenderDateField}
            />
          </div>
          <div className="row">
            <Field
              label="Original Port:"
              type="text"
              name="OrgPort"
              data={ports.orgPorts}
              component={RenderReactSelect}
            />
            <Field
              label="ETD:"
              type="text"
              name="ETD"
              component={RenderDateField}
            />
          </div>
          <div className="row">
            <Field
              label="Transit Port:"
              type="text"
              name="DstPort"
              data={ports.dstPorts}
              component={RenderReactSelect}
            />
            <Field
              label="(Transit Port) ETA:"
              type="text"
              name="ETAFirst"
              component={RenderDateField}
            />
          </div>
          <div className="row">
            <Field
              label="Final Destination:"
              type="text"
              name="FnlPort"
              data={ports.fnlPorts}
              component={RenderReactSelect}
            />
            <Field
              label="(Final port) ETA:"
              type="text"
              name="ETAFinal"
              component={RenderDateField}
            />
          </div>
          <div className="row">
            <Field
              label="Document Send Date:"
              type="text"
              name="docSend"
              component={RenderDateField}
            />
            <Field
              label="Approval Cut Off:"
              type="text"
              name="AprvCutOff"
              component={RenderDateField}
            />
          </div>
          {this.renderUpdatedMsg()}
          <div className="row text-center">
            <button
              type="submit"
              name="submitBooking"
              className="btn btn-default"
            >
              Update
            </button>
          </div>
          <hr />
        </form>
      </fieldset>
    )
  }
}

function mapStateToProps(state) {
  return {
    errorMessage: state.util.errors,
    initialValues: state.bookings.bookingHeader,
    ports: state.ports,
    showModal: state.util.showModal,
    updatedBooking: state.bookings.updatedBooking,
    uploaded: state.util.uploaded,
    user: state.auth.user,
  }
}

BookingAirHeader = reduxForm({
  form: 'BookingAirHeader',
  enableReinitialize: true,
  validate,
})(BookingAirHeader)

BookingAirHeader = withRouter(
  connect(mapStateToProps, actions)(BookingAirHeader),
)
export default BookingAirHeader
