import React from 'react'
import CreatableSelect from 'react-select/creatable'
import ReactSelect from 'react-select'

function RenderReactSelect(props) {
  const {
    input: { onChange, value },
    label,
    data,
    variant = 'creatable',
  } = props
  let currentValue = value
  if (typeof value === 'string') {
    currentValue = { value, label: value }
  }

  const styles = {
    container: styles => ({ ...styles, height: 30 }),
    control: styles => ({ ...styles, minHeight: 30 }),
    dropdownIndicator: styles => ({ ...styles, padding: 4 }),
    clearIndicator: styles => ({ ...styles, padding: 4 }),
    valueContainer: styles => ({ ...styles, fontSize: 12 }),
    menu: styles => ({ ...styles, fontSize: 12 }),
  }

  return (
    <div className="form-group form-group-sm col-sm-6">
      <div className="row">
        <label className="col-sm-4 col-form-label">{label}</label>
        <div className="col-sm-7">
          {variant === 'default' ? (
            <ReactSelect
              styles={styles}
              options={data}
              onChange={onChange}
              value={currentValue}
            />
          ) : (
            <CreatableSelect
              isClearable
              styles={styles}
              options={data}
              onChange={onChange}
              value={currentValue}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default RenderReactSelect
