import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.css';
import '../css/landing.css';

class Landing extends Component {
  render() {
    return (
      <div>
        <div id="landing-header">
          <h1>Welcome to OTX Logistics!</h1>
          <Link to="/p/search" className="btn btn-primary">
            Get Started
          </Link>
        </div>
        <ul className="slideshow">
          <li />
          <li />
          <li />
          <li />
          <li />
        </ul>
      </div>
    );
  }
}

export default Landing;
