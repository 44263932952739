import axios from 'axios';
import { createBrowserHistory } from 'history';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import * as actions from './actions';
import FourOhFour from './components/FourOhFour';
import Landing from './components/landing';
import SignIn from './components/signin';
import Signout from './components/signout';
// import routes from './routes'
import MainLayout from './layouts/Main';

const hist = createBrowserHistory();

class App extends Component {
  render() {
    (function() {
      axios.interceptors.request.use(function(config) {
        const token = localStorage.getItem('jwtToken');
        config.headers.Authorization = token;

        return config;
      });
    })();
    return (
      <Router history={hist}>
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route exact path="/signin" component={SignIn} />
          <Route exact path="/signout" component={Signout} />
          <Route path="/p" component={MainLayout} />
          <Route path="*" component={FourOhFour} />
        </Switch>
      </Router>
    );
  }
}

export default connect(
  null,
  actions
)(App);
