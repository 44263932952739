import moment from 'moment'
import queryString from 'query-string'
import React, { Component } from 'react'
import { Button, ButtonToolbar } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import * as actions from '../actions'
import '../css/search.css'
import CustomSweetAlert from './Alerts/SweetAlert'
import UploadModal from './AutomatedBookingModal'
import RenderDateField from './booking/renderDateField'
import RenderField from './booking/renderField'
import RenderShipmentStatus from './booking/renderShipmentStatus'
// import _ from 'lodash';
import RenderStatusField from './booking/renderStatusField'
import RenderAlert from './util/renderAlert'
import Spinner from './util/spinner'
import validate from './util/validate'

class SearchOcean extends Component {
  onSubmit(values) {
    if (values.ETDFrom) {
      values.ETDFrom = moment(values.ETDFrom).format('MM/DD/YYYY')
    }
    if (values.ETDTo) {
      values.ETDTo = moment(values.ETDTo).format('MM/DD/YYYY')
    }
    if (values.ETAFirstFrom) {
      values.ETAFirstFrom = moment(values.ETAFirstFrom).format('MM/DD/YYYY')
    }
    if (values.ETAFirstTo) {
      values.ETAFirstTo = moment(values.ETAFirstTo).format('MM/DD/YYYY')
    }
    if (values.ETAFinalFrom) {
      values.ETAFinalFrom = moment(values.ETAFinalFrom).format('MM/DD/YYYY')
    }
    if (values.ETAFinalTo) {
      values.ETAFinalTo = moment(values.ETAFinalTo).format('MM/DD/YYYY')
    }
    if (values.CargoReadyDateFrom) {
      values.CargoReadyDateFrom = moment(values.CargoReadyDateFrom).format(
        'MM/DD/YYYY',
      )
    }
    if (values.CargoReadyDateTo) {
      values.CargoReadyDateTo = moment(values.CargoReadyDateTo).format(
        'MM/DD/YYYY',
      )
    }

    if (this.props.user.accessLevel === 'AGENT') {
      values.CNeeID = this.props.user.CustomerID.split(', ')
    }
    if (
      this.props.user.accessLevel === 'OPERATOR' &&
      this.props.user.CustomerID
    ) {
      values.CNeeID = this.props.user.CustomerID.split(', ')
    }

    // check if customer:
    if (this.props.user.accessLevel === 'CUSTOMER') {
      values.CNeeID = this.props.user.CustomerID.split(', ')
    }
    this.props.history.push(
      `/p/booking/result?${queryString.stringify(values)}`,
    )
  }

  renderSubMenu() {
    const { showModal, openModal, closeModal, uploaded, user } = this.props
    if (user.accessLevel === 'AGENT') {
      return (
        <div className="row">
          <ButtonToolbar className="submenu">
            <Button bsSize="small" bsStyle="default" href="/p/booking/new">
              New
            </Button>
            <Button bsSize="small" bsStyle="default" onClick={openModal}>
              Upload Template
            </Button>
          </ButtonToolbar>
          <UploadModal
            url="template_uploads/"
            type="auto-booking-creation"
            role={'agent'}
            showModal={showModal}
            closeModal={closeModal}
          />
          {uploaded && uploaded.status === 'ok' ? (
            <CustomSweetAlert type="success" title={uploaded.message} />
          ) : uploaded && uploaded.status === 'warn' ? (
            <CustomSweetAlert type="warning" title={uploaded.message} />
          ) : uploaded && uploaded.status === 'error' ? (
            <CustomSweetAlert type="error" title={uploaded.message} />
          ) : null}
        </div>
      )
    }
  }

  render() {
    const { handleSubmit, user, isLoading, errorMessage } = this.props
    if (isLoading) {
      return <Spinner />
    }
    return (
      <div>
        <div className="col-sm-10 col-sm-offset-1">
          <h2 className="text-center">Search Booking</h2>
          <RenderAlert errorMessage={errorMessage} />
          {this.renderSubMenu()}

          <div className="row">
            <div className="col-md-12">
              <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                <div className="row">
                  <Field
                    label="Booking ID:"
                    placeholder="Booking ID"
                    name="BookingID"
                    component={RenderField}
                  />
                  {user.accessLevel !== 'CUSTOMER' ? (
                    <Field
                      label="Consignee Name:"
                      placeholder="Consignee Name"
                      name="CNeeName"
                      component={RenderField}
                    />
                  ) : (
                    <Field
                      label="Booking Status:"
                      name="bkStatus"
                      component={RenderStatusField}
                    />
                  )}
                </div>
                <div className="row">
                  <Field
                    label="PO No:"
                    placeholder="PO #"
                    name="PONo"
                    component={RenderField}
                  />
                  <Field
                    label="Vessel Name:"
                    placeholder="Vessel Name"
                    name="VesselName"
                    component={RenderField}
                  />
                </div>
                <div className="row">
                  {user.accessLevel !== 'CUSTOMER' ? (
                    <Field
                      label="Booking Status:"
                      name="bkStatus"
                      component={RenderStatusField}
                    />
                  ) : (
                    <Field
                      label="SKU / Item #:"
                      name="SKUNo"
                      placeholder="SKU #"
                      component={RenderField}
                    />
                  )}

                  <Field
                    label="Shipment Status:"
                    name="spStatus"
                    component={RenderShipmentStatus}
                  />
                </div>
                <div className="row">
                  <Field
                    label="HBL:"
                    placeholder="HBL"
                    name="HBL"
                    component={RenderField}
                  />
                  <Field
                    label="MBL:"
                    placeholder="MBL"
                    name="MBL"
                    component={RenderField}
                  />
                </div>
                <div className="row">
                  <Field
                    label="HAWB:"
                    placeholder="HAWB"
                    name="HAWB"
                    component={RenderField}
                  />
                  <Field
                    label="MAWB:"
                    placeholder="MAWB"
                    name="mawb"
                    component={RenderField}
                  />
                </div>
                <div className="row">
                  <Field
                    label="Original Port:"
                    placeholder="Original Port"
                    name="OrgPort"
                    component={RenderField}
                  />
                  <Field
                    label="Destination Port:"
                    placeholder="Destination Port"
                    name="FnlPort"
                    component={RenderField}
                  />
                </div>
                <div className="row">
                  <Field
                    label="Container #:"
                    placeholder="Container #"
                    name="ContainerNo"
                    component={RenderField}
                  />
                  <Field
                    label="Buyer Code:"
                    placeholder="Buyer Code"
                    name="buyCode"
                    component={RenderField}
                  />
                </div>
                <div className="row">
                  <Field
                    label="Description:"
                    placeholder="Title or Description"
                    name="CargoDescription"
                    component={RenderField}
                  />
                  <Field
                    label="Shipper Name:"
                    placeholder="Shipper Name"
                    name="ShipperName"
                    component={RenderField}
                  />
                </div>
                <div className="row">
                  {user.accessLevel !== 'CUSTOMER' ? (
                    <Field
                      label="CW File No:"
                      placeholder="CW File No."
                      name="CWFileNo"
                      component={RenderField}
                    />
                  ) : (
                    ''
                  )}
                  <Field
                    label="Comm Invoice #:"
                    name="CommInvoiceNo"
                    component={RenderField}
                  />
                </div>
                <div className="row">
                  <Field
                    label="ETD From:"
                    placeholder="MM/DD/YYYY"
                    name="ETDFrom"
                    component={RenderDateField}
                  />
                  <Field
                    label="ETD To:"
                    placeholder="MM/DD/YYYY"
                    name="ETDTo"
                    component={RenderDateField}
                  />
                </div>
                <div className="row">
                  <Field
                    label="ETA First From:"
                    placeholder="MM/DD/YYYY"
                    name="ETAFirstFrom"
                    component={RenderDateField}
                  />
                  <Field
                    label="ETA First To:"
                    placeholder="MM/DD/YYYY"
                    name="ETAFirstTo"
                    component={RenderDateField}
                  />
                </div>
                <div className="row">
                  <Field
                    label="ETA Final From:"
                    placeholder="MM/DD/YYYY"
                    name="ETAFinalFrom"
                    component={RenderDateField}
                  />
                  <Field
                    label="ETA Final To:"
                    placeholder="MM/DD/YYYY"
                    name="ETAFinalTo"
                    component={RenderDateField}
                  />
                </div>
                <div className="row">
                  <Field
                    label="Cargo Ready From:"
                    placeholder="MM/DD/YYYY"
                    name="CargoReadyDateFrom"
                    component={RenderDateField}
                  />
                  <Field
                    label="Cargo Ready To:"
                    placeholder="MM/DD/YYYY"
                    name="CargoReadyDateTo"
                    component={RenderDateField}
                  />
                </div>
                <div className="text-center">
                  <Button bsStyle="default" disabled={isLoading} type="submit">
                    {isLoading ? 'Loading...' : 'Search'}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps({ util, auth }) {
  return {
    errorMessage: util.errors,
    isLoading: util.isLoading,
    showModal: util.showModal,
    uploaded: util.uploaded,
    user: auth.user,
  }
}

export default reduxForm({
  validate,
  form: 'SearchOcean',
  destroyOnUnmount: false,
})(connect(mapStateToProps, actions)(SearchOcean))
