import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import * as actions from '../../actions';

class MBL extends Component {
  state = {
    mblExist: false,
    isLoading: true
  };

  componentDidMount() {
    const { mbl, CNeeID } = this.props;
    const link = `https://otxusa.s3-us-west-1.amazonaws.com/sfo_uploads/${CNeeID}/${mbl}.pdf`;
    fetch(link, {
      method: 'HEAD'
    }).then(res => {
      if (res.status === 200) {
        this.setState({ mblExist: true });
      }
      this.setState({ isLoading: false });
    });
    // this.props.checkMBLExist(mbl, CNeeID);
  }

  render() {
    const { mblExist, isLoading } = this.state;
    const {
      disabled,
      mbl,
      CNeeID,
      label,
      input,
      meta: { touched, error }
    } = this.props;

    if (isLoading) {
      return <div>Loading...</div>;
    }

    if (!mblExist && !isLoading) {
      return (
        <div className="form-group form-group-sm col-sm-6">
          <div className="row">
            <label className="col-sm-4 col-form-label">{label}</label>
            <div className="col-sm-7">
              <input className="form-control" {...input} disabled={disabled} />
              <div className="text-danger">{touched ? error : ''}</div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="form-group form-group-sm col-sm-6">
        <div className="row">
          <label className="col-sm-4 col-form-label">{label}</label>
          <div className="col-sm-7">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://otxusa.s3-us-west-1.amazonaws.com/sfo_uploads/${CNeeID}/${mbl}.pdf`}
            >
              {mbl}
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default MBL;

// function mapStateToProps(state) {
//   return {
//     mblExist: state.util.mblExist
//   };
// }
// export default connect(
//   mapStateToProps,
//   actions
// )(MBL);
