import { FETCH_PORTS } from '../actions/types'

const DEFAULT_STATE = {}

function reducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case FETCH_PORTS:
      return {
        ...action.payload,
      }

    default:
      return state
  }
}

export default reducer
