import React, { Component } from 'react';

export default class RenderProNo extends Component {
  render() {
    const {
      label,
      trucker,
      disabled,
      vendorID,
      type,
      input,
      meta: { touched, error }
    } = this.props;

    const truckerName = trucker.filter(t => t.VendorURLID === vendorID);

    const truckerNickName = Object.assign({}, ...truckerName).VendorShortName;

    return (
      <div className="form-group form-group-sm col-sm-6">
        <div className="row">
          <label className="col-sm-4 col-form-label">{label}</label>

          {input.value && truckerNickName ? (
            <div className="col-sm-8">
              <div className="col-sm-8" style={{ padding: '0' }}>
                <input className="form-control" type={type} disabled={disabled} {...input} />
                <div className="text-danger">{touched ? error : ''}</div>
              </div>
              <div className="col-sm-2" style={{ paddingLeft: '4px' }}>
                <a
                  className="btn btn-sm btn-default"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`http://connect.track-trace.com/for/otx/${truckerNickName}/${input.value}`}
                >
                  Track
                </a>
              </div>
            </div>
          ) : (
            <div className="col-sm-7">
              <input className="form-control" type={type} disabled={disabled} {...input} />
              <div className="text-danger">{touched ? error : ''}</div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
