import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import Spinner from '../util/spinner';

class ContainerSummary extends Component {
  componentDidMount() {
    // get list of containers with the booking id
    this.props.fetchContainers(this.props.id);
  }

  containerSummary(containerArr) {
    if (!containerArr) return '';
    let totalContainers = 0;
    let size20 = 0;
    let msg20 = '';
    let size40 = 0;
    let msg40 = '';
    let size40HQ = 0;
    let msg40HQ = '';
    let size45 = 0;
    let msg45 = '';
    containerArr.forEach(item => {
      if (item.ContainerNo.length === 11) {
        totalContainers++;
      }
      if (item.CargoSize === '29') {
        size20++;
        msg20 = `${size20} x 20',`;
      }
      if (item.CargoSize === '30') {
        size40++;
        msg40 = `${size40} x 40',`;
      }
      if (item.CargoSize === '31') {
        size40HQ++;
        msg40HQ = `${size40HQ} x 40'HQ,`;
      }
      if (item.CargoSize === '32') {
        size45++;
        msg45 = `${size45} x 45'`;
      }
    });
    return `Total: ${totalContainers} container(s) == ${msg20} ${msg40} ${msg40HQ} ${msg45}`;
  }

  render() {
    const { containers } = this.props;
    if (!containers) {
      return <Spinner />;
    }
    // console.log(containers);

    return (
      <fieldset className="for-panel">
        <legend>Summary</legend>
        <div>{this.containerSummary(containers)}</div>
      </fieldset>
    );
  }
}

function mapStateToProps({ bookings }) {
  return {
    containers: bookings.containers
  };
}

export default connect(
  mapStateToProps,
  actions
)(ContainerSummary);
