import 'font-awesome/css/font-awesome.min.css';
import React, { Component } from 'react';
import Datatable from 'react-bs-datatable';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import '../../css/summary.css';
import Spinner from '../util/spinner';

class POResults extends Component {
  render() {
    const { POResult, isLoading } = this.props;
    if (isLoading) {
      return <Spinner />;
    }

    const header = [
      { title: 'PO #', prop: 'PONo', sortable: true, filterable: true },
      { title: 'Buyer', prop: 'buyCode', sortable: true, filterable: true },
      { title: 'SKU #', prop: 'SKUNo', sortable: true, filterable: true },
      {
        title: 'Description',
        prop: 'CargoDescription',
        sortable: true,
        filterable: true
      },
      { title: 'Open Qty', prop: 'POTotal', sortable: true, filterable: false },
      {
        title: 'Shipped Qty',
        prop: 'QtyShipped',
        sortable: true,
        filterable: false
      },
      {
        title: 'Remaning Qty',
        prop: 'QtyRemaining',
        sortable: true,
        filterable: false
      },
      {
        title: 'Cargo Ready Date',
        prop: 'CargoReadyDate',
        sortable: true,
        filterable: false
      },
      {
        title: 'Promise Date',
        prop: 'CustomerInHandsDate',
        sortable: true,
        filterable: true
      },
      {
        title: 'Shipper Name',
        prop: 'ShipperName',
        sortable: true,
        filterable: true
      },
      {
        title: 'Vendor Country',
        prop: 'VendorCountry',
        sortable: true,
        filterable: true
      }
    ];
    if (!POResult) {
      return (
        <div>
          <h2 className="page-header">Search Results</h2>
          <Datatable
            id="reportTable"
            tableHeader={header}
            tableBody={[]}
            keyName="userTable"
            tableClass="table-bordered table-striped table-condensed table-responsive"
            rowsPerPage={50}
            rowsPerPageOption={[10, 20, 50, 100]}
          />
          <div style={{ marginBottom: '100px' }}>
            <br />
          </div>
        </div>
      );
    }
    // console.log(POResult);
    POResult.forEach(item => (item.PONo = <a href={`/p/po/${item.PONo}/${item.LineNum}`}>{item.PONo}</a>));

    return (
      <div>
        <h2 className="page-header">Search Results</h2>
        <Datatable
          id="reportTable"
          tableHeader={header}
          tableBody={POResult}
          keyName="userTable"
          tableClass="table-bordered table-striped table-condensed table-responsive"
          rowsPerPage={50}
          rowsPerPageOption={[10, 20, 50, 100]}
          initialSort={{ prop: 'PONo', isAscending: false }}
        />
        <div style={{ marginBottom: '100px' }}>
          <br />
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    errorMessage: state.util.errors,
    POResult: state.POs.POSearchResults,
    user: state.auth.user,
    isLoading: state.util.isLoading
  };
}
export default connect(
  mapStateToProps,
  actions
)(POResults);
