import React, { Component } from 'react';

export default class RenderContainer extends Component {
  render() {
    const {
      label,
      disabled,
      carrier,
      type,
      input,
      meta: { touched, error }
    } = this.props;

    return (
      <div className="form-group form-group-sm col-sm-6">
        <div className="row">
          <label className="col-sm-4 col-form-label">{label}</label>

          {input.value.length === 11 ? (
            <div className="col-sm-8">
              <div className="col-sm-8" style={{ padding: '0' }}>
                <input className="form-control" type={type} disabled={disabled} {...input} />
                <div className="text-danger">{touched ? error : ''}</div>
              </div>
              <div className="col-sm-2" style={{ paddingLeft: '4px' }}>
                {carrier ? (
                  <a
                    className="btn btn-sm btn-default"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`http://www.shippingline.org/track/?container=${input.value}&type=container&line=${carrier}`}
                  >
                    Track
                  </a>
                ) : (
                  <a
                    className="btn btn-sm btn-default"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`http://www.shippingline.org/track/?container=${input.value}&type=container`}
                  >
                    Track
                  </a>
                )}
              </div>
            </div>
          ) : (
            <div className="col-sm-7">
              <input className="form-control" type={type} disabled={disabled} {...input} />
              <div className="text-danger">{touched ? error : ''}</div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
