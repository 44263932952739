import React, { Component, Fragment } from 'react'
import {
  Glyphicon,
  MenuItem,
  Nav,
  Navbar,
  NavDropdown,
  NavItem,
} from 'react-bootstrap'
import { connect } from 'react-redux'
import * as actions from '../actions'
import '../css/header.css'
import logo from '../img/logo2.png'
import CustomSweetAlert from './Alerts/SweetAlert'
import UploadModal from './AutomatedBookingModal'

class Header extends Component {
  renderContent() {
    const {
      isAuthenticated,
      user: { username, accessLevel },
      showUploadTemplateModal,
      openUploadTemplateModal,
      closeModal,
    } = this.props
    switch (isAuthenticated) {
      case null:
        return
      case false:
        return (
          <Nav pullRight>
            <NavItem eventKey={4} href="/signin">
              <Glyphicon glyph="log-in" /> Login{' '}
            </NavItem>
          </Nav>
        )
      default:
        return (
          <div>
            <Nav pullRight>
              <NavDropdown
                eventKey={4}
                title={
                  <div style={{ display: 'inline-block' }}>
                    <Glyphicon glyph="user" /> {username}
                  </div>
                }
                id="user-dropdown"
              >
                {accessLevel === 'CONTROL' ? (
                  <React.Fragment>
                    <MenuItem eventKey={4.1} href="/p/account">
                      <Glyphicon glyph="star" /> Account
                    </MenuItem>
                    <MenuItem divider />
                    <MenuItem eventKey={4.2} onClick={openUploadTemplateModal}>
                      <Glyphicon glyph="folder-open" /> Upload Template
                    </MenuItem>
                    <MenuItem divider />
                    <UploadModal
                      role={accessLevel}
                      showModal={showUploadTemplateModal}
                      closeModal={closeModal}
                    />
                  </React.Fragment>
                ) : null}

                {accessLevel === 'OPERATOR' ? (
                  <React.Fragment>
                    <MenuItem eventKey={4.1} onClick={openUploadTemplateModal}>
                      <Glyphicon glyph="folder-open" /> Upload Template
                    </MenuItem>
                    <MenuItem divider />
                    <UploadModal
                      role={accessLevel}
                      showModal={showUploadTemplateModal}
                      closeModal={closeModal}
                    />
                  </React.Fragment>
                ) : null}
                <MenuItem eventKey={4.3} href="/signout">
                  <Glyphicon glyph="log-out" /> Logout
                </MenuItem>
              </NavDropdown>
            </Nav>
          </div>
        )
    }
  }

  renderAlert() {
    const { uploaded } = this.props
    if (uploaded && uploaded.status === 'ok') {
      return <CustomSweetAlert type="success" title={uploaded.message} />
    }
    if (
      uploaded &&
      (uploaded.status === 'warn' || uploaded.status === 'warning')
    ) {
      return <CustomSweetAlert type="warning" title={uploaded.message} />
    }
    if (uploaded && uploaded.status === 'error') {
      return <CustomSweetAlert type="error" title={uploaded.message} />
    }
    return null
  }

  render() {
    const { user } = this.props
    return (
      <Navbar fluid>
        <Navbar.Header>
          <Navbar.Brand>
            <a href="/">
              <img className="img-responsive" src={logo} alt="brand" />
            </a>
          </Navbar.Brand>
          <Navbar.Toggle />
          {this.renderAlert()}
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav>
            {user.accessLevel === 'CUSTOMER' ? (
              <Fragment>
                <NavItem eventKey={1} href="/p/dashboard">
                  Home
                </NavItem>
                <NavItem eventKey={2} href="/p/search">
                  Search
                </NavItem>
                <NavItem eventKey={3} href="/p/report">
                  Report
                </NavItem>
                <NavItem eventKey={4} href="/p/po/search">
                  Inventory
                </NavItem>
              </Fragment>
            ) : (
              <Fragment>
                <NavItem eventKey={1} href="/p/search">
                  <Glyphicon glyph="search" /> Search
                </NavItem>
                <NavItem eventKey={2} href="/p/report">
                  <Glyphicon glyph="list-alt" /> Report
                </NavItem>
                <NavItem eventKey={3} href="/p/po/search">
                  <Glyphicon glyph="shopping-cart" /> Inventory{' '}
                </NavItem>
              </Fragment>
            )}
          </Nav>
          {this.renderContent()}
        </Navbar.Collapse>
      </Navbar>
    )
  }
}
function mapStateToProps({ auth, util }) {
  return {
    errorMessage: util.errors,
    isLoading: util.isLoading,
    showUploadTemplateModal: util.showUploadTemplateModal,
    uploaded: util.uploaded,
    user: auth.user,
    isAuthenticated: auth.isAuthenticated,
  }
}
export default connect(mapStateToProps, actions)(Header)
