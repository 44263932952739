import 'bootstrap/dist/css/bootstrap.css';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import * as actions from '../actions';
import '../css/signin.css';
import Logo from '../img/logo.png';
import Spinner from './util/spinner';

class SignIn extends Component {
  renderField(field) {
    const {
      meta: { touched, error }
    } = field;
    const className = `form-group ${touched && error ? 'has-danger' : ''}`;
    return (
      <div className={className}>
        <input className="form-control" type={field.type} placeholder={field.placeholder} {...field.input} />
        <div className="text-danger">{touched ? error : ''}</div>
      </div>
    );
  }

  onSubmit(values) {
    // this.setState({ isLoading: true });
    this.props.signinUser(values, () => {
      // this.setState({ isLoading: false });
      const { user } = this.props;
      if (user.accessLevel === 'CUSTOMER') {
        this.props.history.push('/p/dashboard');
      } else {
        this.props.history.push('/p/search');
      }
    });
  }
  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <strong>Oops!</strong> {this.props.errorMessage}
        </div>
      );
    }
  }
  render() {
    if (this.props.isLoading) return <Spinner />;
    const { handleSubmit } = this.props;
    return (
      <div className="wrapper">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-md-4 col-md-offset-4">
              <section className="login-form">
                {/* eslint-disable-next-line */}
                <form onSubmit={handleSubmit(this.onSubmit.bind(this))} role="login">
                  <img src={Logo} className="img-responsive" alt="logo" />

                  <Field type="text" name="username" placeholder="Username" component={this.renderField} />
                  <Field type="password" name="password" placeholder="Password" component={this.renderField} />
                  {this.renderAlert()}
                  <button type="submit" name="submit" className="btn btn-lg btn-primary btn-block">
                    Sign in
                  </button>
                </form>
                <div className="form-links">
                  <a target="_blank" rel="noopener noreferrer" href="http://www.otxusa.com">
                    www.otxusa.com
                  </a>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function validate(values) {
  const errors = {};
  // Validate the inputs form 'values'
  if (!values.username) {
    errors.username = 'Please enter a username!';
  }
  if (!values.password) {
    errors.password = 'Password cannot be blank';
  }

  // If errors is empty, the form is fine to submit
  // If errors has *any* properties, redux form assumes form is invalid
  return errors;
}
function mapStateToProps({ auth, util }) {
  return {
    isLoading: util.isLoading,
    errorMessage: auth.error,
    user: auth.user
  };
}

export default reduxForm({
  validate,
  form: 'SignIn'
})(
  connect(
    mapStateToProps,
    actions
  )(SignIn)
);
