import moment from 'moment';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Scrollchor from 'react-scrollchor';
import { Field, reduxForm } from 'redux-form';
import * as actions from '../../actions';
import Spinner from '../util/spinner';

class POComment extends Component {
  constructor(props) {
    super(props);
    this.onCommentSubmit = this.onCommentSubmit.bind(this);
  }

  componentDidMount() {
    // console.log(this.props.match.params);
    const { PONo, LineNum } = this.props.match.params;
    this.props.fetchPOComment(PONo, LineNum);
  }

  onCommentSubmit(values) {
    const { PONo, LineNum } = this.props.match.params;
    values.PONo = PONo;
    values.LineNum = LineNum;
    this.props.createPOComment(PONo, LineNum, values, () => {
      alert('Comment created');
      // window.location.reload(true);
      // this.props.history.push()
    });
  }

  deleteComment(CommentID) {
    this.props.deletePOComment(CommentID, () => {
      alert('Comment Deleted!');
      // window.location.reload(true);
    });
  }

  renderTextArea(field) {
    const { label, input } = field;
    return (
      <div className="form-group form-group-sm">
        <div className="row">
          <label className="col-sm-2 col-form-label">{label}</label>
          <div className="col-sm-9">
            <textarea className="form-control" rows="5" {...input} />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      pocomments,
      handleSubmit,
      errorMessage,
      user: { accessLevel }
    } = this.props;
    if (!pocomments) {
      return <Spinner />;
    }
    return (
      <div id="comments">
        <div className="container">
          <fieldset className="for-panel">
            <div className="pull-right">
              <Button bsSize="small">
                <Scrollchor to="" style={{ color: 'inherit' }}>
                  Top
                </Scrollchor>
              </Button>
            </div>
            <legend>Comments</legend>
            <div className="col-sm-10 col-sm-offset-1">
              <form onSubmit={handleSubmit(this.onCommentSubmit)}>
                <Field label="Comments:" name="Comments" component={this.renderTextArea} />

                <div className="text-center">
                  <Button bsStyle="default" bsSize="small" type="submit">
                    Submit Comment
                  </Button>
                </div>
                <hr />
              </form>
              {!errorMessage ? (
                <div className="form-group row">
                  {[...pocomments].reverse().map(item => {
                    return (
                      <div key={item.CommentID} className="row">
                        <div className="col-sm-1">
                          <div className="thumbnail">
                            <img
                              className="img-responsive user-photo"
                              src="https://ssl.gstatic.com/accounts/ui/avatar_1x.png"
                              alt="user"
                            />
                          </div>
                        </div>
                        <div className="col-sm-10">
                          <div className="panel panel-default">
                            <div className="panel-heading">
                              <strong>{item.Owner} </strong>{' '}
                              <span className="text-muted">{moment(item.Cdate).format('MM/DD/YYYY h:mm a')}</span>
                            </div>
                            <div className="panel-body">{item.Comments}</div>
                          </div>
                        </div>
                        {accessLevel === 'OPERATOR' || accessLevel === 'CONTROL' ? (
                          <div className="col-sm-1">
                            <Button
                              bsStyle="danger"
                              bsSize="small"
                              type="button"
                              onClick={() => {
                                if (window.confirm('Delete the item?')) {
                                  this.deleteComment(`${item.CommentID}`);
                                }
                              }}
                            >
                              Delete
                            </Button>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    );
                  })}
                </div>
              ) : (
                ''
              )}
            </div>
          </fieldset>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    );
  }
}

function mapStateToProps({ POs, auth, util }) {
  return {
    pocomments: POs.POComment,
    user: auth.user,
    errorMessage: util.errors
  };
}

POComment = reduxForm({
  form: 'POComment',
  enableReinitialize: true
})(POComment);

POComment = withRouter(
  connect(
    mapStateToProps,
    actions
  )(POComment)
);
export default POComment;
