import axios from 'axios';
import 'font-awesome/css/font-awesome.min.css';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { ROOT_URL } from '../actions/index';
import '../css/summary.css';
import Spinner from './util/spinner';

class Summary extends Component {
  state = {
    data: [],
    pages: -1,
    isLoading: false
  };

  componentDidMount() {
    this.setState({ isLoading: true });
    // if (!this.props.location.search) {}
    axios.get(`${ROOT_URL}/api/booking${this.props.location.search || '?'}&page=1&pageSize=25`).then(res => {
      this.setState({
        data: this.mapRawData(res.data.result),
        pages: res.data.pages,
        isLoading: false
      });
    });
    // this.props.searchBookingByQuery(parsed);
  }

  mapRawData(data) {
    const searchResults = _.flatMap(data, item =>
      _(item.BkDetails)
        .map(v => ({
          BookingID: item.BookingID,
          ShipmentType: item.ShipmentType,
          bkStatus: this.checkBkStatus(item.bkStatus),
          ETD: item.ETD ? moment(item.ETD).format('MM/DD/YYYY') : '',
          ETAFirst: item.ETAFirst ? moment(item.ETAFirst).format('MM/DD/YYYY') : '',
          ETAFinal: item.ETAFinal ? moment(item.ETAFinal).format('MM/DD/YYYY') : '',
          OrgPort: item.OrgPort,
          FnlPort: item.FnlPort,
          CNeeName: item.CNeeName,
          Description: this.checkNull(v.CargoDescription),
          UpdateOn: item.UpdateOn ? moment(item.UpdateOn).format('MM/DD/YYYY') : '',
          VesselName: item.VesselName,
          SKUNo: this.checkNull(v.SKUNo),
          PONo: this.checkNull(v.PONo),
          CargoReadyDate: v.CargoReadyDate ? moment(v.CargoReadyDate).format('MM/DD/YYYY') : '',
          ContainerNo: this.checkNull(v.ContainerNo),
          MBL: this.checkNull(v.MBL),
          ShipperName: this.checkNull(v.ShipperName)
        }))
        .value()
    );

    return searchResults;
  }

  turnIdToLink(row) {
    const { value } = row;
    return <Link to={`/p/booking/${value}`}>{value}</Link>;
  }

  checkNull(value) {
    if (!value || value === '0') {
      return (value = 'N/A');
    } return value;
  }

  checkBkStatus(value) {
    if (!value) {
      return (value = 'BP');
    } return value;
  }

  renderAlert() {
    if (this.props.bookings.length === 0) {
      setTimeout(() => {
        this.props.dismissError();
      }, 5000);
      return (
        <div className="alert alert-danger">
          <strong>Oops!</strong> The booking is either archived or does not have a booking detail!
          <p>Try searching using the URL https://pom.otxusa.com/p/booking/[bookingNumber]</p>
        </div>
      );
    }
  }

  renderError() {
    if (this.props.errorMessage) {
      setTimeout(() => {
        this.props.dismissError();
      }, 5000);
      return (
        <div className="alert alert-danger">
          <strong>Oops!</strong> {this.props.errorMessage}
        </div>
      );
    }
  }

  render() {
    const { isLoading } = this.state;
    if (isLoading) {
      return <Spinner />;
    }

    return (
      <div>
        <h2 className="page-header booking-main-header">Search Results</h2>
        {/* {this.renderAlert()}
          {this.renderError()} */}
        <ReactTable
          columns={[
            {
              Header: 'Booking ID',
              accessor: 'BookingID',
              Cell: row => this.turnIdToLink(row)
            },
            {
              Header: 'PO #',
              accessor: 'PONo'
            },
            {
              Header: 'Description',
              accessor: 'Description'
            },
            {
              Header: 'Shipper Name',
              accessor: 'ShipperName'
            },
            {
              Header: 'Origin',
              accessor: 'OrgPort'
            },
            {
              Header: 'Destination',
              accessor: 'FnlPort'
            },
            {
              Header: 'Shipper Name',
              accessor: 'ShipperName'
            },
            {
              Header: 'Cargo Ready Date',
              accessor: 'CargoReadyDate'
            },
            {
              Header: 'Updated On',
              accessor: 'UpdateOn'
            },
            {
              Header: 'Vessel Name',
              accessor: 'VesselName'
            },
            {
              Header: 'Status',
              accessor: 'bkStatus'
            }
          ]}
          data={this.state.data}
          defaultPageSize={25}
          pages={this.state.pages}
          isLoading={this.state.isLoading}
          manual
          className="-striped -highlight"
          showPaginationTop
          showPaginationBottom={false}
          showPageSizeOptions={false}
          onFetchData={(state, instance) => {
            axios
              .get(
                `${ROOT_URL}/api/booking${this.props.location.search || '?'}&page=${state.page + 1}&pageSize=${
                  state.pageSize
                }`
              )
              .then(res => {
                this.setState({
                  data: this.mapRawData(res.data.result),
                  pages: res.data.pages,
                  isLoading: false
                });
              });
          }}
        />
        <div style={{ marginBottom: '100px' }}>
          <br />
        </div>
      </div>
    );
  }
}

export default Summary;
