import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';

class RenderAlert extends Component {
  render() {
    const { errorMessage } = this.props;
    if (errorMessage) {
      setTimeout(() => {
        this.props.dismissError();
      }, 8000);
      return (
        <div className="alert alert-danger">
          <strong>Oops!</strong> {errorMessage}
        </div>
      );
    } else return null;
  }
}

export default connect(
  null,
  actions
)(RenderAlert);
