import React, { Component } from 'react';

export default class RenderCargoSize extends Component {
  render() {
    const { label, input } = this.props;
    const sizeOption = [
      { value: '', display: '' },
      { value: '29', display: "20'" },
      { value: '30', display: "40'" },
      { value: '31', display: "40' HQ" },
      { value: '32', display: "45'" }
    ];
    return (
      <div className="form-group form-group-sm col-sm-6">
        <div className="row">
          <label className="col-sm-4 col-form-label">{label}</label>
          <div className="col-sm-7">
            <select className="form-control" {...input}>
              {sizeOption.map(choice => (
                <option value={choice.value} key={choice.value}>
                  {choice.display}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  }
}
