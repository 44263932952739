// @material-ui/icons
import withStyles from '@material-ui/core/styles/withStyles';
import MailOutline from '@material-ui/icons/MailOutline';
import React from 'react';
import { connect } from 'react-redux';
// import { Field, Form, Formik } from "formik";
import { Field, reduxForm } from 'redux-form';
import * as userActions from '../../actions/users';
import regularFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import Card from '../../components/Card/Card.jsx';
import CardBody from '../../components/Card/CardBody.jsx';
import CardHeader from '../../components/Card/CardHeader.jsx';
import CardIcon from '../../components/Card/CardIcon.jsx';
import Button from '../../components/CustomButtons/Button.jsx';
import CustomInput from '../../components/CustomInput/CustomInput.jsx';
import CustomerSuggest from '../customer_react_select';
// import { getProductInitialValues as getInitialValues } from "variables/getInitialValues";
class EditUserForm extends React.Component {
  render() {
    const { classes, initialValues, userLabels, onClose, title, handleSubmit, updateUser } = this.props;
    if (!initialValues) return <div>Loading...</div>;

    return (
      <Card>
        <CardHeader color="rose" icon>
          <CardIcon color="rose">
            <MailOutline />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>{title}</h4>
        </CardHeader>
        <CardBody>
          <form
            onSubmit={handleSubmit(values => {
              updateUser(values);
              onClose();
            })}
          >
            {userLabels.map((k, index) => (
              <Field
                key={index}
                type="text"
                name={k.name}
                component={({ input }) => (
                  <CustomInput
                    labelText={k.label}
                    labelProps={{
                      required: true
                    }}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: 'text',
                      ...input
                    }}
                  />
                )}
              />
            ))}
            {/* eslint-disable-next-line no-nested-ternary */}
            {initialValues.GroupType === 'CUSTOMER' ? (
              <Field
                type="text"
                name="CustomerID"
                component={({ input }) => (
                  <CustomerSuggest onChange={input.onChange} value={input.value} placeholder="select a customer..." />
                )}
              />
            ) : initialValues.GroupType === 'AGENT' ? (
              <Field
                type="text"
                name="CustomerID"
                component={({ input }) => (
                  <CustomerSuggest
                    onChange={input.onChange}
                    value={input.value}
                    allowMulti
                    placeholder="select one or more customer..."
                  />
                )}
              />
            ) : null}

            <Button color="rose" type="submit">
              Submit
            </Button>
          </form>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = ({ users }) => ({
  initialValues: users.userData,
  userLabels: users.userLabels
});

export default connect(
  mapStateToProps,
  userActions
)(
  reduxForm({
    form: 'editUser',
    enableReinitialize: true,
    destroyOnUnmount: false
  })(withStyles(regularFormsStyle)(EditUserForm))
);
