import { Collapse } from 'antd';
import 'antd/lib/collapse/style/index.css';
import RenderField from '../booking/renderField';
import React, { Component } from 'react';
import { Button, ButtonToolbar, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import * as actions from '../../actions';
import '../../css/booking.css';
import RenderCheckbox from '../booking/renderCheckbox';
import CommInvoice from '../booking/renderCommInvoice';
import RenderDateField from '../booking/renderDateField';
// import RenderContainer from '../booking/renderContainer';
import HBL from '../booking/renderHBL';
import MBL from '../booking/renderMBL';
import RenderTrucker from '../booking/renderTrucker';
import MoveBooking from '../util/moveBooking';
import Spinner from '../util/spinner';
import validate from '../util/validate';
import DetailModal from './createDetailModal';
import RenderCargoSize from './renderCargoSize';
import RenderCargoType from './renderCargoType';

class BookingOceanDetail extends Component {
  constructor(props) {
    super(props);
    this.onBkDetailSubmit = this.onBkDetailSubmit.bind(this);
    this.deleteDetail = this.deleteDetail.bind(this);
  }

  componentDidMount() {
    // console.log(this.props.match.params);
    const { BookingID } = this.props.match.params;
    this.props.fetchBookingDetail(BookingID);
  }

  onBkDetailSubmit(values) {
    const { BookingID } = this.props.match.params;
    this.props.updateBookingDetail(BookingID, values, () => {
      alert('Booking details updated');
      window.location.reload(true);
    });
  }

  deleteDetail(DetailID) {
    this.props.destoryDetail(DetailID, () => {
      alert('Booking Detail Deleted!');
      window.location.reload(true);
    });
  }

  renderField(field) {
    const { label, type, input } = field;
    return (
      <div className="form-group form-group-sm col-sm-6">
        <div className="row">
          <label className="col-sm-4 col-form-label">{label}</label>
          <div className="col-sm-7">
            <input className="form-control" type={type} {...input} />
          </div>
        </div>
      </div>
    );
  }

  renderCargo(field) {
    const { label, type, input, ...rest } = field;
    return (
      <div className="form-group form-group-sm col-sm-12">
        <div className="row">
          <div className="col-sm-12">
            <input className="form-control" type={type} {...input} {...rest} />
          </div>
        </div>
      </div>
    );
  }

  renderUpdatedMsg() {
    if (this.props.updatedBkDetail) {
      return (
        <div className="alert alert-success text-center">
          <strong>Successfully updated!</strong>
        </div>
      );
    }
  }

  renderMove(field) {
    const { label, type, input, ...rest } = field;
    return (
      <div className="form-group form-group-sm col-sm-6">
        <div className="row">
          <label className="col-sm-4 col-form-label">{label}</label>
          <div className="col-sm-7">
            <input className="form-control" type={type} {...input} {...rest} />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { booking, initialValues, handleSubmit, ShipmentType } = this.props;
    const { Panel } = Collapse;
    const customPanelStyle = {
      border: '1px solid #999',
      borderRadius: 4,
      borderColor: '#bce8f1',
      backgroundColor: '#f8fdfd',
      marginBottom: 12,
      overflow: 'hidden'
    };

    const approvalPending = {
      color: 'red',
      paddingBottom: '10px'
    };
    const approved = {
      color: 'green',
      paddingBottom: '10px'
    };
    const tempApproved = {
      color: 'orange',
      paddingBottom: '10px'
    };
    if (!booking || !initialValues) {
      return <Spinner />;
    }
    if (!booking.BkDetails) {
      return (
        <div>
          <DetailModal
            ShipmentType={ShipmentType}
            showCreateModal={this.props.showCreateModal}
            closeCreateModal={this.props.closeCreateModal}
          />
          <fieldset className="for-panel">
            <legend>BOOKING DETAILS</legend>
            <div className="page-header">
              <ButtonToolbar className="detail-submenu">
                <Button bsSize="small" bsStyle="default" onClick={this.props.openCreateModal}>
                  Add New Detail
                </Button>
              </ButtonToolbar>
            </div>
          </fieldset>
        </div>
      );
    }
    return (
      <div>
        <DetailModal
          ShipmentType={booking.ShipmentType}
          showCreateModal={this.props.showCreateModal}
          closeCreateModal={this.props.closeCreateModal}
        />
        {booking.BkDetails.map((item, index) => (
          <Collapse key={item.BkDetailID}>
            <Panel
              header={`Detail #${index + 1}, PO #: ${item.PONo ? item.PONo : 'N/A'}, Description: ${
                item.CargoDescription
              }`}
              key={item.BkDetailID}
              style={customPanelStyle}
            >
              {(() => {
                if (item.bkStatus === 'AP' || item.ApStatus1)
                  return (
                    <h4 style={approved}>
                      Approved
                      <i className="fa fa-check-square-o" style={{ marginLeft: '5px' }} aria-hidden="true" />
                    </h4>
                  );
                if (item.bkStatus === 'TEMP') return <h4 style={tempApproved}>Temp Approved</h4>;
                if (item.bkStatus === 'HOLD') return <h4 style={approvalPending}>HOLD</h4>;
                if (item.bkStatus === 'BR' && !item.ApStatus1)
                  return (
                    <h4 style={approvalPending}>
                      Approval Pending
                      <i className="fa fa-times-circle-o" style={{ marginLeft: '5px' }} aria-hidden="true" />
                    </h4>
                  );
                return null;
              })()}
              <div className="page-header">
                <ButtonToolbar className="detail-submenu">
                  <Button bsSize="small" bsStyle="default" onClick={this.props.openCreateModal}>
                    Add New Detail
                  </Button>
                  <Button
                    bsSize="small"
                    bsStyle="default"
                    onClick={() => {
                      if (window.confirm('Delete the item?')) {
                        this.deleteDetail(`${item.BkDetailID}`);
                      }
                    }}
                  >
                    Delete
                  </Button>
                </ButtonToolbar>
                <div className="row">
                  <MoveBooking BkDetailID={item.BkDetailID} details={item} />
                </div>
              </div>
              <form onSubmit={handleSubmit(this.onBkDetailSubmit)}>
                <div className="row">
                  <Field
                    label="Customer PO:"
                    type="text"
                    name={`BkDetails[${index}].csnPO`}
                    component={this.renderField}
                  />
                  <Field
                    label="Shipper Name:"
                    type="text"
                    name={`BkDetails[${index}].ShipperName`}
                    component={this.renderField}
                  />
                </div>
                <div className="row">
                  <Field
                    label="Container #:"
                    type="text"
                    name={`BkDetails[${index}].ContainerNo`}
                    component={this.renderField}
                  />
                  <Field
                    label="HBL #:"
                    hbl={item.HBL}
                    CNeeID={booking.CNeeID}
                    type="text"
                    name={`BkDetails[${index}].HBL`}
                    component={HBL}
                  />
                </div>
                <div className="row">
                  <Field
                    label="Vendor:"
                    type="text"
                    name={`BkDetails[${index}].publisher`}
                    component={this.renderField}
                  />
                  <Field
                    label="MBL #:"
                    mbl={item.MBL}
                    CNeeID={booking.CNeeID}
                    type="text"
                    name={`BkDetails[${index}].MBL`}
                    component={MBL}
                  />
                </div>
                <div className="row">
                  <Field label="Type:" type="text" name={`BkDetails[${index}].CargoType`} component={RenderCargoType} />
                  <Field label="Size:" type="text" name={`BkDetails[${index}].CargoSize`} component={RenderCargoSize} />
                </div>
                <div className="row">
                  <Field
                    label="Cargo Ready Date:"
                    type="text"
                    name={`BkDetails[${index}].CargoReadyDate`}
                    component={RenderDateField}
                  />
                  <Field
                    label="Cargo Rap Date:"
                    type="text"
                    name={`BkDetails[${index}].CargoRapDate`}
                    component={RenderDateField}
                  />
                </div>
                <div className="row">
                  <Field 
                    label="ISBN:" 
                    type="text" 
                    name={`BkDetails[${index}].ISBN`} 
                    component={RenderField}
                    disabled = {booking.CNeeID === 34067 ? true : false}
                    />
                  <Field
                    label="Description/Title:"
                    type="text"
                    name={`BkDetails[${index}].CargoDescription`}
                    component={RenderField}
                    disabled = {booking.CNeeID === 34067 ? true : false}
                  />
                </div>
                <div className="row">
                  <Field label="PO #:" type="text" name={`BkDetails[${index}].PONo`} component={this.renderField} />
                  <Field
                    label="Sales Order:"
                    type="text"
                    name={`BkDetails[${index}].saleOrder`}
                    component={this.renderField}
                  />
                </div>
                <div className="row">
                  <Field
                    label="PO Due Date #:"
                    type="text"
                    name={`BkDetails[${index}].CustomerInHandsDate`}
                    component={RenderDateField}
                  />
                  <Field
                    label="PO Total:"
                    type="text"
                    name={`BkDetails[${index}].POTotal`}
                    component={RenderField}
                    disabled = {booking.CNeeID === 34067 ? true : false}
                  />
                </div>
                <div className="row">
                  <Field
                    label="Unit Cost:"
                    type="text"
                    name={`BkDetails[${index}].unitCost`}
                    component={RenderField}
                    disabled = {booking.CNeeID === 34067 ? true : false}
                  />
                  <Field
                    label="Buyer Code:"
                    type="text"
                    name={`BkDetails[${index}].buyCode`}
                    component={RenderField}
                    disabled = {booking.CNeeID === 34067 ? true : false}
                  />
                </div>
                <div className="row">
                  <Field
                    label="SKU / ITEM #:"
                    type="text"
                    name={`BkDetails[${index}].SKUNo`}
                    component={this.renderField}
                  />
                  <Field
                    label="Delivery Addr:"
                    type="text"
                    name={`BkDetails[${index}].DelrAddr`}
                    component={this.renderField}
                  />
                </div>

                <div className="row">
                  <Field
                    label="Estimate Del. Date:"
                    type="text"
                    name={`BkDetails[${index}].EstDelivery`}
                    component={RenderDateField}
                  />
                  <Field
                    label="Actual Del. Date:"
                    type="text"
                    name={`BkDetails[${index}].ActDelivery`}
                    component={RenderDateField}
                  />
                </div>

                <div className="row">
                  <Field
                    label="Final Due Date:"
                    type="text"
                    name={`BkDetails[${index}].FinalDueDate`}
                    component={RenderDateField}
                  />
                  <Field
                    label="Received by:"
                    type="text"
                    name={`BkDetails[${index}].ReceivedBy`}
                    component={this.renderField}
                  />
                </div>
                <div className="row">
                  <Field label="ProNo #:" type="text" name={`BkDetails[${index}].ProNo`} component={this.renderField} />
                  <Field
                    label="Trucker Name:"
                    type="text"
                    name={`BkDetails[${index}].VendorURLID`}
                    component={RenderTrucker}
                  />
                </div>
                <div className="row">
                  <Field 
                    label="HTS #:" 
                    type="text" 
                    name={`BkDetails[${index}].HTSNo`} 
                    component={RenderField} 
                    disabled = {booking.CNeeID === 34067 ? true : false}  
                    />
                  <Field
                    label="Comm. Invoice No #:"
                    CNeeID={booking.CNeeID}
                    invoiceNo={item.CommInvoiceNo}
                    type="text"
                    name={`BkDetails[${index}].CommInvoiceNo`}
                    component={CommInvoice}
                  />
                </div>
                <div className="row">
                  <Field
                    label="Empty Cntr Returned Date:"
                    type="text"
                    name={`BkDetails.[${index}].emptyCntrReturnedOn`}
                    component={RenderDateField}
                  />
                </div>
                <div className="row">
                  <label className="col-sm-2" style={{ color: 'red' }}>
                    Missing Documents:
                  </label>
                  <Field
                    detailID={item.BkDetailID}
                    BookingID={this.props.match.params.BookingID}
                    label="HBL:"
                    type="checkbox"
                    docName="MissingDoc1"
                    name={`BkDetails[${index}].MissingDoc1`}
                    component={RenderCheckbox}
                  />
                  <Field
                    detailID={item.BkDetailID}
                    BookingID={this.props.match.params.BookingID}
                    label="Commerical Invoice And/Or Packing List:"
                    type="checkbox"
                    docName="MissingDoc2"
                    name={`BkDetails[${index}].MissingDoc2`}
                    component={RenderCheckbox}
                  />
                  <Field
                    detailID={item.BkDetailID}
                    BookingID={this.props.match.params.BookingID}
                    label="ORIGIN CHARGES NOT RECEIVED:"
                    type="checkbox"
                    docName="MissingDoc3"
                    name={`BkDetails[${index}].MissingDoc3`}
                    component={RenderCheckbox}
                  />
                </div>
                <Table responsive bordered condensed>
                  <thead>
                    <tr>
                      <th className="text-center col-sm-2 col-sm-offset-1">VOLUME :</th>
                      <th className="text-center col-sm-2">WEIGHT :</th>
                      <th className="text-center col-sm-2">QTY. [UNITS] :</th>
                      <th className="text-center col-sm-2">QTY. [CARTON] :</th>
                      <th className="text-center col-sm-2">QTY. [PALLET] :</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="">
                      <td>
                        <Field type="text" name={`BkDetails[${index}].CargoVol`} component={this.renderCargo} />
                      </td>
                      <td>
                        <Field type="text" name={`BkDetails[${index}].cargoWt`} component={this.renderCargo} />
                      </td>
                      <td>
                        <Field type="text" name={`BkDetails[${index}].CopyQty`} component={this.renderCargo} />
                      </td>
                      <td>
                        <Field type="text" name={`BkDetails[${index}].CartonQty`} component={this.renderCargo} />
                      </td>
                      <td>
                        <Field type="text" name={`BkDetails[${index}].PalletQty`} component={this.renderCargo} />
                      </td>
                    </tr>
                    {/* <tr>
                      <td colSpan="5">
                        Actual Commerical Value : {item.CopyQty} * {item.unitCost} = ${Number(
                          item.CopyQty * item.unitCost
                        ).toFixed(2)}
                      </td>
                    </tr> */}
                  </tbody>
                </Table>
                {this.renderUpdatedMsg()}
                <div className="row text-center">
                  <button type="submit" name={`submit${index}`} className="btn btn-default">
                    Update
                  </button>
                </div>
              </form>
            </Panel>
          </Collapse>
        ))}
      </div>
    );
  }
}

function mapStateToProps({ bookings, auth, util }) {
  return {
    booking: bookings.results,
    updatedBkDetail: bookings.updatedBkDetail,
    initialValues: bookings.results,
    showCreateModal: util.showCreateModal,
    user: auth.user
  };
}

BookingOceanDetail = reduxForm({
  validate,
  form: 'BookingOceanDetail',
  enableReinitialize: true
})(BookingOceanDetail);

BookingOceanDetail = withRouter(connect(mapStateToProps, actions)(BookingOceanDetail));
export default BookingOceanDetail;
