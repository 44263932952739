import React, { Component } from 'react';
// import DatePicker from 'react-datepicker';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import moment from 'moment';
// import momentLocaliser from 'react-widgets-moment-localizer';
import momentLocaliser from 'react-widgets-moment';
// import globalize from 'globalize';
// import globalizeLocalizer from 'react-widgets-globalize';

import 'react-widgets/dist/css/react-widgets.css';

moment.locale('en');
momentLocaliser(moment);

export default class RenderDateField extends Component {
  render() {
    const {
      label,
      input: { onChange, value },
      disabled,
      meta: { touched, error }
    } = this.props;
    // remember to change all date type to datetime2 in SQL
    return (
      <div className="form-group form-group-sm col-sm-6">
        <div className="row">
          <label className="col-sm-4 col-form-label">{label}</label>
          <div className="col-sm-7">
            <DateTimePicker
              onChange={onChange}
              disabled={disabled}
              time={false}
              format="MM/DD/YYYY"
              value={!value ? null : new Date(value)}
            />
            <div className="text-danger">{touched ? error : ''}</div>
          </div>
        </div>
      </div>
    );
  }
}
