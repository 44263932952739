import React, { Component } from 'react';
import Datatable from 'react-bs-datatable';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as actions from '../../actions';
import Spinner from '../util/spinner';
import ModePieChart from './PieChart_TransportMode';

class Dashboard extends Component {
  componentDidMount() {
    const {
      user: { CustomerID },
      fetchCustomerDashboard,
      getNumOfApproved,
      getNumOfDelivered,
      getNumOfShipped,
      getShipmentType
    } = this.props;

    fetchCustomerDashboard(CustomerID);
    getShipmentType(CustomerID);
    getNumOfShipped(CustomerID);
    getNumOfDelivered(CustomerID);
    getNumOfApproved(CustomerID);
  }

  render() {
    const { results, countShipmentType, numOfShipped, numOfDelivered, numOfApproved } = this.props;
    if (!results || !countShipmentType || !numOfShipped || !numOfDelivered || !numOfApproved) {
      return <Spinner />;
    }
    results.forEach(item => (item.BookingID = <Link to={`/p/booking/${item.BookingID}`}>{item.BookingID}</Link>));
    const header = [
      { title: 'ACK', prop: '', sortable: true },
      { title: 'Booking #', prop: 'BookingID', sortable: true },
      { title: 'PO', prop: 'PONo', sortable: true },
      { title: 'Shipper Name', prop: 'shipperName', sortable: true },
      { title: 'Cargo Ready Date', prop: 'CargoReadyDate', sortable: true },
      { title: 'Title/Description', prop: 'Title', sortable: true },
      { title: 'Comment', prop: 'Comments', sortable: true },
      { title: 'Status', prop: 'Status', sortable: true }
    ];
    return (
      <div className="col-sm-10 col-sm-offset-1 container-bottom">
        <h2 className="page-header">Dashboard</h2>
        <h4>Analytics for recent shipments:</h4>
        <div className="row">
          <div className="col-sm-6 pie-chart">
            <ModePieChart data={numOfShipped} />
          </div>
          <div className="col-sm-6 pie-chart">
            <ModePieChart data={numOfDelivered} />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 pie-chart">
            <ModePieChart data={numOfApproved} />
          </div>
          <div className="col-sm-6 pie-chart">
            <ModePieChart data={countShipmentType} />
          </div>
        </div>
        <Datatable
          id="reportTable"
          tableHeader={header}
          tableBody={results}
          keyName="userTable"
          tableClass="table-bordered table-striped table-condensed table-responsive"
          rowsPerPage={50}
          rowsPerPageOption={[10, 20, 50, 100]}
          initialSort={{ prop: 'BookingID', isAscending: false }}
        />
      </div>
    );
  }
}

function mapStateToProps({ bookings, auth }) {
  return {
    numOfShipped: bookings.numOfShipped,
    numOfDelivered: bookings.numOfDelivered,
    numOfApproved: bookings.numOfApproved,
    countShipmentType: bookings.countShipmentType,
    results: bookings.results,
    user: auth.user
  };
}
export default connect(
  mapStateToProps,
  actions
)(Dashboard);
