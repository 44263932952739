import moment from 'moment';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import * as actions from '../../actions';
import RenderDateField from '../booking/renderDateField';
import RenderField from '../booking/renderField';
import Spinner from '../util/spinner';
import validate from '../util/validate';

class POSearch extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }
  onSubmit(values) {
    const { accessLevel, AccessGroup, CustomerID } = this.props.user;
    if (values.CargoReadyDateFrom) {
      values.CargoReadyDateFrom = moment(values.CargoReadyDateFrom).format('MM/DD/YYYY');
    }
    if (values.CargoReadyDateTo) {
      values.CargoReadyDateTo = moment(values.CargoReadyDateTo).format('MM/DD/YYYY');
    }
    // check Agent group
    // if agent
    if (accessLevel === 'AGENT') {
      const { CustomerID } = this.props.user;
      if (AccessGroup === 'SAFEWORKS_BE') {
        values.VendorCountry = 'BE';
      }
      if (AccessGroup === 'SAFEWORKS_TW') {
        values.VendorCountry = 'TW';
      }
      if (AccessGroup === 'SAFEWORKS_DE') {
        values.VendorCountry = 'DE';
      }
      if (CustomerID.includes(',')) {
        values.CNeeID = CustomerID.split(', ');
      } else {
        values.CNeeID = CustomerID;
      }
    }
    // check customer
    if (accessLevel === 'CUSTOMER') {
      values.CNeeID = CustomerID;
    }

    if (this.props.user.accessLevel === 'OPERATOR' && this.props.user.CustomerID) {
      values.CNeeID = this.props.user.CustomerID.split(', ');
    }
    this.props.searchPO(values, () => {
      this.props.history.push(`/p/po/results`);
    });
  }
  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <strong>Oops!</strong> {this.props.errorMessage}
        </div>
      );
    }
  }

  render() {
    const { handleSubmit, isLoading } = this.props;
    if (isLoading) {
      return <Spinner />;
    }
    return (
      <div>
        <h2 className="text-center">Search PO</h2>
        {this.renderAlert()}
        <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <div className="row">
            <Field name="PONo" label="PO #" placeholder="PO #" component={RenderField} />
            <Field name="SKUNo" label="SKU #" placeholder="SKU #" component={RenderField} />
          </div>
          <div className="row">
            <Field
              label="Cargo Ready From:"
              placeholder="MM/DD/YYYY"
              name="CargoReadyDateFrom"
              component={RenderDateField}
            />
            <Field
              label="Cargo Ready To:"
              placeholder="MM/DD/YYYY"
              name="CargoReadyDateTo"
              component={RenderDateField}
            />
          </div>
          <div className="text-center" style={{ marginTop: '20px' }}>
            <Button bsStyle="default" disabled={isLoading} type="submit">
              {isLoading ? 'Loading...' : 'Search'}
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

function mapStateToProps({ util, POs, auth }) {
  return {
    errorMessage: util.errors,
    isLoading: util.isLoading,
    POResult: POs.POSearchResults,
    user: auth.user
  };
}

export default reduxForm({
  validate,
  form: 'POSearch',
  destroyOnUnmount: false
})(
  connect(
    mapStateToProps,
    actions
  )(POSearch)
);
