import React, { Component } from 'react';
import { Field } from 'redux-form';
import { ButtonToolbar } from 'react-bootstrap';
import RenderDate from './renderDateField_newDetail';
import '../../css/booking.css';
import 'font-awesome/css/font-awesome.min.css';

export default class BookingOceanDetailNew extends Component {
  renderField(field) {
    const {
      label,
      type,
      input,
      meta: { touched, error }
    } = field;
    return (
      <div className="form-group form-group-sm col-sm-3">
        <div className="row">
          <label className="col-sm-4 col-form-label">{label}</label>
          <div className="col-sm-8">
            <input className="form-control" type={type} {...input} />
            <div className="text-danger">{touched ? error : ''}</div>
          </div>
        </div>
      </div>
    );
  }
  renderCargoSize(field) {
    const { label, input } = field;
    const sizeOption = [
      { value: '', display: '' },
      { value: '29', display: "20'" },
      { value: '30', display: "40'" },
      { value: '31', display: "40' HQ" },
      { value: '32', display: "45'" }
    ];
    return (
      <div className="form-group form-group-sm col-sm-3">
        <div className="row">
          <label className="col-sm-4 col-form-label">{label}</label>
          <div className="col-sm-8">
            <select className="form-control" {...input}>
              {sizeOption.map(choice => (
                <option value={choice.value} key={choice.value}>
                  {choice.display}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  }
  renderCargoType(field) {
    const { label, input } = field;
    const typeOption = [
      { value: '', display: '' },
      { value: '33', display: 'CY/CY' },
      { value: '34', display: 'CFS/CFS' },
      { value: '688', display: 'CFS/CY' },
      { value: '35', display: 'CFS-HL' },
      { value: '243', display: 'RH' }
    ];
    return (
      <div className="form-group form-group-sm col-sm-3">
        <div className="row">
          <label className="col-sm-4 col-form-label">{label}</label>
          <div className="col-sm-8">
            <select className="form-control" {...input}>
              {typeOption.map(choice => (
                <option value={choice.value} key={choice.value}>
                  {choice.display}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  }
  render() {
    const {
      fields,
      meta: { error, submitFailed }
    } = this.props;
    return (
      <ul>
        <li>
          <div className="row add-detail-margin">
            <button type="button" className="add-detail btn btn-primary btn-sm" onClick={() => fields.push({})}>
              Add Detail
            </button>
            {submitFailed && error && <span>{error}</span>}
          </div>
        </li>
        {fields.map((detail, index) => (
          <li key={index}>
            <fieldset className="for-panel">
              <legend>Detail #{index + 1}</legend>
              <div className="row">
                <div className="col-sm-6" />
                <div className="col-sm-6">
                  <ButtonToolbar className="pull-right add-detail-margin">
                    <button type="button" className="btn btn-danger" onClick={() => fields.remove(index)}>
                      <i className="fa fa-trash-o" aria-hidden="true" />
                    </button>
                  </ButtonToolbar>
                </div>
              </div>
              <div className="row">
                <Field name={`${detail}.PONo`} type="text" component={this.renderField} label="PO #:" />
                <Field name={`${detail}.POTotal`} type="text" component={this.renderField} label="PO TTL QTY:" />
                <Field name={`${detail}.ShipperName`} type="text" component={this.renderField} label="Shipper Name:" />
                <Field name={`${detail}.ContainerNo`} type="text" component={this.renderField} label="Container #:" />
              </div>
              <div className="row">
                <Field name={`${detail}.buyCode`} type="text" component={this.renderField} label="Buyer Code:" />
                <Field name={`${detail}.HBL`} type="text" component={this.renderField} label="HBL:" />
                <Field name={`${detail}.publisher`} type="text" component={this.renderField} label="Vendor:" />
                <Field name={`${detail}.ISBN`} type="text" component={this.renderField} label="ISBN #:" />
              </div>
              <div className="row">
                <Field name={`${detail}.CarBooking`} type="text" component={this.renderField} label="C.Booking#:" />
                <Field name={`${detail}.csnPO`} type="text" component={this.renderField} label="Customer PO:" />
                <Field name={`${detail}.MBL`} type="text" component={this.renderField} label="MBL #:" />
                <Field name={`${detail}.saleOrder`} type="text" component={this.renderField} label="S.Order" />
              </div>
              <div className="row">
                <Field
                  name={`${detail}.CargoDescription`}
                  type="text"
                  component={this.renderField}
                  label="Description:"
                />
                <Field name={`${detail}.SKUNo`} type="text" component={this.renderField} label="SKU #:" />
                <Field name={`${detail}.DelrAddr`} type="text" component={this.renderField} label="Delr. Addr.:" />
                <Field name={`${detail}.HTSNo`} type="text" component={this.renderField} label="HTS #:" />
              </div>
              <div className="row">
                <Field name={`${detail}.FinalDueDate`} type="text" component={RenderDate} label="Final Due Date:" />
                <Field name={`${detail}.CargoReadyDate`} type="text" component={RenderDate} label="Cargo Ready Date:" />
                <Field name={`${detail}.CargoRapDate`} type="text" component={RenderDate} label="Cargo Rap Date:" />
                <Field name={`${detail}.EstDelivery`} type="text" component={RenderDate} label="Estimate Delr. Date:" />
              </div>
              <div className="row">
                <Field name={`${detail}.CargoType`} type="text" component={this.renderCargoType} label="Type:" />
                <Field name={`${detail}.CargoSize`} type="text" component={this.renderCargoSize} label="Size:" />
                <Field name={`${detail}.CargoVol`} type="text" component={this.renderField} label="Cargo Vol:" />
                <Field name={`${detail}.cargoWt`} type="text" component={this.renderField} label="Cargo Wt:" />
              </div>
              <div className="row">
                <Field name={`${detail}.CopyQty`} type="text" component={this.renderField} label="QTY.[copies]:" />
                <Field name={`${detail}.CartonQty`} type="text" component={this.renderField} label="QTY.[cartons]:" />
                <Field name={`${detail}.PalletQty`} type="text" component={this.renderField} label="QTY. [pallets]:" />
                <Field name={`${detail}.unitCost`} type="text" component={this.renderField} label="Unit Cost:" />
              </div>
            </fieldset>
          </li>
        ))}
      </ul>
    );
  }
}
