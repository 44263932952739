export const APPROVE_STATUS = 'approve_status'
export const APPROVE_ALL = 'APPROVE_ALL'
export const ALERT_MISSING_DOCS = 'ALERT_MISSING_DOCS '
export const AUTH_USER = 'auth_user'
export const UNAUTH_USER = 'unauth_user'
export const AUTH_ERROR = 'auth_error'
export const BOOKING_DELETED = 'BOOKING_DELETED'
export const BOOKING_FOR_NOTIFICATION = 'booking_for_notification'
export const CLONE_BOOKING_DETAILS = 'CLONE_BOOKING_DETAILS'
export const CREATE_BOOKING_HEADER = 'CREATE_BOOKING_HEADER'
export const CREATE_BOOKING_DETAIL = 'CREATE_BOOKING_DETAIL'
export const CREATE_COMMENTS = 'create_comments'
export const COUNT_SHIPMENT_TYPE = 'COUNT_SHIPMENT_TYPE'
export const COUNT_BOOKING_SHIPPED = 'COUNT_BOOKING_SHIPPED'
export const COUNT_BOOKING_DELIVERED = 'COUNT_BOOKING_DELIVERED'
export const COUNT_BOOKING_APPROVED = 'COUNT_BOOKING_APPROVED'
export const DELETE_COMMENTS = 'delete_comments'
export const DELETE_DETAILS = 'DELETE_DETAILS'
export const FETCH_ADDRESS_BOOK = 'FETCH_ADDRESS_BOOK'
export const FETCH_CONTAINERS = 'FETCH_CONTAINERS'
export const FETCH_CUSTOMER_DASHBOARD = 'FETCH_CUSTOMER_DASHBOARD,'
export const FETCH_PORTS = 'FETCH_PORTS'
export const FETCH_USER = 'fetch_user'
export const FETCH_REPORT = 'FETCH_REPORT'
export const FILE_ERROR = 'file_error'
export const GET_ALL_BOOKING = 'get_all_booking'
export const GET_BOOKING = 'get_booking'
export const GET_BOOKING_HEADER = 'get_booking_header'
export const GET_BOOKING_DETAIL = 'get_booking_detail'
export const GET_BOOKING_COMMENT = 'get_booking_comment'
export const GET_CUSTOMER_LIST = 'GET_CUSTOMER_LIST'
export const GET_TRUCKER_LIST = 'GET_TRUCKER_LIST'
export const GET_ALL_OPERATORS = 'GET_ALL_OPERATORS'
export const GET_CARRIER_LIST = 'GET_CARRIER_LIST'
export const IF_HBL_EXISTS = 'if_hbl_exists'
export const IF_MBL_EXISTS = 'IF_MBL_EXISTS,'
export const IF_COMM_INVOICE_EXISTS = 'IF_COMM_INVOICE_EXISTS'
export const IF_PACKINGLIST_EXISTS = 'IF_PACKINGLIST_EXISTS'
export const IS_LOADING = 'IS_LOADING'
export const TOGGLE_UPDATED = 'TOGGLE_UPDATED'
export const DOES_NOT_EXIST = 'does_not_exist'
export const MOVE_BOOKING = 'MOVE_BOOKING'
export const MOVE_PO_BOOKING = 'MOVE_PO_BOOKING'
export const GET_MSG_FROM_RADIO = 'GET_MSG_FROM_RADIO'
export const ADD_ERROR = 'ADD_ERROR'
export const DISMISS_ERROR = 'DISMISS_ERROR'
export const SEARCH_BOOKINGS = 'search_bookings'
export const SEARCH_PO = 'SEARCH_PO'
export const GET_PO_HEADER = 'GET_PO_HEADER'
export const GET_PO_COMMENT = 'GET_PO_COMMENT'
export const CREATE_PO_COMMENTS = 'CREATE_PO_COMMENTS'
export const DELETE_PO_COMMENT = 'DELETE_PO_COMMENT'
export const UPDATE_PO_HEADER = 'UPDATE_PO_HEADER'
export const SENT_MAIL = 'sent_mail'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const UPDATE_BOOKING_HEADER = 'update_booking_header'
export const UPDATE_BOOKING_DETAIL = 'update_booking_detail'
export const OPEN_MODAL = 'OPEN_MODAL'
export const OPEN_UPLOAD_TEMPLATE_MODAL = 'OPEN_UPLOAD_TEMPLATE_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
export const OPEN_CREATE_MODAL = 'OPEN_CREATE_MODAL'
export const CLOSE_CREATE_MODAL = 'CLOSE_CREATE_MODAL'
export const UPLOAD_COMPLETE = 'UPLOAD_COMPLETE'
export const UPLOAD_RESET = 'UPLOAD_RESET'
export const SHOW_ALERT = 'SHOW_ALERT'
export const HIDE_ALERT = 'HIDE_ALERT'
