import React from 'react';
// import logo from '../img/logo2.png';
import '../css/footer.css';

const Footer = () => (
  <footer id="myFooter">
    <div className="footer-copyright">
      <p>© 2018-2019 Copyright OTX Logistics Inc</p>
      <p>All rights reserved</p>
    </div>
  </footer>
);

export default Footer;
