import jwtDecode from 'jwt-decode'
import _ from 'lodash'
import moment from 'moment'
import {
  BOOKING_FOR_NOTIFICATION,
  CLONE_BOOKING_DETAILS,
  COUNT_BOOKING_APPROVED,
  COUNT_BOOKING_DELIVERED,
  COUNT_BOOKING_SHIPPED,
  COUNT_SHIPMENT_TYPE,
  CREATE_BOOKING_HEADER,
  CREATE_COMMENTS,
  DELETE_COMMENTS,
  FETCH_CONTAINERS,
  FETCH_CUSTOMER_DASHBOARD,
  FETCH_REPORT,
  GET_ALL_BOOKING,
  GET_BOOKING,
  GET_BOOKING_COMMENT,
  GET_BOOKING_DETAIL,
  GET_BOOKING_HEADER,
  GET_MSG_FROM_RADIO,
  GET_TRUCKER_LIST,
  SEARCH_BOOKINGS,
  UPDATE_BOOKING_DETAIL,
  // TOGGLE_UPDATED,
  UPDATE_BOOKING_HEADER,
} from '../actions/types'

function checkNull(value) {
  if (!value || value === '0') {
    return 'N/A'
  }
  return value
}
function checkBkStatus(value) {
  if (!value) {
    return 'BP'
  }
  return value
}

function determineCargoSize(size) {
  if (size === '29') return "20'"
  if (size === '30') return "40'"
  if (size === '31') return "40' HQ"
  if (size === '32') return "45'"
  return ''
}

const initialState = {
  bookingComment: [],
}
export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_BOOKING:
      return _.mapKeys(action.payload, 'BookingID')
    case GET_MSG_FROM_RADIO:
      // const messageList = action.messageList;
      const messageList = _.uniqBy(action.messageList, 'description')
      // const messageList = _.map(action.messageList, obj => _.assign(obj, _.find(b, {d})))
      // console.log(messageList);
      return {
        ...state,
        messageList,
      }
    case FETCH_REPORT:
      const results = _.flatMap(action.payload, item =>
        _(item.BkDetails)
          .map(v => ({
            BookingID: item.BookingID,
            ShipmentType: item.ShipmentType,
            CreatedBy: item.PrepareBy,
            bkStatus: checkBkStatus(item.bkStatus),
            CargoReadyDate: v.CargoReadyDate
              ? moment(v.CargoReadyDate).format('MM/DD/YYYY')
              : '',
            ETD: item.ETD ? moment(item.ETD).format('MM/DD/YYYY') : '',
            ETAFirst: item.ETAFirst
              ? moment(item.ETAFirst).format('MM/DD/YYYY')
              : '',
            ETAFinal: item.ETAFinal
              ? moment(item.ETAFinal).format('MM/DD/YYYY')
              : '',
            FinalDueDate: v.FinalDueDate
              ? moment(v.FinalDueDate).format('MM/DD/YYYY')
              : '',
            OrgPort: item.OrgPort,
            FnlPort: item.FnlPort,
            CNeeName: item.CNeeName,
            Description: v.CargoDescription,
            VesselName: item.VesselName,
            SKUNo: checkNull(v.SKUNo),
            PONo: checkNull(v.PONo),
            ContainerNo: checkNull(v.ContainerNo),
            MBL: checkNull(v.MBL),
            ShipperName: v.ShipperName,
            NumOfPallet: v.PalletQty,
            CargoVol: v.CargoVol,
            cargoWt: v.cargoWt,
            CopyQty: v.CopyQty,
            CartonQty: v.CartonQty,
            CWfile: item.CWFileNo,
            EmtyCntrReturned: v.emptyCntrReturnedOn,
            ActDelivery: v.ActDelivery,
            CargoRapDate: v.CargoRapDate,
          }))
          .value(),
      )

      return {
        ...state,
        results,
      }

    case CREATE_BOOKING_HEADER: {
      return {
        ...state,
        newBookingID: action.payload,
      }
    }

    case FETCH_CUSTOMER_DASHBOARD: {
      const results = action.payload.map(v => ({
        ApStatus1: v.ApStatus1,
        BookingID: v.BookingID,
        CNeeName: v.CNeeName,
        shipperName: checkNull(v.ShipperName),
        CType: v.CType,
        CargoReadyDate: v.CargoReadyDate
          ? moment(v.CargoReadyDate).format('MM/DD/YYYY')
          : '',
        CommentID: v.CommentID,
        Comments: v.Comments,
        MaxDAte: v.MaxDAte ? moment(v.MaxDAte).format('MM/DD/YYYY') : '',
        MissingDoc: v.MissingDoc,
        OrgPort: v.OrgPort,
        PONo: checkNull(v.PONo),
        Status: v.Status,
        Title: checkNull(v.CargoDescription),
        csnAkn: v.csnAkn,
      }))
      return {
        ...state,
        results,
      }
    }

    // case TOGGLE_UPDATED: {
    //   return {
    //     ...state,
    //     updated
    //   };
    // }

    case COUNT_SHIPMENT_TYPE: {
      return {
        ...state,
        countShipmentType: action.payload,
      }
    }
    case COUNT_BOOKING_SHIPPED: {
      return {
        ...state,
        numOfShipped: action.payload,
      }
    }
    case COUNT_BOOKING_DELIVERED: {
      return {
        ...state,
        numOfDelivered: action.payload,
      }
    }
    case COUNT_BOOKING_APPROVED: {
      return {
        ...state,
        numOfApproved: action.payload,
      }
    }
    case GET_BOOKING: {
      if (action.payload.data.BkDetails) {
        action.payload.data.BkDetails.forEach(detail => {
          if (detail.ApStatus1) {
            detail.ApStatus1 = detail.ApStatus1.substring(0, 1)
          }
        })
      }
      return {
        ...state,
        results: action.payload.data,
      }
    }
    case CLONE_BOOKING_DETAILS: {
      const newDetail = action.payload
      if (newDetail.ApStatus1) {
        newDetail.ApStatus1 = newDetail.ApStatus1.substring(0, 1)
      }

      const updatedDetails = [...state.results.BkDetails, newDetail]

      return {
        ...state,
        results: {
          ...state.results,
          BkDetails: updatedDetails,
        },
      }
    }

    case FETCH_CONTAINERS: {
      const containers = _.uniqBy(action.payload, 'ContainerNo')
      return {
        ...state,
        containers,
      }
    }
    case GET_BOOKING_HEADER: {
      return {
        ...state,
        bookingHeader: action.payload,
      }
    }
    case UPDATE_BOOKING_HEADER: {
      const bookingHeader = action.payload
      return {
        ...state,
        updatedBooking: true,
        bookingHeader,
      }
    }
    case GET_BOOKING_DETAIL: {
      return {
        ...state,
        bookingDetail: action.payload.data,
      }
    }

    case UPDATE_BOOKING_DETAIL: {
      if (action.payload.BkDetails) {
        action.payload.BkDetails.forEach(detail => {
          if (detail.ApStatus1) {
            detail.ApStatus1 = detail.ApStatus1.substring(0, 1)
          }
        })
      }
      return {
        ...state,
        results: action.payload,
        updatedBkDetail: true,
      }
    }
    case CREATE_COMMENTS: {
      return {
        ...state,
        bookingComment: [...state.bookingComment, action.payload],
      }
    }
    case DELETE_COMMENTS: {
      const newID = parseInt(action.id, 10)
      return {
        ...state,
        bookingComment: state.bookingComment.filter(
          comment => comment.CommentID !== newID,
        ),
      }
    }
    case GET_BOOKING_COMMENT: {
      return {
        ...state,
        bookingComment: action.payload.data,
      }
    }
    case GET_TRUCKER_LIST: {
      const defaultTrucker = {
        VendorURLID: '',
        VendorName: '',
      }
      const trucker = action.payload
      trucker.unshift(defaultTrucker)
      return { ...state, trucker }
    }
    case BOOKING_FOR_NOTIFICATION: {
      const notification = {}
      const { data } = action.payload
      const user = jwtDecode(localStorage.getItem('jwtToken'))
      // console.log(data);
      notification.consigneeID = data.CNeeID
      notification.Agent = data.Agent
      notification.CNeeName = data.CNeeName
      notification.from = user.email
      notification.subject = `PO# ${data.BkDetails[0].PONo} *||* ${data.BkDetails[0].CargoDescription}`
      notification.message = `New Booking Information for your review\n
Booking No. ${data.BookingID}\nPLEASE LOG IN TO OTX PO MANAGEMENT FOR MORE DETAILS\nhttps://pom.otxusa.com\n`
      notification.message += `-------------------------------------------------------------------\n\n`
      notification.message += `COMMENTS:\n------------------\n`
      if (data.BkComments === undefined) {
        notification.message += `NO COMMENTS AVAILABLE\n`
      } else {
        let comments = data.BkComments.reverse().map(
          (item, key) =>
            `DATE: ${moment(item.Cdate).format('MM/DD/YYYY')}\t\t\t${
              item.Owner
            }\nCOMMENT:${
              item.Comments
            }\n------------------------------------------------------\n`,
        )
        // console.log(notification.message);
        comments = comments.join('')
        notification.message += comments
      }
      notification.message += `-------------------------------------------------------------------\n`
      notification.message += `Booking #: ${data.BookingID}\t\t\tDate: ${moment(
        data.createDate,
      ).format('MM/DD/YYYY')}\n\nConsignee: ${data.CNeeName}\n\nCarrier: ${
        data.CarrierName
      }\n\nMother Vessel/Voy: ${data.VesselName}\n\nOriginal Port: ${
        data.OrgPort
      }\t\t\tETD: ${moment(data.ETD).format('MM/DD/YYYY')}\n\nDischarge Port: ${
        data.DstPort
      }\t\t\tETA: ${moment(data.ETAFirst).format(
        'MM/DD/YYYY',
      )}\n\nDestination Port: ${data.FnlPort}\t\t\tETA Final: ${moment(
        data.ETAFinal,
      ).format('MM/DD/YYYY')}\n\nShipment buying Terms: ${
        data.buyTerm
      }\n\nDocument CutOff Date: ${moment(data.DocCutOff).format(
        'MM/DD/YYYY',
      )}\n\nCargo CutOff Date: ${moment(data.CargoCutOff).format(
        'MM/DD/YYYY',
      )}\n\nDocument Send Date: ${
        data.docSend ? moment(data.docSend).format('MM/DD/YYYY') : ''
      }`
      notification.message += `\n\n+++++++++++++++++++++++++++++++++++++++++++++++++++++++\n\n`
      let details = data.BkDetails.map(
        (item, key) =>
          `INVOICE: ${item.PACInv}\n\nPO # ${item.PONo}\n\nContainer #: ${
            item.ContainerNo
          }\n\nHBL #: ${item.HBL}\n\nMBL #: ${item.MBL}\n\nISBN #: ${
            item.ISBN
          }\n\nShipper: ${item.ShipperName}\n\nCargo Ready Date: ${moment(
            item.CargoReadyDate,
          ).format('MM/DD/YYYY')}\n\nPublisher: ${
            item.PublisherContact
          }\n\nDescription: ${
            item.CargoDescription
          }\n\nCargo Type:\t\tCargo Size: ${determineCargoSize(item.CargoSize)}
          \n\nVolume: ${item.CargoVol}\t\tWeight: ${item.cargoWt}
          \n\nQty. [copies]: ${item.CopyQty}
          \n\nQty. [cartons]: ${item.CartonQty}
          \n\nQty. [pallets]: ${item.PalletQty}
          \n\n-----------------------------------------\n\n`,
      )
      details = details.join('')
      notification.message += details

      return {
        ...state,
        notification,
      }
    }

    case SEARCH_BOOKINGS: {
      const searchResults = _.flatMap(action.payload, item =>
        _(item.BkDetails)
          .map(v => ({
            BookingID: item.BookingID,
            ShipmentType: item.ShipmentType,
            bkStatus: checkBkStatus(item.bkStatus),
            ETD: item.ETD ? moment(item.ETD).format('MM/DD/YYYY') : '',
            ETAFirst: item.ETAFirst
              ? moment(item.ETAFirst).format('MM/DD/YYYY')
              : '',
            ETAFinal: item.ETAFinal
              ? moment(item.ETAFinal).format('MM/DD/YYYY')
              : '',
            OrgPort: item.OrgPort,
            FnlPort: item.FnlPort,
            CNeeName: item.CNeeName,
            Description: checkNull(v.CargoDescription),
            UpdateOn: item.UpdateOn
              ? moment(item.UpdateOn).format('MM/DD/YYYY')
              : '',
            VesselName: item.VesselName,
            SKUNo: checkNull(v.SKUNo),
            PONo: checkNull(v.PONo),
            CargoReadyDate: v.CargoReadyDate
              ? moment(v.CargoReadyDate).format('MM/DD/YYYY')
              : '',
            ContainerNo: checkNull(v.ContainerNo),
            MBL: checkNull(v.MBL),
            ShipperName: checkNull(v.ShipperName),
          }))
          .value(),
      )

      return {
        ...state,
        searchResults,
      }
    }

    default:
      return state
  }
}
