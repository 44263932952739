import axios from 'axios';
import omit from 'lodash/omit';
// import { addError } from '../actions/index';
import { addError, ROOT_URL } from './index';
import { setIsLoading } from './utils';

export const createUser = (values, cb) => async dispatch => {
  let user = {};
  Object.keys(values).forEach(i => {
    user = { ...user, ...values[i] };
  });
  try {
    dispatch(setIsLoading(true));
    dispatch({ type: 'CREATE_USER_STATUS', payload: '' });
    const response = await axios.post(`${ROOT_URL}/api/users`, user);
    dispatch(setIsLoading(false));

    if (response.data.ok) {
      const returnedUser = omit(response.data.user, ['Password', 'GroupType', 'station']);
      dispatch({ type: 'CREATE_USER_STATUS', payload: 'success' });
      dispatch({ type: 'CREATE_USER', payload: returnedUser });
      cb();
    }
  } catch (error) {
    dispatch(setIsLoading(false));
    dispatch(addError('User creation failed'));
  }
};

export const listUsers = () => async dispatch => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(`${ROOT_URL}/api/users`);
    dispatch(setIsLoading(false));
    if (response.data.ok) {
      dispatch({ type: 'LIST_USERS', payload: response.data.users });
    }
  } catch (err) {
    dispatch(setIsLoading(false));
    // show error reason from response.erro
  }
};

export const setEditingUser = user => async dispatch => {
  try {
    if (user.CustomerID) {
      const customers = user.CustomerID.split(',').map(customer => customer.trim());
      dispatch(setIsLoading(true));
      const res = await axios.post(`${ROOT_URL}/api/customer`, customers);
      dispatch(setIsLoading(false));
      if (res.data) {
        const linkedCustomers = res.data.map(({ customerNo, companyName }) => ({
          value: customerNo,
          label: `[${customerNo}] ${companyName}`
        }));
        dispatch({
          type: 'SET_EDIT_USER',
          payload: { ...user, CustomerID: linkedCustomers }
        });
      }
    } else {
      dispatch({
        type: 'SET_EDIT_USER',
        payload: { ...user }
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateUser = values => async dispatch => {
  try {
    if (values.CustomerID) {
      values.CustomerID = values.CustomerID.map(customer => customer.value).join(', ');
    }
    const updateUserValues = omit(values, ['Status', 'EmployeeNo']);
    dispatch(setIsLoading(true));
    dispatch({ type: 'EDIT_USER_STATUS', payload: '' });
    const res = await axios.patch(`${ROOT_URL}/api/users/${values.EmployeeNo}`, updateUserValues);
    dispatch(setIsLoading(false));
    if (res.data.ok) {
      dispatch({ type: 'EDIT_USER_STATUS', payload: 'success' });
      dispatch({ type: 'EDIT_USER', payload: { user: values } });
    }
  } catch (error) {}
};

export const toggleUser = ({ status, id }) => async dispatch => {
  try {
    dispatch(setIsLoading(true));
    const res = await axios.patch(`${ROOT_URL}/api/users/${id}/toggleUser`, { status });
    dispatch(setIsLoading(false));
    if (res.data.ok) {
      dispatch({ type: 'TOGGLE_USER_STATUS', payload: { status, id } });
    }
  } catch (err) {
    dispatch(setIsLoading(false));
  }
};

export const resetPassword = ({ password, id }, cb) => async dispatch => {
  try {
    dispatch(setIsLoading(true));
    dispatch({ type: 'RESET_PASSWORD', payload: { msg: '' } });
    const response = await axios.patch(`${ROOT_URL}/api/users/${id}/reset`, { password });
    dispatch(setIsLoading(false));
    if (response.data.ok) {
      dispatch({ type: 'RESET_PASSWORD', payload: { msg: 'success' } });
      cb();
    }
  } catch (err) {
    dispatch(setIsLoading(false));
    dispatch({ type: 'PASSWORD_ERROR', payload: err.response.data.errors });
  }
};
