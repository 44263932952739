// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';
// react component used to create sweet alerts
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import * as actions from '../../actions/utils';
import sweetAlertStyle from '../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx';

class SweetAlertPage extends React.Component {
  constructor(props) {
    super(props);
    const { title, classes, type } = this.props;
    this.state = {
      alert: (
        <SweetAlert
          type={type}
          style={{ display: 'block' }}
          title={title}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={`${classes.button} ${classes.success}`}
        />
      )
    };
    this.hideAlert = this.hideAlert.bind(this);
  }

  hideAlert() {
    const { resetUpload, hideAlert } = this.props;
    if (resetUpload && typeof resetUpload === 'function') {
      resetUpload();
    }
    if (hideAlert && typeof hideAlert === 'function') {
      hideAlert();
    }
    this.setState({
      alert: null
    });
  }

  render() {
    const { alert } = this.state;
    return <div>{alert}</div>;
  }
}

export default connect(null, actions)(withStyles(sweetAlertStyle)(SweetAlertPage));
