import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import S3Uploader from './util/s3_uploader';

function UploadModal(props) {
  const { showModal, closeModal, url } = props;
  // console.log(CNeeID);
  return (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Upload files here</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Guide to successful uploading:</h4>
        <p>Drag & drop to the square below or click it to begin upload</p>
        <ul>
          <li>The uploaded filename must match the document </li>
          <li>
            For example, if you are uploading an HBL file, if HBL# is ABCD12345, then your file name must also be
            ABCD12345.pdf
          </li>
          <li>
            COMMERCIAL INVOICE accepts PDF at this time but can be changed to accept Excel spreadsheet upon request.
          </li>
          <li style={{ color: 'red' }}>WARNING: Upload only supports PDFs for HBL, MBL. File size limit is 15MB. </li>
          <li style={{ color: 'blue' }}>
            For requests on uploading other types of documents, please contact your OTX representative
          </li>
        </ul>

        <S3Uploader url={url} close={closeModal} type="document" />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={closeModal}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default UploadModal;
