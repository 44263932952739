import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form'
import * as actions from '../../actions'
import { ADD_ERROR } from '../../actions/types'
import RenderDateField from '../booking/renderDateField'
import RenderField from '../booking/renderField'
import RenderStatusField from '../booking/renderStatusField'
import validate from '../util/validate'
import RenderBuyTerms from './renderBuyTerms'
import RenderCarrier from './renderCarrier'
import RenderReactSelect from '../booking/RenderReactSelect'
import { isEmpty } from 'lodash'
import Spinner from '../util/spinner'

class BookingOceanNew extends Component {
  constructor(props) {
    super(props)
    this.onBookingSubmit = this.onBookingSubmit.bind(this)
  }

  componentDidMount() {
    // check if accessLevel is agent, else
    // show error, access is denied.
    const { accessLevel } = this.props.user
    if (accessLevel !== 'AGENT') {
      this.props.history.push('/p/search')
      this.props.dispatch(this.addError('ACCESS DENIED'))
    } else {
      this.props.fetchPorts()
    }
  }

  addError(error) {
    return {
      type: ADD_ERROR,
      payload: error,
    }
  }

  onBookingSubmit(values) {
    const { customer, user, ShipmentType, CNeeID, station } = this.props
    values.PrepareBy = user.username
    values.Agent = user.id
    values.ShipmentType = ShipmentType
    values.CNeeID = CNeeID
    values.station = station
    customer.forEach(item => {
      if (item.customerNo.toString() === values.CNeeID) {
        values.CNeeName = item.companyName
      }
    })
    // console.log(values);
    this.props.createBookingHeader(values, () => {
      const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
      sleep(1000).then(() =>
        this.props.history.push(
          `/p/booking/${this.props.newBookingID.BookingID}`,
        ),
      )
      // this.props.history.push(`/booking/${this.props.newBookingID.BookingID}`);
    })
  }

  render() {
    const { handleSubmit, ports } = this.props
    if (isEmpty(ports)) {
      return <Spinner />
    }

    return (
      <form onSubmit={handleSubmit(this.onBookingSubmit)}>
        <div className="row">
          <Field
            label="Carrier Name:"
            name="CarrierName"
            component={RenderCarrier}
          />
          <Field
            label="Booking Status:"
            name="bkStatus"
            component={RenderStatusField}
          />
        </div>
        <div className="row">
          <Field
            label="Mother Vessel:"
            type="text"
            name="VesselName"
            component={RenderField}
          />
          <Field
            label="Feeder Vessel/Voy:"
            type="text"
            name="FeedVessel"
            component={RenderField}
          />
        </div>
        <div className="row">
          <Field
            label="Shpmt. buying terms:"
            type="text"
            name="buyTerm"
            component={RenderBuyTerms}
          />
          <Field
            label="Voy:"
            type="text"
            name="VoyNo"
            component={RenderField}
          />
        </div>
        <div className="row">
          <Field
            label="Original Port:"
            type="text"
            name="OrgPort"
            data={ports.orgPorts}
            component={RenderReactSelect}
          />
          <Field
            label="ETD:"
            type="text"
            name="ETD"
            component={RenderDateField}
          />
        </div>
        <div className="row">
          <Field
            label="Discharge Port:"
            type="text"
            name="DstPort"
            data={ports.dstPorts}
            component={RenderReactSelect}
          />
          <Field
            label="(1st Port) ETA:"
            type="text"
            name="ETAFirst"
            component={RenderDateField}
          />
        </div>
        <div className="row">
          <Field
            label="Final Destination:"
            type="text"
            name="FnlPort"
            data={ports.fnlPorts}
            component={RenderReactSelect}
          />
          <Field
            label="(Final port) ETA:"
            type="text"
            name="ETAFinal"
            component={RenderDateField}
          />
        </div>
        <div className="row">
          <Field
            label="Document Cutoff Date:"
            type="text"
            name="DocCutOff"
            component={RenderDateField}
          />
          <Field
            label="Cargo Cutoff Date:"
            type="text"
            name="CargoCutOff"
            component={RenderDateField}
          />
        </div>
        <div className="row text-center">
          <button
            type="submit"
            name="submitBooking"
            className="btn btn-default"
          >
            Create
          </button>
        </div>
        <hr />
      </form>
    )
  }
}

function mapStateToProps({ util, bookings, auth, ports }) {
  return {
    customer: util.customer,
    user: auth.user,
    newBookingID: bookings.newBookingID,
    ports,
  }
}

BookingOceanNew = reduxForm({
  form: 'BookingOceanNew',
  enableReinitialize: true,
  validate,
})(BookingOceanNew)

BookingOceanNew = withRouter(connect(mapStateToProps, actions)(BookingOceanNew))
export default BookingOceanNew
