import Icon from '@material-ui/core/Icon';
import InputAdornment from '@material-ui/core/InputAdornment';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Face from '@material-ui/icons/Face';
import axios from 'axios';
import React from 'react';
import { ROOT_URL } from '../../actions/index';
import customSelectStyle from '../../assets/jss/material-dashboard-pro-react/customSelectStyle.jsx';
// core components
import CustomInput from '../CustomInput/CustomInput.jsx';
import GridContainer from '../Grid/GridContainer.jsx';
import GridItem from '../Grid/GridItem.jsx';

const style = {
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center'
  },
  ...customSelectStyle
};

class Step3 extends React.Component {
  state = {
    simpleSelect: '',
    username: '',
    usernameState: '',
    Password: '',
    passwordState: '',
    confirmPassword: '',
    confirmPasswordState: '',
    city: ''
  };

  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  compare = (string1, string2) => {
    if (string1 === string2) {
      return true;
    }
    return false;
  };

  verifyComplexity = value => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/.test(value);

  checkUniqueUsername = async username => {
    const res = await axios.post(`${ROOT_URL}/api/users/checkUnique`, { username });
    if (res.data.ok === true) {
      this.setState({ isUserUnique: true });
    } else {
      this.setState({ isUserUnique: false });
    }
  };

  sendState = () => this.state;

  isValidated = () => {
    const { usernameState, passwordState, confirmPasswordState } = this.state;
    if (usernameState === 'success' && passwordState === 'success' && confirmPasswordState === 'success') {
      return true;
    }
    if (usernameState !== 'success') {
      this.setState({ usernameState: 'error' });
    }
    if (passwordState !== 'success') {
      this.setState({ passwordState: 'error' });
    }
    if (confirmPasswordState !== 'success') {
      this.setState({ confirmPasswordState: 'error' });
    }

    return false;
  };

  handleBlur = async (event, stateName) => {
    if (event.target.value) {
      const res = await axios.post(`${ROOT_URL}/api/users/checkUnique`, { username: event.target.value });
      if (res.data.ok === true) {
        this.setState({ isUserUnique: true });
        this.setState({ [`${stateName}State`]: 'success' });
      } else {
        this.setState({ isUserUnique: false });
        this.setState({ [`${stateName}State`]: 'error' });
      }
    } else return null;
  };

  change = (event, stateName, type, stateNameEqualTo) => {
    switch (type) {
      case 'username':
        this.setState({ username: event.target.value });
        if (this.verifyComplexity(event.target.value)) {
          this.setState({ [`${stateName}State`]: 'success' });
        } else {
          this.setState({ [`${stateName}State`]: 'error' });
        }
        break;
      case 'password':
        this.setState({ Password: event.target.value });
        if (this.verifyComplexity(event.target.value)) {
          this.setState({ [`${stateName}State`]: 'success' });
        } else {
          this.setState({ [`${stateName}State`]: 'error' });
        }
        break;
      case 'equalTo':
        this.setState({ confirmPassword: event.target.value });
        if (this.verifyComplexity(event.target.value)) {
          if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
            this.setState({ [`${stateName}State`]: 'success' });
          } else {
            this.setState({ [`${stateName}State`]: 'error' });
          }
        } else {
          this.setState({ [`${stateName}State`]: 'error' });
        }
        break;
      default:
        break;
    }
  };

  render() {
    const { classes } = this.props;
    const { usernameState, passwordState, confirmPasswordState } = this.state;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8} lg={8}>
          <h4 className={classes.infoText}>User Credentials</h4>
          <h6 className={classes.infoText}>
            Password must contain at least 6 character, at least 1 uppercase, 1 lowercase, and 1 number
          </h6>
        </GridItem>
        <GridItem xs={12} sm={12} md={8} lg={8}>
          <CustomInput
            success={usernameState === 'success'}
            error={usernameState === 'error'}
            labelText="Username"
            id="username"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              autoComplete: 'off',
              onChange: event => this.change(event, 'username', 'username'),
              onBlur: event => this.handleBlur(event, 'username'),
              endAdornment: (
                <InputAdornment position="end" className={classes.inputAdornment}>
                  <Face className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={8} lg={8}>
          <CustomInput
            success={passwordState === 'success'}
            error={passwordState === 'error'}
            labelText="password"
            id="password"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              autoComplete: 'off',
              type: 'password',
              onChange: event => this.change(event, 'password', 'password'),
              endAdornment: (
                <InputAdornment position="end">
                  <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                </InputAdornment>
              )
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={8} lg={8}>
          <CustomInput
            success={confirmPasswordState === 'success'}
            error={confirmPasswordState === 'error'}
            labelText="confirm password"
            id="confirmPassword"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              autoComplete: 'off',
              type: 'password',
              onChange: event => this.change(event, 'confirmPassword', 'equalTo', 'Password'),
              endAdornment: (
                <InputAdornment position="end">
                  <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                </InputAdornment>
              )
            }}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(style)(Step3);
