import { AUTH_ERROR, SET_CURRENT_USER, UNAUTH_USER } from '../actions/types';

const DEFAULT_STATE = {
  isAuthenticated: false,
  user: {}
};

export default function(state = DEFAULT_STATE, action) {
  // console.log(action.payload);
  switch (action.type) {
    // case AUTH_USER:
    //   console.log(`auth user: ${user}`);
    //   return { ...state, authenticated: true, user };
    case SET_CURRENT_USER:
      return {
        // turn an empty object into false or an object with keys to be true
        isAuthenticated: !!Object.keys(action.user).length,
        user: action.user
      };
    case UNAUTH_USER:
      localStorage.removeItem('jwtToken');
      return { ...state, isAuthenticated: false, user: {} };
    case AUTH_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
}
