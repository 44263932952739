import moment from 'moment'
import React, { Component } from 'react'
import { Button, ButtonToolbar } from 'react-bootstrap'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Scrollchor from 'react-scrollchor'
import { Field, reduxForm } from 'redux-form'
import * as actions from '../../actions'
import CustomSweetAlert from '../Alerts/SweetAlert'
import UploadModal from '../renderUploadModal'
import RenderHandler from '../util/RenderHandler'
import Spinner from '../util/spinner'
import validate from '../util/validate'
import RenderDateField from './renderDateField'
import RenderField from './renderField'
import RenderStation from './RenderStation'
import RenderStatusField from './renderStatusField'
import RenderUrgent from './RenderUrgent'
import RenderReactSelect from './RenderReactSelect'
import { isEmpty } from 'lodash'

class BookingOceanHeader extends Component {
  constructor(props) {
    super(props)
    this.onBookingSubmit = this.onBookingSubmit.bind(this)
    this.state = {
      updateMessage: '',
    }
  }

  componentDidMount() {
    const {
      addError,
      booking: { CNeeID },
      fetchBookingHeader,
      fetchPorts,
      history,
      match: {
        params: { BookingID },
      },
      user: { accessLevel, CustomerID },
    } = this.props

    fetchBookingHeader(BookingID)
    fetchPorts()
    // check if the operation account has a customer ID,
    // meaning that this operator account is only able to view these customers' bookings
    if (CustomerID && accessLevel === 'OPERATOR') {
      const customerList = CustomerID.split(', ')
      const validCustomerID = customerList
        .filter(id => id === CNeeID.toString())
        .toString()
      if (!validCustomerID) {
        history.push('/p/search')
        addError('ACCESS DENIED')
      }
    }
  }

  onBookingSubmit(values) {
    console.log('values: ', values)
    const { BookingID } = this.props.match.params
    const { user } = this.props
    values.UpdateBy = user.username
    values.UpdateOn = Date.now()
    this.props.updateBookingHeader(BookingID, values)
  }

  renderUpdatedMsg() {
    let updated = this.props.updatedBooking
    if (updated) {
      const msg = 'Successfully updated!'

      // needs additional work to get below working
      setTimeout(() => (updated = false), 5000)
      return (
        <div className="alert alert-success text-center">
          <strong>{msg}</strong>
        </div>
      )
    }
  }

  deleteBooking(id) {
    this.props.deleteBooking(id, () => {
      this.props.history.push(`/p/search`)
    })
  }

  render() {
    const { booking, initialValues, handleSubmit, uploaded, ports } = this.props
    if (!booking || !initialValues || isEmpty(ports)) {
      return <Spinner />
    }

    const { dstPorts, fnlPorts, orgPorts } = ports

    return (
      <div>
        <fieldset className="for-panel" id="header">
          <ButtonToolbar>
            <Button bsSize="small">
              <Scrollchor to="#comments" style={{ color: 'inherit' }}>
                Comments
              </Scrollchor>
            </Button>
            <Button
              bsSize="small"
              onClick={() => {
                if (window.confirm(`Are you sure to delete this booking?`)) {
                  this.deleteBooking(booking.BookingID)
                }
              }}
            >
              Delete
            </Button>
          </ButtonToolbar>

          <div className="row">
            <div className="col-sm-6">
              <strong>Created By:</strong>
              <span style={{ marginLeft: '40px' }}>{booking.PrepareBy}</span>
            </div>
            <div className="col-sm-6">
              <ButtonToolbar className="pull-right button-lineup">
                <Button
                  bsSize="small"
                  bsStyle="default"
                  onClick={this.props.openModal}
                >
                  Upload
                </Button>
                <Button
                  bsSize="small"
                  bsStyle="default"
                  href={`/p/sendmail/${booking.BookingID}`}
                >
                  Send Notification
                </Button>
              </ButtonToolbar>
            </div>
            <UploadModal
              url={`sfo_uploads/${booking.CNeeID}`}
              showModal={this.props.showModal}
              closeModal={this.props.closeModal}
            />
            {uploaded && uploaded.status === 'ok' ? (
              <CustomSweetAlert type="success" title={uploaded.message} />
            ) : null}
          </div>
          <div style={{ marginTop: '15px' }} />
          <div className="row">
            <div className="col-sm-6 ">
              <strong>
                BOOKING{' '}
                <span style={{ color: 'red' }}>({booking.ShipmentType})</span> #{' '}
              </strong>
              {booking.BookingID} -- created on{' '}
              {moment(booking.createDate).format('MM/DD/YYYY hh:mm A')}
            </div>
            <div className="col-sm-6 pull-right button-lineup">
              <strong>Consignee:</strong> [{booking.CNeeID}] {booking.CNeeName}
            </div>
          </div>
          <div style={{ marginTop: '15px' }} />

          <div className="row">
            <Field
              label="Station:"
              name="station"
              component={RenderStation}
              disabled
            />
          </div>

          <form onSubmit={handleSubmit(this.onBookingSubmit)}>
            <div className="row">
              {booking.bookingHandlerId === '0' ||
              booking.bookingHandlerId === null ? (
                <Field
                  label="Handler:"
                  station={booking.station}
                  name="bookingHandlerId"
                  component={RenderHandler}
                />
              ) : (
                <Field
                  label="Handler:"
                  station={booking.station}
                  name="bookingHandlerId"
                  component={RenderHandler}
                  disabled
                />
              )}
              <Field label="Urgent?" name="Urgent" component={RenderUrgent} />
            </div>

            <hr />
            <div className="row">
              <Field
                label="Carrier Name:"
                name="CarrierName"
                component={RenderField}
                disabled
              />
              <Field
                label="Booking Status:"
                name="bkStatus"
                component={RenderStatusField}
              />
            </div>
            <div className="row">
              <Field
                label="Mother Vessel:"
                type="text"
                name="VesselName"
                component={RenderField}
              />
              <Field
                label="Feeder Vessel/Voy:"
                type="text"
                name="FeedVessel"
                component={RenderField}
              />
            </div>
            <div className="row">
              <Field
                label="Voy:"
                type="text"
                name="VoyNo"
                component={RenderField}
              />
              <Field
                label="CW File #:"
                type="text"
                name="CWFileNo"
                component={RenderField}
              />
            </div>
            <div className="row">
              <Field
                label="Shpmt. buying terms:"
                type="text"
                name="buyTerm"
                component={RenderField}
              />
              <Field
                label="ISF File Date:"
                type="text"
                name="ISFDate"
                component={RenderDateField}
              />
            </div>
            <div className="row">
              <Field
                label="Original Port:"
                type="text"
                name="OrgPort"
                data={orgPorts}
                component={RenderReactSelect}
              />
              <Field
                label="ETD:"
                type="text"
                name="ETD"
                component={RenderDateField}
              />
            </div>
            <div className="row">
              <Field
                label="Discharge Port:"
                type="text"
                name="DstPort"
                data={dstPorts}
                component={RenderReactSelect}
              />
              <Field
                label="(1st Port) ETA:"
                type="text"
                name="ETAFirst"
                component={RenderDateField}
              />
            </div>
            <div className="row">
              <Field
                label="Final Destination:"
                type="text"
                name="FnlPort"
                data={fnlPorts}
                component={RenderReactSelect}
              />
              <Field
                label="(Final port) ETA:"
                type="text"
                name="ETAFinal"
                component={RenderDateField}
              />
            </div>
            <div className="row">
              <Field
                label="Document Cut Off Date:"
                type="text"
                name="DocCutOff"
                component={RenderDateField}
              />
              <Field
                label="Cargo Cut Off Date:"
                type="text"
                name="CargoCutOff"
                component={RenderDateField}
              />
            </div>
            <div className="row">
              <Field
                label="Document Send Date:"
                type="text"
                name="docSend"
                component={RenderDateField}
              />
              <Field
                label="Approval Cut Off:"
                type="text"
                name="AprvCutOff"
                component={RenderDateField}
              />
            </div>
            <div className="row">
              <Field
                label="CFS Warehouse:"
                type="text"
                name="cfsWhs"
                component={RenderField}
              />
              <Field
                label="Billing Date:"
                type="text"
                name="billingDate"
                component={RenderDateField}
              />
            </div>
            {this.renderUpdatedMsg()}
            <div className="row text-center">
              <button
                type="submit"
                name="submitBooking"
                className="btn btn-default"
              >
                Update
              </button>
            </div>
            <hr />
          </form>
        </fieldset>
      </div>
    )
  }
}

function mapStateToProps({ bookings, auth, util, ports }) {
  return {
    booking: bookings.bookingHeader,
    updatedBooking: bookings.updatedBooking,
    initialValues: bookings.bookingHeader,
    user: auth.user,
    showModal: util.showModal,
    uploaded: util.uploaded,
    ports,
    errorMessage: util.errors,
  }
}

BookingOceanHeader = reduxForm({
  form: 'BookingOceanHeader',
  enableReinitialize: true,
  validate,
})(BookingOceanHeader)

BookingOceanHeader = withRouter(
  connect(mapStateToProps, actions)(BookingOceanHeader),
)
export default BookingOceanHeader
