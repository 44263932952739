import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';

class RenderTrucker extends Component {
  componentDidMount() {
    this.props.fetchTruckerList();
  }
  render() {
    const { trucker, label, input, disabled } = this.props;
    if (!this.props.trucker) {
      return <div>loading...</div>;
    }

    return (
      <div className="form-group form-group-sm col-sm-6">
        <div className="row">
          <label className="col-sm-4 col-form-label">{label}</label>
          <div className="col-sm-7">
            <select className="form-control" {...input} disabled={disabled}>
              {trucker.map(choice => (
                <option value={choice.VendorURLID} key={choice.VendorURLID}>
                  {choice.VendorName}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ bookings }) {
  return {
    trucker: bookings.trucker
  };
}

export default connect(
  mapStateToProps,
  actions
)(RenderTrucker);
