import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';
import '../css/booking.css';
import BookingAirDetailAgent from './agent/bookingAirDetail_Agent';
import BookingAirHeaderAgent from './agent/bookingAirHeader_Agent';
import BookingCommentAgent from './agent/bookingComment_Agent';
import BookingOceanDetailAgent from './agent/bookingOceanDetail_Agent';
import BookingOceanHeaderAgent from './agent/bookingOceanHeader_Agent';
import BookingAirDetail from './booking/bookingAirDetail';
import BookingAirHeader from './booking/bookingAirHeader';
import BookingComment from './booking/bookingComment';
import BookingOceanDetail from './booking/bookingOceanDetail';
import BookingOceanHeader from './booking/bookingOceanHeader';
import ContainerSummary from './booking/containerSummary';
import BookingAirDetailCustomer from './customer/bookingAirDetail_Customer';
import BookingAirHeaderCustomer from './customer/bookingAirHeader_Customer';
import BookingCommentCustomer from './customer/bookingComment_Customer';
import BookingOceanDetailCustomer from './customer/bookingOceanDetail_Customer';
import BookingOceanHeaderCustomer from './customer/bookingOceanHeader_Customer';
import Spinner from './util/spinner';

class BookingMain extends Component {
  componentDidMount() {
    // console.log(this.props.match.params);
    const { BookingID } = this.props.match.params;
    this.props.fetchBookingHeader(BookingID);
    this.props.fetchTruckerList();
  }

  renderContent() {
    switch (this.props.user.accessLevel) {
      case null:
        return;
      case 'AGENT':
        return (
          <div>
            {(() => {
              if (this.props.booking.ShipmentType === 'AIR') {
                return (
                  <div>
                    <BookingAirHeaderAgent booking={this.props.booking} />
                    <BookingAirDetailAgent ShipmentType={this.props.booking.ShipmentType} />
                  </div>
                );
              } else
                return (
                  <div>
                    <BookingOceanHeaderAgent booking={this.props.booking} />
                    <BookingOceanDetailAgent ShipmentType={this.props.booking.ShipmentType} />
                    <ContainerSummary id={this.props.match.params.BookingID} />
                  </div>
                );
            })()}
            <BookingCommentAgent />
          </div>
        );
      case 'CUSTOMER':
        return (
          <div>
            {(() => {
              if (this.props.booking.ShipmentType === 'AIR') {
                return (
                  <div>
                    <BookingAirHeaderCustomer booking={this.props.booking} />
                    <BookingAirDetailCustomer trucker={this.props.trucker} />
                  </div>
                );
              } else
                return (
                  <div>
                    <BookingOceanHeaderCustomer booking={this.props.booking} />
                    <BookingOceanDetailCustomer trucker={this.props.trucker} />
                    <ContainerSummary id={this.props.match.params.BookingID} />
                  </div>
                );
            })()}

            <BookingCommentCustomer />
          </div>
        );
      case 'CONTROL':
      case 'OPERATOR':
        return (
          <div>
            {(() => {
              if (this.props.booking.ShipmentType === 'AIR') {
                return (
                  <div>
                    <BookingAirHeader booking={this.props.booking} />
                    <BookingAirDetail trucker={this.props.trucker} />
                  </div>
                );
              } else
                return (
                  <div>
                    <BookingOceanHeader booking={this.props.booking} />
                    <BookingOceanDetail trucker={this.props.trucker} />
                    <ContainerSummary id={this.props.match.params.BookingID} />
                  </div>
                );
            })()}

            <BookingComment />
          </div>
        );

      default:
        return (
          <div>
            <h2 className="text-danger">Access Denied</h2>
          </div>
        );
    }
  }
  render() {
    const { booking, trucker } = this.props;
    if (!booking || !trucker) {
      return <Spinner />;
    }
    return (
      <div className="booking-main-header">
        <h2>
          Booking #{booking.BookingID} <span>{booking.Archived ? '(Archived)' : ''}</span>
        </h2>
        {this.renderContent()}
      </div>
    );
  }
}

function mapStateToProps({ auth, bookings }) {
  return {
    user: auth.user,
    booking: bookings.bookingHeader,
    trucker: bookings.trucker
  };
}

export default connect(
  mapStateToProps,
  actions
)(BookingMain);
