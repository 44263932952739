import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
// material-ui components
import withStyles from '@material-ui/core/styles/withStyles';
// @material-ui/icons
import Close from '@material-ui/icons/Close';
import React from 'react';
import modalStyle from '../../assets/jss/material-dashboard-pro-react/modalStyle.jsx';
import EditForm from '../../views/Account/edit';
import Transition from '../Transition/default';

// export const FullscreenTransition = React.forwardRef((props, ref) => (
//   <Slide direction='left' {...props} ref={ref} />
// ))

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
  }

  handleClickOpen(modal) {
    const x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleClose(modal) {
    const x = [];
    x[modal] = false;
    this.setState(x);
  }

  render() {
    const { classes, open, title, onClose, item, ...other } = this.props;
    return (
      <div>
        <Dialog
          fullWidth
          open={open}
          classes={{
            root: classes.auto
          }}
          TransitionComponent={Transition}
          keepMounted
          onClose={onClose}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
          {...other}
        >
          <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
            <IconButton
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={onClose}
            >
              <Close className={classes.modalClose} />
            </IconButton>
          </DialogTitle>
          <DialogContent id="modal-slide-description" className={classes.modalBody}>
            <EditForm item={item} onClose={onClose} title={title} />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(modalStyle)(Modal);
