import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';

class RenderHandler extends Component {
  componentDidMount() {
    this.props.getAllOperators();
  }
  render() {
    const { station, operators, label, input, disabled } = this.props;
    if (!this.props.operators) {
      return <div>loading...</div>;
    }
    return (
      <div className="form-group form-group-sm col-sm-6">
        <div className="row">
          <label className="col-sm-4 col-form-label">{label}</label>
          <div className="col-sm-7">
            <select className="form-control" {...input} disabled={disabled}>
              {operators
                .filter(e => e.station === station || e.station === 'ALL')
                .map(choice => (
                  <option value={choice.EmployeeNo} key={choice.EmployeeNo}>
                    {choice.EmployeeNo === 0 ? 'Select Operation Handler...' : `${choice.station}: ${choice.FirstName}`}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ util }) {
  return {
    operators: util.operators
  };
}

export default connect(
  mapStateToProps,
  actions
)(RenderHandler);
