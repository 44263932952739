import moment from 'moment'
import React, { Component } from 'react'
import { Button, ButtonToolbar } from 'react-bootstrap'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Scrollchor from 'react-scrollchor'
import { Field, reduxForm } from 'redux-form'
import * as actions from '../../actions'
import { ADD_ERROR } from '../../actions/types'
import CustomSweetAlert from '../Alerts/SweetAlert'
import RenderDateField from '../booking/renderDateField'
import RenderField from '../booking/renderField'
import RenderStatusField from '../booking/renderStatusField'
import UploadModal from '../renderUploadModal'
import Spinner from '../util/spinner'

class BookingHeaderCustomer extends Component {
  componentDidMount() {
    const { BookingID } = this.props.match.params
    this.props.fetchBookingHeader(BookingID)
    if (this.props.user.accessLevel === 'CUSTOMER') {
      if (this.props.user.CustomerID !== this.props.booking.CNeeID.toString()) {
        this.props.history.push('/p/search')
        this.props.dispatch(this.addError('ACCESS DENIED'))
      }
    }
  }

  addError(error) {
    return {
      type: ADD_ERROR,
      payload: error,
    }
  }

  render() {
    const { booking, initialValues, handleSubmit, uploaded } = this.props
    if (!booking || !initialValues) {
      return <Spinner />
    }

    return (
      <fieldset className="for-panel">
        <Button bsSize="small">
          <Scrollchor to="#comments" style={{ color: 'inherit' }}>
            Comments
          </Scrollchor>
        </Button>
        <div className="row">
          <div className="col-sm-6">Prepare By: {booking.PrepareBy}</div>
          <div className="col-sm-6">
            <ButtonToolbar className="pull-right button-lineup">
              <Button
                bsSize="small"
                bsStyle="default"
                onClick={this.props.openModal}
              >
                Upload
              </Button>
              <Button
                bsSize="small"
                bsStyle="default"
                href={`/p/sendmail/${booking.BookingID}`}
              >
                Send Notification
              </Button>
            </ButtonToolbar>
          </div>
          <UploadModal
            url={`sfo_uploads/${booking.CNeeID}`}
            showModal={this.props.showModal}
            closeModal={this.props.closeModal}
          />
          {uploaded && uploaded.status === 'ok' ? (
            <CustomSweetAlert type="success" title={uploaded.message} />
          ) : null}
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-sm-6 ">
              <strong>
                BOOKING{' '}
                <span style={{ color: 'red' }}>({booking.ShipmentType})</span> #{' '}
              </strong>
              {booking.BookingID} -- created on{' '}
              {moment(booking.createDate).format('MM/DD/YYYY hh:mm A')}
            </div>
            <div className="col-sm-6 pull-right button-lineup">
              <strong>Consignee:</strong> [{booking.CNeeID}] {booking.CNeeName}
            </div>
          </div>

          <hr />
          <div className="row">
            <Field
              label="Carrier Name:"
              name="CarrierName"
              component={RenderField}
              disabled
            />
            <Field
              label="Status:"
              name="bkStatus"
              component={RenderStatusField}
              disabled
            />
          </div>
          <div className="row">
            <Field
              label="Mother Vessel:"
              type="text"
              name="VesselName"
              component={RenderField}
              disabled
            />
            <Field
              label="Feeder Vessel/Voy:"
              type="text"
              name="FeedVessel"
              component={RenderField}
              disabled
            />
          </div>
          <div className="row">
            <Field
              label="Voy:"
              type="text"
              name="VoyNo"
              component={RenderField}
              disabled
            />
            <Field
              label="Shpmt. buying terms:"
              type="text"
              name="buyTerm"
              component={RenderField}
              disabled
            />
          </div>
          <div className="row">
            <Field
              label="Original Port:"
              type="text"
              name="OrgPort"
              component={RenderField}
              disabled
            />
            <Field
              label="ETD:"
              type="text"
              name="ETD"
              component={RenderDateField}
              disabled
            />
          </div>
          <div className="row">
            <Field
              label="Discharge Port:"
              type="text"
              name="DstPort"
              component={RenderField}
              disabled
            />
            <Field
              label="(1st Port) ETA:"
              type="text"
              name="ETAFirst"
              component={RenderDateField}
              disabled
            />
          </div>
          <div className="row">
            <Field
              label="Final Destination:"
              type="text"
              name="FnlPort"
              component={RenderField}
              disabled
            />
            <Field
              label="(Final port) ETA:"
              type="text"
              name="ETAFinal"
              component={RenderDateField}
              disabled
            />
          </div>
          <div className="row">
            <Field
              label="Document Cut Off Date:"
              type="text"
              name="DocCutOff"
              component={RenderDateField}
              disabled
            />
            <Field
              label="Cargo Cut Off Date:"
              type="text"
              name="CargoCutOff"
              component={RenderDateField}
              disabled
            />
          </div>
          <div className="row">
            <Field
              label="Document Send Date:"
              type="text"
              name="docSend"
              component={RenderDateField}
              disabled
            />
            <Field
              label="Approval Cut Off:"
              type="text"
              name="AprvCutOff"
              component={RenderDateField}
              disabled
            />
          </div>
          <div className="row">
            <Field
              label="CFS Warehouse:"
              type="text"
              name="cfsWhs"
              component={RenderField}
              disabled
            />
          </div>
        </form>
      </fieldset>
    )
  }
}

function mapStateToProps({ bookings, auth, util }) {
  return {
    initialValues: bookings.bookingHeader,
    user: auth.user,
    showModal: util.showModal,
    uploaded: util.uploaded,
    errorMessage: util.errors,
  }
}

BookingHeaderCustomer = reduxForm({
  form: 'BookingHeaderCustomer',
  enableReinitialize: true,
})(BookingHeaderCustomer)

BookingHeaderCustomer = withRouter(
  connect(mapStateToProps, actions)(BookingHeaderCustomer),
)
export default BookingHeaderCustomer
