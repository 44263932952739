import React from 'react';

function RenderCargoType({ label, CargoType, CargoSize }) {
  let cyType = '';
  if (CargoType === '33') cyType = 'CY/CY';
  if (CargoType === '34') cyType = 'CFS/CFS';
  if (CargoType === '688') cyType = 'CFS/CY';
  if (CargoType === '35') cyType = 'CFS-HL';
  let cSize = '';
  if (CargoSize === '29') cSize = "20'";
  if (CargoSize === '30') cSize = "40'";
  if (CargoSize === '31') cSize = "40' HQ";
  if (CargoSize === '32') cSize = "45'";
  const cargoDisplayType = `${cyType} / ${cSize}`;
  return (
    <div>
      <div className="form-group form-group-sm col-sm-6">
        <div className="row">
          <label className="col-sm-4 col-form-label">{label}</label>
          <div className="col-sm-7">
            <input className="form-control" type="text" defaultValue={cargoDisplayType} disabled />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RenderCargoType;
