import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';

class RenderConsignee extends Component {
  componentDidMount() {
    this.props.fetchCustomerList();
  }
  render() {
    const {
      customer,
      label,
      input,
      meta: { touched, error }
    } = this.props;
    if (!this.props.customer) {
      return <div>loading...</div>;
    }
    return (
      <div className="form-group form-group-sm col-sm-6">
        <div className="row">
          <label className="col-sm-4 col-form-label">{label}</label>
          <div className="col-sm-7">
            <select className="form-control" {...input}>
              {customer.map(choice => (
                <option value={choice.customerNo} key={choice.customerNo}>
                  {choice.companyName}
                </option>
              ))}
            </select>
            <div className="text-danger">{touched ? error : ''}</div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ util }) {
  return {
    customer: util.customer
  };
}

export default connect(
  mapStateToProps,
  actions
)(RenderConsignee);
