import React, { Component } from 'react';

export default class RenderStatusField extends Component {
  render() {
    const { label, input, disabled } = this.props;
    const statusOption = [
      { value: '', display: 'BOOKING STATUS' },
      { value: 'AP', display: 'APPROVED' },
      { value: 'AL', display: 'ALERT' },
      { value: 'AR', display: 'APPROVAL REQUIRED' },
      { value: 'BP', display: 'BOOKING PENDING' }
    ];
    return (
      <div className="form-group form-group-sm col-sm-6">
        <div className="row">
          <label className="col-sm-4 col-form-label">{label}</label>
          <div className="col-sm-7">
            <select className="form-control" {...input} disabled={disabled}>
              {statusOption.map(choice => (
                <option value={choice.value} key={choice.value}>
                  {choice.display}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  }
}
